// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  Collapse,
  Fade,
  Grid,
  Icon,
  Modal,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { MenuContext } from "context/menuContext";
import dayjs from "dayjs";
import { getUproColor } from "utils/colors";
import ModalComision from "../ModalComision";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalComisiones({ open, handleClose, selectedMateria }) {
  const { handleSnackbar } = useContext(MessageManager);
  const [expanded, setExpanded] = useState(["Comisiones", "Calendario"]);
  const [expandSedesComisiones, setExpandSedesComisiones] = useState(false);
  const [openComisionModal, setOpenComisionModal] = useState(false);
  const [expandSedes, setExpandSedes] = useState(false);
  const [selectedSede, setSelectedSede] = useState(null);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [comision, setComision] = useState();
  const { menu } = useContext(MenuContext);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "nombre",
    orden: "ASC",
  });

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const [deleteComision, { loading: loadingDelete }] = useMutation(gql`
    mutation deleteComision($id: ID!) {
      deleteComision(id: $id) {
        id
      }
    }
  `);

  const [getSedes, { data: sedes, loading: loadingSedes }] = useLazyQuery(
    gql`
      query getSedes {
        sedes {
          id
          nombre
          aulas {
            id
            nombre
            capacidad
            comisiones {
              id
              horarios {
                dia
                horarioInicio
                horarioFin
                aula {
                  id
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const [getComisiones, { loading, data: comisiones, refetch }] = useLazyQuery(
    gql`
      query getComisiones($search: String, $page: Int, $order: Order, $filter: JSON) {
        paginationInfo {
          pages
          total
        }
        comisiones(
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
          filter: $filter
        ) {
          id
          nombre
          codigo
          color
          nivel
          cantidadInscriptos
          capacidad
          sede {
            id
          }
          docente {
            id
          }
          horarios {
            dia
            aula {
              id
              nombre
            }
            capacidad
            horarioInicio
            horarioFin
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const [
    getComisionesHorarios,
    { loading: loadingHorarios, data: comisionesHorarios, refetch: refetchHorarios },
  ] = useLazyQuery(
    gql`
      query getComisiones($filter: JSON) {
        comisiones(filter: $filter) {
          id
          nombre
          codigo
          color
          nivel
          cantidadInscriptos
          capacidad
          sede {
            id
          }
          docente {
            id
          }
          horarios {
            dia
            aula {
              id
              nombre
            }
            capacidad
            horarioInicio
            horarioFin
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (open) {
      getSedes().then((data) => {
        setSelectedSede(data?.data?.sedes?.[0]);
      });
    }
  }, [open]);

  useEffect(() => {
    if (open && selectedMateria && selectedSede) {
      getComisiones({
        variables: {
          search: search || null,
          page: page,
          order: { field: orderBy.campo, order: orderBy.orden },
          filter: { idMateria: selectedMateria.id, idSede: selectedSede?.id },
        },
      });
      getComisionesHorarios({
        variables: {
          filter: { idMateria: selectedMateria.id, idSede: selectedSede?.id },
        },
      });
    }
  }, [open, selectedMateria, selectedSede, page, search]);

  useEffect(() => {
    if (comisiones) {
      setPagesInfo(comisiones.paginationInfo);
    }
  }, [comisiones]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">
                  {`Comisiones de ${selectedMateria?.nombre}`}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox pt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: !expanded.includes("Comisiones")
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon color="primary">groups</Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          Comisiones
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                        <SoftBox
                          mr={{
                            xs: 0,
                            sm: 2,
                          }}
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "auto",
                            },
                          }}
                        >
                          <SoftInput
                            placeholder="Escribe aquí..."
                            icon={{ component: "search", direction: "left" }}
                            onChange={handleSearch}
                          />
                        </SoftBox>
                        <SoftBox mr={2}>
                          <SoftButton
                            color="uproGreen"
                            circular
                            onClick={(event) => {
                              setExpandSedesComisiones(!expandSedesComisiones);
                              menu({
                                open: event.currentTarget,
                                align: "right",
                                onClose: () => {
                                  setExpandSedesComisiones(false);
                                },
                                options: [
                                  ...sedes?.sedes?.map((sede) => ({
                                    name: sede.nombre,
                                    icon: {
                                      icon:
                                        selectedSede?.id === sede.id
                                          ? "location_on"
                                          : "location_city",
                                      color:
                                        selectedSede?.id === sede.id
                                          ? getUproColor("uproPink")
                                          : getUproColor("uproGreen"),
                                    },
                                    onClick: () => {
                                      setSelectedSede(sede);
                                      getComisiones({
                                        variables: {
                                          search: search || null,
                                          page: page,
                                          order: { field: orderBy.campo, order: orderBy.orden },
                                          filter: {
                                            idMateria: selectedMateria.id,
                                            idSede: sede?.id,
                                          },
                                        },
                                      });
                                      getComisionesHorarios({
                                        variables: {
                                          filter: {
                                            idMateria: selectedMateria.id,
                                            idSede: sede?.id,
                                          },
                                        },
                                      });
                                    },
                                  })),
                                ],
                                handleClose: () => {
                                  setExpandSelector(false);
                                },
                              });
                            }}
                          >
                            <Icon fontSize="small">apartment</Icon>
                            &nbsp; {selectedSede?.nombre || "Todas las sedes"} &nbsp;
                            <Icon
                              sx={{
                                rotate: expandSedesComisiones ? "180deg" : "0deg",
                                transition: "all 0.5s",
                              }}
                            >
                              expand_more
                            </Icon>
                          </SoftButton>
                        </SoftBox>
                        <SoftBox mr={2}>
                          <Tooltip title={"Agregar Comisión"} placement="top">
                            <SoftButton
                              color="primary"
                              circular
                              onClick={() => setOpenComisionModal(true)}
                            >
                              <Icon>add</Icon>
                              &nbsp;Agregar
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                        <SoftBox>
                          <Tooltip
                            title={expanded.includes("Comisiones") ? "Ocultar" : "Mostrar"}
                            placement="top"
                          >
                            <SoftButton
                              color="primary"
                              circular
                              iconOnly
                              onClick={() =>
                                setExpanded(
                                  expanded.includes("Comisiones")
                                    ? expanded.filter((i) => i !== "Comisiones")
                                    : [...expanded, "Comisiones"]
                                )
                              }
                            >
                              <Icon
                                sx={{
                                  rotate: expanded.includes("Comisiones") ? "180deg" : "0deg",
                                  transition: "all 0.5s",
                                }}
                              >
                                expand_more
                              </Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={expanded.includes("Comisiones")}>
                      <Grid container>
                        {loading || loadingSedes ? (
                          <Grid item xs={12}>
                            <Loading />
                          </Grid>
                        ) : comisiones?.comisiones?.length === 0 ? (
                          <Grid item xs={12}>
                            <SinDatos />
                          </Grid>
                        ) : (
                          comisiones?.comisiones?.map((thisComision, index) => (
                            <Grid item xs={12} key={thisComision.id}>
                              <SoftBox
                                px={2}
                                py={1}
                                sx={{
                                  borderBottom:
                                    comisiones?.comisiones?.length - 1 === index
                                      ? "none"
                                      : "1px solid rgba(0, 0, 0, 0.12)",
                                  cursor: "pointer",
                                  "&:hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                  },
                                }}
                              >
                                <SoftBox display="flex" justifyContent="space-between">
                                  <SoftBox display="flex" alignItems="center" pl={2}>
                                    <SoftBox mr={1}>
                                      <Icon
                                        sx={{
                                          color: getUproColor("uproPink"),
                                        }}
                                      >
                                        subdirectory_arrow_right
                                      </Icon>
                                    </SoftBox>
                                    <SoftBox mr={1}>
                                      <Tooltip title="Nivel" placement="top">
                                        <Icon
                                          sx={{
                                            color: getUproColor("uproYellow"),
                                          }}
                                        >
                                          group
                                        </Icon>
                                      </Tooltip>
                                    </SoftBox>
                                    <SoftTypography variant="h6">{`${thisComision.codigo} - ${thisComision.nombre}`}</SoftTypography>
                                    <SoftBox ml={1} display="flex" alignItems="center">
                                      <SoftBadge
                                        color="uproYellow"
                                        badgeContent={`${thisComision.cantidadInscriptos}/${thisComision.capacidad} alumnos`}
                                      />
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftBox
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                  >
                                    <SoftBox>
                                      <Tooltip title="Opciones" placement="top">
                                        <SoftButton
                                          color="uproYellow"
                                          circular
                                          iconOnly
                                          disabled={loadingDelete}
                                          onClick={(event) => {
                                            menu({
                                              open: event.currentTarget,
                                              align: "right",
                                              options: [
                                                {
                                                  name: "Editar",
                                                  icon: {
                                                    icon: "edit",
                                                    color: getUproColor("uproGreen"),
                                                  },
                                                  onClick: () => {
                                                    setComision({
                                                      id: thisComision.id,
                                                      codigo: thisComision.codigo,
                                                      nombre: thisComision.nombre,
                                                      color: thisComision.color,
                                                      nivel: thisComision.nivel,
                                                      idSede: thisComision.sede.id,
                                                      idDocente: thisComision.docente.id,
                                                      horarios: thisComision.horarios.map((d) => ({
                                                        dia: d.dia,
                                                        horarioInicio: dayjs(
                                                          d.horarioInicio,
                                                          "HH:mm"
                                                        ).format("HH:mm"),
                                                        horarioFin: dayjs(
                                                          d.horarioFin,
                                                          "HH:mm"
                                                        ).format("HH:mm"),
                                                        idAula: d.aula.id,
                                                        capacidad: d.capacidad,
                                                      })),
                                                    });
                                                    setOpenComisionModal(true);
                                                  },
                                                },
                                                {
                                                  name:
                                                    thisComision.cantidadInscriptos > 0
                                                      ? "No se puede eliminar"
                                                      : "Eliminar",
                                                  icon: {
                                                    icon: "delete",
                                                    color: getUproColor("uproPink"),
                                                  },
                                                  disabled:
                                                    loadingDelete ||
                                                    thisComision.cantidadInscriptos > 0,
                                                  onClick: () => {
                                                    deleteComision({
                                                      variables: {
                                                        id: thisComision.id,
                                                      },
                                                    })
                                                      .then(() => {
                                                        handleSnackbar(
                                                          "Comisión eliminada correctamente",
                                                          "success"
                                                        );
                                                        refetch();
                                                        refetchHorarios();
                                                      })
                                                      .catch((e) => {
                                                        handleSnackbar(
                                                          e.message ||
                                                            "Error al eliminar la comisión",
                                                          "error"
                                                        );
                                                      });
                                                  },
                                                },
                                              ],
                                              handleClose: () => {},
                                            });
                                          }}
                                        >
                                          <Icon>more_vert</Icon>
                                        </SoftButton>
                                      </Tooltip>
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              </SoftBox>
                            </Grid>
                          ))
                        )}
                        {comisiones?.comisiones?.length > 0 && (
                          <SoftBox
                            sx={{
                              width: "100%",
                            }}
                          >
                            <CustomPagination
                              loading={loading || loadingSedes}
                              length={comisiones?.comisiones?.length}
                              total={pagesInfo?.total}
                              pages={pagesInfo?.pages}
                              page={page}
                              setPage={setPage}
                              noCard
                            />
                          </SoftBox>
                        )}
                      </Grid>
                    </Collapse>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: !expanded.includes("Calendario")
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon
                            sx={{
                              color: getUproColor("uproYellow"),
                            }}
                          >
                            calendar_today
                          </Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          Calendario
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                        <SoftBox mr={2}>
                          <SoftButton
                            color="uproGreen"
                            circular
                            onClick={(event) => {
                              setExpandSedes(!expandSedes);
                              menu({
                                open: event.currentTarget,
                                align: "right",
                                onClose: () => {
                                  setExpandSedes(false);
                                },
                                options: [
                                  ...sedes?.sedes?.map((sede) => ({
                                    name: sede.nombre,
                                    icon: {
                                      icon:
                                        selectedSede?.id === sede.id
                                          ? "location_on"
                                          : "location_city",
                                      color:
                                        selectedSede?.id === sede.id
                                          ? getUproColor("uproPink")
                                          : getUproColor("uproGreen"),
                                    },
                                    onClick: () => {
                                      setSelectedSede(sede);
                                      getComisiones({
                                        variables: {
                                          search: search || null,
                                          page: page,
                                          order: { field: orderBy.campo, order: orderBy.orden },
                                          filter: {
                                            idMateria: selectedMateria.id,
                                            idSede: sede?.id,
                                          },
                                        },
                                      });
                                      getComisionesHorarios({
                                        variables: {
                                          filter: {
                                            idMateria: selectedMateria.id,
                                            idSede: sede?.id,
                                          },
                                        },
                                      });
                                    },
                                  })),
                                ],
                                handleClose: () => {
                                  setExpandSelector(false);
                                },
                              });
                            }}
                          >
                            <Icon fontSize="small">apartment</Icon>
                            &nbsp; {selectedSede?.nombre || "Todas las sedes"} &nbsp;
                            <Icon
                              sx={{
                                rotate: expandSedes ? "180deg" : "0deg",
                                transition: "all 0.5s",
                              }}
                            >
                              expand_more
                            </Icon>
                          </SoftButton>
                        </SoftBox>
                        <SoftBox>
                          <Tooltip
                            title={expanded.includes("Calendario") ? "Ocultar" : "Mostrar"}
                            placement="top"
                          >
                            <SoftButton
                              color="primary"
                              circular
                              iconOnly
                              onClick={() =>
                                setExpanded(
                                  expanded.includes("Calendario")
                                    ? expanded.filter((i) => i !== "Calendario")
                                    : [...expanded, "Calendario"]
                                )
                              }
                            >
                              <Icon
                                sx={{
                                  rotate: expanded.includes("Calendario") ? "180deg" : "0deg",
                                  transition: "all 0.5s",
                                }}
                              >
                                expand_more
                              </Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={expanded.includes("Calendario")}>
                      <SoftBox sx={{ maxHeight: "50vh", overflowY: "scroll" }}>
                        <Table stickyHeader>
                          <TableHead
                            sx={{
                              display: "revert !important",
                            }}
                          >
                            <TableRow>
                              <TableCell>&nbsp;</TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                Lunes
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                Martes
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                Miércoles
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                Jueves
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                Viernes
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {new Array(17).fill(0).map((_, index) => {
                            const hora = dayjs()
                              .startOf("day")
                              .add(index + 7, "hour");

                            const comisionesSede = comisionesHorarios?.comisiones?.filter(
                              (comision) => comision.sede.id === selectedSede?.id
                            );

                            const filtrarComisionesPorDiaYHora = (dia) => {
                              return (
                                comisionesSede?.filter((comision) =>
                                  comision.horarios.some(
                                    (horario) =>
                                      horario.dia === dia &&
                                      (dayjs(horario.horarioInicio, "HH:mm").isSame(hora, "hour") ||
                                        (dayjs(horario.horarioInicio, "HH:mm").isBefore(
                                          hora,
                                          "hour"
                                        ) &&
                                          dayjs(horario.horarioFin, "HH:mm").isAfter(hora, "hour")))
                                  )
                                ) || []
                              );
                            };

                            return (
                              <TableRow key={index}>
                                <TableCell>{hora.format("HH:mm")}</TableCell>

                                <TableCell
                                  sx={{
                                    backgroundColor:
                                      filtrarComisionesPorDiaYHora("Lunes").length > 0
                                        ? filtrarComisionesPorDiaYHora("Lunes")[0].color
                                        : "white",
                                    textAlign: "center",
                                  }}
                                >
                                  {filtrarComisionesPorDiaYHora("Lunes").map((comision) => (
                                    <SoftBox
                                      key={comision.id}
                                      display="flex"
                                      flexDirection="column"
                                    >
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="bold"
                                        color="white"
                                      >{`${comision.codigo} - ${comision.nombre}`}</SoftTypography>
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="light"
                                        color="white"
                                      >
                                        {comision?.horarios?.find((d) => d.dia === "Lunes")?.aula
                                          ?.nombre || ""}
                                      </SoftTypography>
                                    </SoftBox>
                                  ))}
                                </TableCell>

                                <TableCell
                                  sx={{
                                    backgroundColor:
                                      filtrarComisionesPorDiaYHora("Martes").length > 0
                                        ? filtrarComisionesPorDiaYHora("Martes")[0].color
                                        : "white",
                                    textAlign: "center",
                                  }}
                                >
                                  {filtrarComisionesPorDiaYHora("Martes").map((comision) => (
                                    <SoftBox
                                      key={comision.id}
                                      display="flex"
                                      flexDirection="column"
                                    >
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="bold"
                                        color="white"
                                      >{`${comision.codigo} - ${comision.nombre}`}</SoftTypography>
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="light"
                                        color="white"
                                      >
                                        {comision?.horarios?.find((d) => d.dia === "Martes")?.aula
                                          ?.nombre || ""}
                                      </SoftTypography>
                                    </SoftBox>
                                  ))}
                                </TableCell>

                                <TableCell
                                  sx={{
                                    backgroundColor:
                                      filtrarComisionesPorDiaYHora("Miércoles").length > 0
                                        ? filtrarComisionesPorDiaYHora("Miércoles")[0].color
                                        : "white",
                                    textAlign: "center",
                                  }}
                                >
                                  {filtrarComisionesPorDiaYHora("Miércoles").map((comision) => (
                                    <SoftBox
                                      key={comision.id}
                                      display="flex"
                                      flexDirection="column"
                                    >
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="bold"
                                        color="white"
                                      >{`${comision.codigo} - ${comision.nombre}`}</SoftTypography>
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="light"
                                        color="white"
                                      >
                                        {comision?.horarios?.find((d) => d.dia === "Miércoles")
                                          ?.aula?.nombre || ""}
                                      </SoftTypography>
                                    </SoftBox>
                                  ))}
                                </TableCell>

                                <TableCell
                                  sx={{
                                    backgroundColor:
                                      filtrarComisionesPorDiaYHora("Jueves").length > 0
                                        ? filtrarComisionesPorDiaYHora("Jueves")[0].color
                                        : "white",
                                    textAlign: "center",
                                  }}
                                >
                                  {filtrarComisionesPorDiaYHora("Jueves").map((comision) => (
                                    <SoftBox
                                      key={comision.id}
                                      display="flex"
                                      flexDirection="column"
                                    >
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="bold"
                                        color="white"
                                      >{`${comision.codigo} - ${comision.nombre}`}</SoftTypography>
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="light"
                                        color="white"
                                      >
                                        {comision?.horarios?.find((d) => d.dia === "Jueves")?.aula
                                          ?.nombre || ""}
                                      </SoftTypography>
                                    </SoftBox>
                                  ))}
                                </TableCell>

                                <TableCell
                                  sx={{
                                    backgroundColor:
                                      filtrarComisionesPorDiaYHora("Viernes").length > 0
                                        ? filtrarComisionesPorDiaYHora("Viernes")[0].color
                                        : "white",
                                    textAlign: "center",
                                  }}
                                >
                                  {filtrarComisionesPorDiaYHora("Viernes").map((comision) => (
                                    <SoftBox
                                      key={comision.id}
                                      display="flex"
                                      flexDirection="column"
                                    >
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="bold"
                                        color="white"
                                      >{`${comision.codigo} - ${comision.nombre}`}</SoftTypography>
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="light"
                                        color="white"
                                      >
                                        {comision?.horarios?.find((d) => d.dia === "Viernes")?.aula
                                          ?.nombre || ""}
                                      </SoftTypography>
                                    </SoftBox>
                                  ))}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </Table>
                      </SoftBox>
                    </Collapse>
                  </Card>
                </Grid>
              </Grid>
            </SoftBox>
            <ModalComision
              open={openComisionModal}
              handleClose={() => {
                setOpenComisionModal(false);
                setComision();
              }}
              selectedComision={comision}
              selectedMateria={selectedMateria}
              refetch={() => {
                refetch();
                refetchHorarios();
              }}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalComisiones.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedMateria: PropTypes.object,
};
