// prop-types is a library for typechecking of props

// @mui material components
import Grid from "@mui/material/Grid";

// Kaizen Dashboard components
import curved9 from "assets/images/curved-images/curved0.jpg";
import SoftBox from "components/SoftBox";

import SoftTypography from "components/SoftTypography";

// Kaizen Dashboard examples
import PageLayout from "components/LayoutContainers/PageLayout";

// Authentication layout components
import { Card, Icon, Slide } from "@mui/material";
import logo from "assets/images/logo_alt.png";
import Footer from "components/Footer";
import SoftButton from "components/SoftButton";
import { useRef, useState } from "react";
import ModalCertificado from "../components/ModalCertificado";
import PreinscripcionAlumno from "../components/PreInscripcionAlumno";

function Preinscripcion() {
  const [step, setStep] = useState(0);
  const [usuario, setUsuario] = useState(null);
  const [idUser, setIdUser] = useState(null);
  const [carrera, setCarrera] = useState(null);
  const [openModalCertificado, setOpenModalCertificado] = useState(false);
  const containerRef = useRef(null);

  return (
    <PageLayout>
      <SoftBox
        width="calc(100% - 2rem)"
        minHeight="50vh"
        borderRadius="lg"
        mx={2}
        my={2}
        pt={6}
        pb={28}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            curved9 &&
            `${linearGradient(
              rgba(gradients.uproPink.main, 0.9),
              rgba(gradients.uproPink.state, 0.6)
            )}, url(${curved9})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container spacing={3} justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={10} lg={4}>
            <SoftBox mt={6} mb={1}>
              <img src={logo} alt="Terra" width="50%" />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftTypography variant="h4" color="white" fontWeight="bold">
                Pre-Inscripción
              </SoftTypography>
              <SoftTypography variant="body2" color="white" fontWeight="regular">
                Inscribite a la UPrO, tu camino profesional comienza ahora
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox
        mt={{ xs: -28, lg: -26 }}
        mb={5}
        px={1}
        width="calc(100% - 2rem)"
        mx="auto"
        ref={containerRef}
      >
        <Slide
          direction="up"
          in={step === 0}
          mountOnEnter
          unmountOnExit
          container={containerRef.current}
        >
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={11} md={7} lg={4} xl={3}>
              <Card>
                <SoftBox p={3}>
                  <SoftBox display="flex" justifyContent="center">
                    <Icon color="primary" sx={{ fontSize: "100px !important" }}>
                      school
                    </Icon>
                  </SoftBox>
                  <SoftBox mt={2}>
                    <SoftTypography variant="h5" align="center">
                      ¿Estás listo para comenzar?
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mt={1}>
                    <SoftTypography variant="h6" align="center" fontWeight="light">
                      Necesitarás tener a mano tu DNI y un correo electrónico válido.
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mt={2}>
                    <SoftButton color="uproGreen" fullWidth circular onClick={() => setStep(1)}>
                      Comenzar &nbsp;
                      <Icon sx={{ fontWeight: "light" }}>arrow_forward</Icon>
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </Slide>
        <Slide
          direction="up"
          in={step === 1}
          mountOnEnter
          unmountOnExit
          container={containerRef.current}
        >
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} md={9} lg={9} xl={9}>
              <Card>
                <PreinscripcionAlumno
                  handleCancel={() => setStep(0)}
                  handleSuccess={(usuario, carrera) => {
                    setUsuario(usuario);
                    setCarrera(carrera);
                    setStep(2);
                  }}
                  setIdUser={setIdUser}
                />
              </Card>
            </Grid>
          </Grid>
        </Slide>
        <Slide
          direction="up"
          in={step === 2}
          mountOnEnter
          unmountOnExit
          container={containerRef.current}
        >
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={11} md={7} lg={4} xl={3}>
              <Card>
                <SoftBox p={3}>
                  <SoftBox display="flex" justifyContent="center">
                    <Icon color="success" sx={{ fontSize: "100px !important" }}>
                      check_circle
                    </Icon>
                  </SoftBox>
                  <SoftBox mt={2}>
                    <SoftTypography variant="h5" align="center">
                      Preinscripción completada
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mt={1}>
                    <SoftTypography variant="h6" align="center" fontWeight="light">
                      Se le ha enviado un correo electrónico con los pasos a seguir para completar
                      la inscripción.{" "}
                      <strong>
                        Deberá presentar los siguientes documentos del 12/09 al 04/10 en la sede más cercana:
                      </strong>
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mt={1}>
                    <SoftBox display="flex" alignItems="center" justifyContent="center">
                      <SoftTypography variant="h6" fontWeight="bold">
                        <Icon
                          color="primary"
                          sx={{
                            marginRight: "5px",
                          }}
                        >
                          file_present
                        </Icon>
                        DNI
                        <SoftTypography variant="caption" fontWeight="light" color="primary">
                          &nbsp;(Obligatorio)
                        </SoftTypography>
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      alignItems="center"
                      mt={1}
                      justifyContent="center"
                      textAlign="center"
                    >
                      <SoftTypography variant="h6" fontWeight="bold">
                        <Icon
                          color="primary"
                          sx={{
                            marginRight: "5px",
                          }}
                        >
                          file_present
                        </Icon>
                        Constancia de Finalización de Estudios Secundarios/Certificado
                        analítico/Constancia de estudios en progreso
                        <SoftTypography variant="caption" fontWeight="light" color="primary">
                          &nbsp;(Obligatorio para menores de 25 años)
                        </SoftTypography>
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox display="flex" alignItems="center" mt={1} justifyContent="center">
                      <SoftTypography variant="h6" fontWeight="bold">
                        <Icon
                          color="primary"
                          sx={{
                            marginRight: "5px",
                          }}
                        >
                          file_present
                        </Icon>
                        Declaración Jurada Firmada
                        <SoftTypography variant="caption" fontWeight="light" color="primary">
                          &nbsp;(Obligatorio)
                        </SoftTypography>
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox mt={2}>
                    <SoftButton
                      color="uproYellow"
                      fullWidth
                      circular
                      onClick={() => {
                        window.open(
                          "https://alumnos-upro.com.ar/DeclaracionJurada.pdf",
                          "_blank"
                        );
                      }}
                    >
                      Descargar Declaración Jurada&nbsp;
                      <Icon sx={{ fontWeight: "light" }}>download</Icon>
                    </SoftButton>
                  </SoftBox>
                  <SoftBox mt={2}>
                    <SoftButton
                      color="primary"
                      fullWidth
                      circular
                      onClick={() => setOpenModalCertificado(true)}
                    >
                      Ver comprobante de preinscripción&nbsp;
                      <Icon sx={{ fontWeight: "light" }}>picture_as_pdf</Icon>
                    </SoftButton>
                  </SoftBox>
                  <SoftBox mt={2}>
                    <SoftButton
                      color="uproGreen"
                      fullWidth
                      circular
                      onClick={() => {
                        setStep(0);
                        setUsuario(null);
                        setCarrera(null);
                      }}
                    >
                      Finalizar&nbsp;
                      <Icon sx={{ fontWeight: "light" }}>exit_to_app</Icon>
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </Slide>
      </SoftBox>
      <ModalCertificado
        open={openModalCertificado}
        handleClose={() => setOpenModalCertificado(false)}
        usuario={usuario}
        carrera={carrera}
        idUser={idUser}
      />
      <SoftBox py={3}>
        <Footer />
      </SoftBox>
    </PageLayout>
  );
}

export default Preinscripcion;
