

// Kaizen Dashboard Base Styles
import colors from "assets/theme/base/colors";

const { info, dark } = colors;

const globals = {
  html: {
    scrollBehavior: "smooth",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  "body": {
    paddingRight: "0px !important",
  },

  ".leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar":{
    bottom: "0",
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    margin: "0.6rem",
    boxShadow: "rgba(0, 0, 0, 0.11) 0px 4px 7px -1px, rgba(0, 0, 0, 0.07) 0px 2px 4px -1px",
    border: "none",
  },

  ".leaflet-control-layers-toggle": {
    padding: "0"
  },

  ".leaflet-touch .leaflet-bar a:first-child": {
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    margin: "0.3rem",
    boxShadow: "rgba(0, 0, 0, 0.11) 0px 4px 7px -1px, rgba(0, 0, 0, 0.07) 0px 2px 4px -1px",
    border: "none",
  },

  ".leaflet-touch .leaflet-bar a:last-child": {
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    margin: "0.3rem",
    boxShadow: "rgba(0, 0, 0, 0.11) 0px 4px 7px -1px, rgba(0, 0, 0, 0.07) 0px 2px 4px -1px",
    border: "none",
  },
};

export default globals;
