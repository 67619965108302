/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";

const getRows = (data, handleOpenMenu) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color: getUproColor("uproBlue"),
                }}
                fontSize="lg"
              >
                apartment
              </Icon>
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={"Sede"} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: getUproColor("uproBlue"),
                  }}
                  fontSize="lg"
                >
                  apartment
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.id}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.id}
            </SoftTypography>
          </SoftBox>
        ),
        codigo: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.codigo}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.codigo}
            </SoftTypography>
          </SoftBox>
        ),
        nombre: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={makeNiceText(item?.nombre, "nombre")}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {makeNiceText(item?.nombre, "nombre")}
            </SoftTypography>
          </SoftBox>
        ),
        region: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={makeNiceText(item?.region, "nombre")}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {makeNiceText(item?.region, "nombre")}
            </SoftTypography>
          </SoftBox>
        ),
        direccion: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={makeNiceText(item?.direccion, "nombre")}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {makeNiceText(`${item?.direccion}, ${item?.localidad?.nombre}, ${item?.localidad?.provincia?.nombre}`, "nombre")}
            </SoftTypography>
          </SoftBox>
        ),
        telefono: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={makeNiceText(item?.telefono, "nombre")}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {makeNiceText(item?.telefono, "nombre")}
            </SoftTypography>
          </SoftBox>
        ),
        accion: (
          <SoftBox
            mr={2}
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <Tooltip title="Opciones" placement="top">
              <Icon
                sx={{ color: getUproColor("uproGreen"), cursor: "pointer", mx: 0.5 }}
                fontSize="small"
                onClick={(e) => handleOpenMenu(e, item)}
              >
                more_vert
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", width: "1%", align: "left" },
  { name: "codigo", desc: "CUE", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "region", desc: "Región", align: "left" },
  { name: "direccion", desc: "Dirección", align: "left" },
  { name: "telefono", desc: "Teléfono", align: "left" },
  { name: "accion", desc: " ", align: "center", width: "1%", noOrder: true },
];

export default { columns, getRows };
