// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Collapse,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import SinDatos from "components/Carteles/SinDatos";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { ConfirmarContext } from "context/ConfirmarContext";
import { MenuContext } from "context/menuContext";
import dayjs from "dayjs";
import { getUproColor } from "utils/colors";
import ModalCertificadoComision from "../ModalCertificadoComision";
import ModalCertificadoLibre from "../ModalCertificadoLibre";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "90%", xxl: "70%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalInscripcionComision({
  open,
  handleClose,
  alumno,
  carrera,
  idInscripcion,
  refetch,
}) {
  const { handleSnackbar } = useContext(MessageManager);
  const [materias, setMaterias] = useState([]);
  const [expanded, setExpanded] = useState(["Calendario"]);
  const [comisiones, setComisiones] = useState([]);
  const [selectedSede, setSelectedSede] = useState(null);
  const [step, setStep] = useState(0);
  const [inicio, setInicio] = useState(true);
  const { menu } = useContext(MenuContext);
  const [openModalCertificado, setOpenModalCertificado] = useState(false);
  const [openModalCertificadoLibre, setOpenModalCertificadoLibre] = useState(false);
  const [indice, setIndice] = useState(0);
  const { confirmar } = useContext(ConfirmarContext);
  const [filtroNivel, setFiltroNivel] = useState("Todos");

  const [confirmarInscripcion, { loading: loadingConfirmar }] = useMutation(gql`
    mutation confirmarInscripcion($idInscripcion: ID!) {
      confirmarInscripcion(idInscripcion: $idInscripcion) {
        id
      }
    }
  `);

  const [confirmarInscripcionLibre, { loading: loadingConfirmarLibre }] = useMutation(gql`
    mutation confirmarInscripcionLibre($idInscripcion: ID!) {
      confirmarInscripcionLibre(idInscripcion: $idInscripcion) {
        id
      }
    }
  `);

  const [addInscripcionComision, { loading: loadingInscripcion }] = useMutation(gql`
    mutation addInscripcionComision($idComision: ID!, $idAlumno: ID!) {
      addInscripcionComision(idComision: $idComision, idAlumno: $idAlumno) {
        id
      }
    }
  `);

  const [getSedes, { data: sedes }] = useLazyQuery(
    gql`
      query getSedes {
        sedes {
          id
          nombre
          aulas {
            id
            nombre
            capacidad
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const [getCarrera, { loading: loadingMaterias }] = useLazyQuery(
    gql`
      query getCarrera($id: ID!) {
        carrera(id: $id) {
          id
          nombre
          planes {
            niveles {
              materias {
                id
                nombre
                comisiones {
                  id
                  nombre
                  codigo
                  cantidadInscriptos
                  capacidad
                  color
                  nivel
                  sede {
                    id
                  }
                  docente {
                    id
                  }
                  horarios {
                    dia
                    aula {
                      id
                      nombre
                    }
                    capacidad
                    horarioInicio
                    horarioFin
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  useEffect(() => {
    setComisiones([]);
    setMaterias([]);
    setFiltroNivel("Todos");
    setExpanded(["Calendario"]);
    setStep(0);
    setIndice(0);
    setInicio(true);
    if (open && carrera) {
      getCarrera({ variables: { id: carrera?.id } }).then((res) => {
        const materias = res.data.carrera.planes.reduce((acc, plan) => {
          const materias = plan.niveles.reduce((acc, nivel) => {
            const materias = nivel.materias.map((materia) => {
              return {
                ...materia,
                comisiones: materia.comisiones.map((comision) => {
                  return {
                    ...comision,
                    horarios: comision.horarios.map((horario) => {
                      return {
                        ...horario,
                      };
                    }),
                  };
                }),
              };
            });
            return [...acc, ...materias];
          }, []);
          return [...acc, ...materias];
        }, []);
        setMaterias(materias);
      });
      getSedes();
    }
  }, [open]);

  useEffect(() => {
    if (sedes) {
      setSelectedSede(sedes.sedes[0]);
    }
  }, [sedes]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Inscripción a comisiones</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox pb={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Card>
                    <SoftBox variant="gradient">
                      <SoftBox p={2}>
                        <Grid container alignItems="center">
                          <Grid item xs={8}>
                            <SoftBox lineHeight={1}>
                              <SoftTypography variant="button">Nivel de Matemáticas</SoftTypography>
                              <SoftTypography variant="h5" fontWeight="bold">
                                {alumno?.escalaMatematicas}&nbsp;
                                <SoftTypography
                                  variant="button"
                                  color={
                                    alumno?.escalaMatematicas < 6
                                      ? "primary"
                                      : alumno?.escalaMatematicas < 8
                                      ? "uproYellow"
                                      : "uproGreen"
                                  }
                                  fontWeight="bold"
                                >
                                  {`(${
                                    alumno?.escalaMatematicas < 6
                                      ? "Bajo"
                                      : alumno?.escalaMatematicas < 8
                                      ? "Medio"
                                      : "Alto"
                                  })`}
                                </SoftTypography>
                              </SoftTypography>
                            </SoftBox>
                          </Grid>
                          <Grid item xs={4}>
                            <SoftBox
                              variant="gradient"
                              color={"white"}
                              width="3rem"
                              height="3rem"
                              marginLeft="auto"
                              borderRadius="md"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              shadow="md"
                              sx={{
                                backgroundColor:
                                  alumno?.escalaMatematicas < 6
                                    ? getUproColor("uproPink")
                                    : alumno?.escalaMatematicas < 8
                                    ? getUproColor("uproYellow")
                                    : getUproColor("uproGreen"),
                              }}
                            >
                              <Icon fontSize="small" color="inherit">
                                {alumno?.escalaMatematicas < 6
                                  ? "arrow_downward"
                                  : alumno?.escalaMatematicas < 8
                                  ? "arrow_forward"
                                  : "arrow_upward"}
                              </Icon>
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card>
                    <SoftBox variant="gradient">
                      <SoftBox p={2}>
                        <Grid container alignItems="center">
                          <Grid item xs={8}>
                            <SoftBox lineHeight={1}>
                              <SoftTypography variant="button">
                                Nivel de Comprensión de Textos
                              </SoftTypography>
                              <SoftTypography variant="h5" fontWeight="bold">
                                {alumno?.escalaComprensionTextos}&nbsp;
                                <SoftTypography
                                  variant="button"
                                  color={
                                    alumno?.escalaComprensionTextos < 6
                                      ? "primary"
                                      : alumno?.escalaComprensionTextos < 8
                                      ? "uproYellow"
                                      : "uproGreen"
                                  }
                                  fontWeight="bold"
                                >
                                  {`(${
                                    alumno?.escalaComprensionTextos < 6
                                      ? "Bajo"
                                      : alumno?.escalaComprensionTextos < 8
                                      ? "Medio"
                                      : "Alto"
                                  })`}
                                </SoftTypography>
                              </SoftTypography>
                            </SoftBox>
                          </Grid>
                          <Grid item xs={4}>
                            <SoftBox
                              variant="gradient"
                              color={"white"}
                              width="3rem"
                              height="3rem"
                              marginLeft="auto"
                              borderRadius="md"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              shadow="md"
                              sx={{
                                backgroundColor:
                                  alumno?.escalaComprensionTextos < 6
                                    ? getUproColor("uproPink")
                                    : alumno?.escalaComprensionTextos < 8
                                    ? getUproColor("uproYellow")
                                    : getUproColor("uproGreen"),
                              }}
                            >
                              <Icon fontSize="small" color="inherit">
                                {alumno?.escalaComprensionTextos < 6
                                  ? "arrow_downward"
                                  : alumno?.escalaComprensionTextos < 8
                                  ? "arrow_forward"
                                  : "arrow_upward"}
                              </Icon>
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Grid>
              </Grid>
              {inicio ? (
                <SoftBox display="flex" justifyContent="center" mt={2}>
                  <SoftBox
                    sx={{
                      width: "50%",
                    }}
                  >
                    <Card>
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        textAlign={"center"}
                        justifyContent="center"
                        alignItems="center"
                        p={3}
                      >
                        <SoftBox>
                          <Icon fontSize="large" color="primary">
                            edit_calendar
                          </Icon>
                          <SoftTypography variant="h6">Inscripción a comisión</SoftTypography>
                          <SoftTypography variant="caption" component="p" color="text">
                            Seleccione como quiere proceder a la inscripción, si quiere rendir libre
                            o inscribirse a las comisiones del cursillo.
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          mt={2}
                          sx={{
                            width: "50%",
                          }}
                        >
                          <SoftButton
                            color="primary"
                            fullWidth
                            circular
                            onClick={() =>
                              confirmar({
                                title: "Rendir libre",
                                message:
                                  "¿Está seguro que desea inscribir al alumno como libre para el curso de nivelación?",
                                icon: "warning",
                                func: () => {
                                  confirmarInscripcionLibre({
                                    variables: {
                                      idInscripcion: idInscripcion,
                                    },
                                  }).then(() => {
                                    setOpenModalCertificadoLibre(true);
                                    refetch();
                                  });
                                },
                              })
                            }
                            disabled={loadingConfirmarLibre}
                          >
                            Rendir libre &nbsp;
                            <Icon>arrow_forward</Icon>
                          </SoftButton>
                        </SoftBox>
                        <SoftBox
                          mt={2}
                          sx={{
                            width: "50%",
                          }}
                        >
                          <SoftButton
                            color="uproGreen"
                            fullWidth
                            circular
                            onClick={() => setInicio(false)}
                            disabled={loadingConfirmarLibre || loadingMaterias}
                          >
                            {loadingMaterias
                              ? "Obteniendo comisiones..."
                              : "Inscribir a comisiones"}
                            &nbsp;
                            {loadingMaterias ? (
                              <CircularProgress
                                size={15}
                                sx={{
                                  marginRight: 0.5,
                                }}
                                color="inherit"
                              />
                            ) : (
                              <Icon>arrow_forward</Icon>
                            )}
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </SoftBox>
              ) : (
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} sm={6}>
                    <InputLabel htmlFor="sede">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Sede
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    {loadingMaterias ? (
                      <Skeleton variant="text" width="100%" height={40} />
                    ) : (
                      <Select
                        fullWidth
                        variant="outlined"
                        value={selectedSede?.id || -1}
                        disabled={step > 0}
                        onChange={(e) => {
                          setSelectedSede(
                            sedes.sedes.find((sede) => sede.id === e.target.value) || null
                          );
                          setIndice(0);
                          setComisiones([]);
                        }}
                      >
                        <MenuItem value={-1} disabled>
                          Seleccione uno
                        </MenuItem>
                        {sedes?.sedes?.map((sede) => (
                          <MenuItem key={sede.id} value={sede.id}>
                            {sede.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel htmlFor="sede">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Filtrar por nivel
                      </SoftTypography>
                    </InputLabel>
                    {loadingMaterias ? (
                      <Skeleton variant="text" width="100%" height={40} />
                    ) : (
                      <Select
                        fullWidth
                        variant="outlined"
                        value={filtroNivel}
                        onChange={(e) => {
                          setFiltroNivel(e.target.value);
                          setIndice(0);
                          if (comisiones[step]?.nivel !== e.target.value) {
                            setComisiones((prev) => {
                              const comisiones = [...prev];
                              comisiones[step] = null;
                              return comisiones;
                            });
                          }
                        }}
                      >
                        <MenuItem value={"Todos"}>Todos</MenuItem>
                        <MenuItem value={"Bajo"}>Bajo</MenuItem>
                        <MenuItem value={"Medio"}>Medio</MenuItem>
                        <MenuItem value={"Alto"}>Alto</MenuItem>
                      </Select>
                    )}
                  </Grid>
                  <Grid item xs={12} display="flex">
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mr={2}
                    >
                      <SoftButton
                        color="primary"
                        circular
                        iconOnly
                        onClick={() => {
                          if (indice > 0) {
                            setIndice((prev) => prev - 4);
                          }
                        }}
                        disabled={indice === 0 || loadingMaterias}
                      >
                        <Icon>arrow_back</Icon>
                      </SoftButton>
                    </SoftBox>
                    <SoftBox
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Grid container spacing={2}>
                        {loadingMaterias ? (
                          new Array(4).fill(0).map((_, index) => {
                            return (
                              <Grid item xs={4} sm={3} key={index}>
                                <Card>
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={100}
                                    sx={{
                                      borderRadius: "10px",
                                    }}
                                  />
                                </Card>
                              </Grid>
                            );
                          })
                        ) : materias.length > 0 ? (
                          materias[step]?.comisiones
                            ?.filter(
                              (comision) =>
                                comision.sede.id === selectedSede?.id &&
                                comision.capacidad > comision.cantidadInscriptos &&
                                (filtroNivel === "Todos" || comision.nivel === filtroNivel)
                            )
                            .filter((comision) => {
                              let comisionSeleccionadaAnterior = null;

                              // Obtener la comisión seleccionada de la materia 1 (si step > 0)
                              if (step > 0) {
                                comisionSeleccionadaAnterior = comisiones[step - 1]; // Comisión seleccionada previamente
                              }

                              // Si existe una comisión seleccionada previamente
                              if (comisionSeleccionadaAnterior) {
                                const horariosSeleccionados = comisionSeleccionadaAnterior.horarios;

                                let response = !comision.horarios.some((horario) =>
                                  horariosSeleccionados.some(
                                    (horarioSeleccionado) =>
                                      horario.dia === horarioSeleccionado.dia &&
                                      (dayjs(horario.horarioInicio, "HH:mm").isBetween(
                                        dayjs(horarioSeleccionado.horarioInicio, "HH:mm"),
                                        dayjs(horarioSeleccionado.horarioFin, "HH:mm"),
                                        null,
                                        "[)"
                                      ) ||
                                        dayjs(horario.horarioFin, "HH:mm").isBetween(
                                          dayjs(horarioSeleccionado.horarioInicio, "HH:mm"),
                                          dayjs(horarioSeleccionado.horarioFin, "HH:mm"),
                                          null,
                                          "(]"
                                        ) ||
                                        (dayjs(horario.horarioInicio, "HH:mm").isBefore(
                                          dayjs(horarioSeleccionado.horarioInicio, "HH:mm")
                                        ) &&
                                          dayjs(horario.horarioFin, "HH:mm").isAfter(
                                            dayjs(horarioSeleccionado.horarioFin, "HH:mm")
                                          )))
                                  )
                                );
                                return response;
                              } else {
                                return true;
                              }
                            })
                            .slice(indice, indice + 4)
                            .map((comision, index) => {
                              return (
                                <Grid item xs={4} sm={3} key={index}>
                                  <Card
                                    sx={{
                                      cursor: "pointer",
                                      height: "100%",
                                      border:
                                        comisiones[step]?.id === comision.id
                                          ? `2px solid ${comision.color}`
                                          : "none",
                                    }}
                                  >
                                    <SoftBox
                                      p={3}
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      onClick={() => {
                                        if (comisiones[step]?.id === comision.id) {
                                          setComisiones((prev) => {
                                            return prev.filter((_, i) => i !== step);
                                          });
                                        } else {
                                          setComisiones((prev) => {
                                            const comisiones = [...prev];
                                            comisiones[step] = comision;
                                            return comisiones;
                                          });
                                        }
                                      }}
                                      sx={{
                                        height: "100%",
                                      }}
                                    >
                                      <SoftBox>
                                        <SoftTypography
                                          variant="button"
                                          fontWeight="bold"
                                          sx={{
                                            color: !comisiones[step]?.id
                                              ? comision.color
                                              : comisiones[step]?.id === comision.id
                                              ? comision.color
                                              : getUproColor(),
                                          }}
                                        >
                                          {`${comision.codigo} - ${comision.nombre}`}
                                        </SoftTypography>
                                      </SoftBox>
                                      {comision.horarios.map((horario, index) => (
                                        <SoftBox
                                          textAlign="center"
                                          sx={{
                                            lineHeight: "normal",
                                          }}
                                          key={index}
                                        >
                                          <SoftTypography variant="caption" fontWeight="light">
                                            {`${horario.dia} de ${dayjs(
                                              horario.horarioInicio,
                                              "HH:mm:ss"
                                            ).format("HH:mm")} a ${dayjs(
                                              horario.horarioFin,
                                              "HH:mm:ss"
                                            ).format("HH:mm")}`}
                                          </SoftTypography>
                                        </SoftBox>
                                      ))}
                                      <SoftBox
                                        display="flex"
                                        justifyContent="space-between"
                                        mt={2}
                                        sx={{
                                          width: "100%",
                                        }}
                                      >
                                        <SoftBox>
                                          <SoftBadge
                                            container
                                            color={
                                              comision.cantidadInscriptos >= comision.capacidad
                                                ? "primary"
                                                : comision.cantidadInscriptos >=
                                                  comision.capacidad * 0.8
                                                ? "uproYellow"
                                                : "uproGreen"
                                            }
                                            badgeContent={`${comision.cantidadInscriptos}/${comision.capacidad}`}
                                          />
                                        </SoftBox>
                                        <SoftBox display="flex" alignItems="center">
                                          <SoftTypography variant="caption" fontWeight="bold">
                                            {comision.nivel}
                                          </SoftTypography>
                                          <Icon
                                            fontSize="small"
                                            sx={{
                                              marginLeft: 0.5,
                                              color:
                                                comision.nivel === "Bajo"
                                                  ? getUproColor("uproPink")
                                                  : comision.nivel === "Medio"
                                                  ? getUproColor("uproYellow")
                                                  : getUproColor("uproGreen"),
                                            }}
                                          >
                                            {comision.nivel === "Bajo"
                                              ? "arrow_downward"
                                              : comision.nivel === "Medio"
                                              ? "arrow_forward"
                                              : "arrow_upward"}
                                          </Icon>
                                        </SoftBox>
                                      </SoftBox>
                                    </SoftBox>
                                  </Card>
                                </Grid>
                              );
                            })
                        ) : (
                          <SinDatos />
                        )}
                      </Grid>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      ml={2}
                    >
                      <SoftButton
                        color="primary"
                        circular
                        iconOnly
                        onClick={() => {
                          if (indice + 4 < materias[step]?.comisiones.length) {
                            setIndice((prev) => prev + 4);
                          }
                        }}
                        disabled={
                          indice + 4 >=
                            materias[step]?.comisiones
                              ?.filter(
                                (comision) =>
                                  comision.sede.id === selectedSede?.id &&
                                  comision.capacidad > comision.cantidadInscriptos &&
                                  (filtroNivel === "Todos" || comision.nivel === filtroNivel)
                              )
                              .filter((comision) => {
                                let comisionSeleccionadaAnterior = null;

                                // Obtener la comisión seleccionada de la materia 1 (si step > 0)
                                if (step > 0) {
                                  comisionSeleccionadaAnterior = comisiones[step - 1]; // Comisión seleccionada previamente
                                }

                                // Si existe una comisión seleccionada previamente
                                if (comisionSeleccionadaAnterior) {
                                  const horariosSeleccionados =
                                    comisionSeleccionadaAnterior.horarios;

                                  let response = !comision.horarios.some((horario) =>
                                    horariosSeleccionados.some(
                                      (horarioSeleccionado) =>
                                        horario.dia === horarioSeleccionado.dia &&
                                        (dayjs(horario.horarioInicio, "HH:mm").isBetween(
                                          dayjs(horarioSeleccionado.horarioInicio, "HH:mm"),
                                          dayjs(horarioSeleccionado.horarioFin, "HH:mm"),
                                          null,
                                          "[)"
                                        ) ||
                                          dayjs(horario.horarioFin, "HH:mm").isBetween(
                                            dayjs(horarioSeleccionado.horarioInicio, "HH:mm"),
                                            dayjs(horarioSeleccionado.horarioFin, "HH:mm"),
                                            null,
                                            "(]"
                                          ) ||
                                          (dayjs(horario.horarioInicio, "HH:mm").isBefore(
                                            dayjs(horarioSeleccionado.horarioInicio, "HH:mm")
                                          ) &&
                                            dayjs(horario.horarioFin, "HH:mm").isAfter(
                                              dayjs(horarioSeleccionado.horarioFin, "HH:mm")
                                            )))
                                    )
                                  );
                                  return response;
                                } else {
                                  return true;
                                }
                              }).length || loadingMaterias
                        }
                      >
                        <Icon>arrow_forward</Icon>
                      </SoftButton>
                    </SoftBox>
                  </Grid>
                  {loadingMaterias ? (
                    <SoftBox
                      pl={2}
                      mt={2}
                      sx={{
                        width: "100%",
                        height: "200px",
                      }}
                    >
                      <Skeleton
                        variant="rounded"
                        width="100%"
                        height={200}
                        sx={{
                          borderRadius: "10px",
                        }}
                      />
                    </SoftBox>
                  ) : materias.length > 0 ? (
                    <Grid item xs={12}>
                      <Card
                        sx={{
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                      >
                        <SoftBox
                          display="flex"
                          justifyContent="space-between"
                          px={2}
                          py={1}
                          sx={{
                            borderBottom: !expanded.includes("Calendario")
                              ? "none"
                              : "1px solid rgba(0, 0, 0, 0.12)",
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.04)",
                            },
                          }}
                        >
                          <SoftBox display="flex" alignItems="center">
                            <SoftBox mr={1} display="flex" alignItems="center">
                              <Icon
                                sx={{
                                  color: getUproColor("uproYellow"),
                                }}
                              >
                                calendar_today
                              </Icon>
                            </SoftBox>
                            <SoftTypography variant="h6" fontWeight="bold">
                              {`Calendario de ${selectedSede?.nombre}`}
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                            <SoftBox>
                              <Tooltip
                                title={expanded.includes("Calendario") ? "Ocultar" : "Mostrar"}
                                placement="top"
                              >
                                <SoftButton
                                  color="primary"
                                  circular
                                  iconOnly
                                  onClick={() =>
                                    setExpanded(
                                      expanded.includes("Calendario")
                                        ? expanded.filter((i) => i !== "Calendario")
                                        : [...expanded, "Calendario"]
                                    )
                                  }
                                >
                                  <Icon
                                    sx={{
                                      rotate: expanded.includes("Calendario") ? "180deg" : "0deg",
                                      transition: "all 0.5s",
                                    }}
                                  >
                                    expand_more
                                  </Icon>
                                </SoftButton>
                              </Tooltip>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                        <Collapse in={expanded.includes("Calendario")}>
                          <SoftBox sx={{ maxHeight: "50vh", overflowY: "scroll" }}>
                            <Table stickyHeader>
                              <TableHead
                                sx={{
                                  display: "revert !important",
                                }}
                              >
                                <TableRow>
                                  <TableCell>&nbsp;</TableCell>
                                  <TableCell
                                    sx={{
                                      textAlign: "center",
                                    }}
                                  >
                                    Lunes
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      textAlign: "center",
                                    }}
                                  >
                                    Martes
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      textAlign: "center",
                                    }}
                                  >
                                    Miércoles
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      textAlign: "center",
                                    }}
                                  >
                                    Jueves
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      textAlign: "center",
                                    }}
                                  >
                                    Viernes
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              {new Array(17).fill(0).map((_, index) => {
                                const hora = dayjs()
                                  .startOf("day")
                                  .add(index + 7, "hour");

                                const comisionesSede = materias[step]?.comisiones?.filter(
                                  (comision) =>
                                    comision.sede.id === selectedSede?.id &&
                                    comision.capacidad > comision.cantidadInscriptos &&
                                    (filtroNivel === "Todos" || comision.nivel === filtroNivel)
                                );

                                const filtrarComisionesPorDiaYHora = (dia) => {
                                  let comisionesData =
                                    comisionesSede?.filter((comision) =>
                                      comision.horarios.some(
                                        (horario) =>
                                          horario.dia === dia &&
                                          (dayjs(horario.horarioInicio, "HH:mm").isSame(
                                            hora,
                                            "hour"
                                          ) ||
                                            (dayjs(horario.horarioInicio, "HH:mm").isBefore(
                                              hora,
                                              "hour"
                                            ) &&
                                              dayjs(horario.horarioFin, "HH:mm").isAfter(
                                                hora,
                                                "hour"
                                              )))
                                      )
                                    ) || [];

                                  let comisionSeleccionadaAnterior = null;

                                  // Obtener la comisión seleccionada de la materia 1 (si step > 0)
                                  if (step > 0) {
                                    comisionSeleccionadaAnterior = comisiones[step - 1]; // Comisión seleccionada previamente
                                  }

                                  // Si existe una comisión seleccionada previamente
                                  if (comisionSeleccionadaAnterior) {
                                    const horariosSeleccionados =
                                      comisionSeleccionadaAnterior.horarios;

                                    // Filtrar comisiones de la materia 2 que NO tengan ningún horario superpuesto con la comisión de la materia 1
                                    comisionesData = comisionesData.filter(
                                      (comision) =>
                                        !comision.horarios.some((horario) =>
                                          horariosSeleccionados.some(
                                            (horarioSeleccionado) =>
                                              horario.dia === horarioSeleccionado.dia &&
                                              (dayjs(horario.horarioInicio, "HH:mm").isBetween(
                                                dayjs(horarioSeleccionado.horarioInicio, "HH:mm"),
                                                dayjs(horarioSeleccionado.horarioFin, "HH:mm"),
                                                null,
                                                "[)"
                                              ) ||
                                                dayjs(horario.horarioFin, "HH:mm").isBetween(
                                                  dayjs(horarioSeleccionado.horarioInicio, "HH:mm"),
                                                  dayjs(horarioSeleccionado.horarioFin, "HH:mm"),
                                                  null,
                                                  "(]"
                                                ) ||
                                                (dayjs(horario.horarioInicio, "HH:mm").isBefore(
                                                  dayjs(horarioSeleccionado.horarioInicio, "HH:mm")
                                                ) &&
                                                  dayjs(horario.horarioFin, "HH:mm").isAfter(
                                                    dayjs(horarioSeleccionado.horarioFin, "HH:mm")
                                                  )))
                                          )
                                        )
                                    );

                                    // Asegurarse de que la comisión seleccionada de la materia 1 se muestre solo en su horario correspondiente
                                    const horariosMateria1 =
                                      comisionSeleccionadaAnterior.horarios.filter(
                                        (horario) => horario.dia === dia
                                      );

                                    // Agregar la comisión seleccionada de la materia 1 SOLO si su horario coincide con la hora actual
                                    horariosMateria1.forEach((horario) => {
                                      if (
                                        dayjs(horario.horarioInicio, "HH:mm").isSame(
                                          hora,
                                          "hour"
                                        ) ||
                                        (dayjs(horario.horarioInicio, "HH:mm").isBefore(
                                          hora,
                                          "hour"
                                        ) &&
                                          dayjs(horario.horarioFin, "HH:mm").isAfter(hora, "hour"))
                                      ) {
                                        comisionesData = [
                                          ...comisionesData,
                                          comisionSeleccionadaAnterior,
                                        ];
                                      }
                                    });
                                  }

                                  return comisionesData;
                                };

                                return (
                                  <TableRow key={index}>
                                    <TableCell>{hora.format("HH:mm")}</TableCell>

                                    <TableCell
                                      sx={{
                                        backgroundColor:
                                          filtrarComisionesPorDiaYHora("Lunes").length > 0
                                            ? comisiones[step]?.id
                                              ? filtrarComisionesPorDiaYHora("Lunes").find(
                                                  (comision) => comision.id === comisiones[step]?.id
                                                )
                                                ? filtrarComisionesPorDiaYHora("Lunes").find(
                                                    (comision) =>
                                                      comision.id === comisiones[step]?.id
                                                  ).color
                                                : "#96a2b8"
                                              : filtrarComisionesPorDiaYHora("Lunes")[0].color
                                            : "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      {filtrarComisionesPorDiaYHora("Lunes").map((comision) => (
                                        <SoftBox
                                          key={comision.id}
                                          display="flex"
                                          flexDirection="column"
                                        >
                                          <SoftTypography
                                            variant="caption"
                                            fontWeight="bold"
                                            color="white"
                                          >{`${comision.codigo} - ${comision.nombre}`}</SoftTypography>
                                          <SoftTypography
                                            variant="caption"
                                            fontWeight="light"
                                            color="white"
                                          >
                                            {comision?.horarios?.find((d) => d.dia === "Lunes")
                                              ?.aula?.nombre || ""}
                                          </SoftTypography>
                                        </SoftBox>
                                      ))}
                                    </TableCell>

                                    <TableCell
                                      sx={{
                                        backgroundColor:
                                          filtrarComisionesPorDiaYHora("Martes").length > 0
                                            ? comisiones[step]?.id
                                              ? filtrarComisionesPorDiaYHora("Martes").find(
                                                  (comision) => comision.id === comisiones[step]?.id
                                                )
                                                ? filtrarComisionesPorDiaYHora("Martes").find(
                                                    (comision) =>
                                                      comision.id === comisiones[step]?.id
                                                  ).color
                                                : "#96a2b8"
                                              : filtrarComisionesPorDiaYHora("Martes")[0].color
                                            : "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      {filtrarComisionesPorDiaYHora("Martes").map((comision) => (
                                        <SoftBox
                                          key={comision.id}
                                          display="flex"
                                          flexDirection="column"
                                        >
                                          <SoftTypography
                                            variant="caption"
                                            fontWeight="bold"
                                            color="white"
                                          >{`${comision.codigo} - ${comision.nombre}`}</SoftTypography>
                                          <SoftTypography
                                            variant="caption"
                                            fontWeight="light"
                                            color="white"
                                          >
                                            {comision?.horarios?.find((d) => d.dia === "Martes")
                                              ?.aula?.nombre || ""}
                                          </SoftTypography>
                                        </SoftBox>
                                      ))}
                                    </TableCell>

                                    <TableCell
                                      sx={{
                                        backgroundColor:
                                          filtrarComisionesPorDiaYHora("Miércoles").length > 0
                                            ? comisiones[step]?.id
                                              ? filtrarComisionesPorDiaYHora("Miércoles").find(
                                                  (comision) => comision.id === comisiones[step]?.id
                                                )
                                                ? filtrarComisionesPorDiaYHora("Miércoles").find(
                                                    (comision) =>
                                                      comision.id === comisiones[step]?.id
                                                  ).color
                                                : "#96a2b8"
                                              : filtrarComisionesPorDiaYHora("Miércoles")[0].color
                                            : "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      {filtrarComisionesPorDiaYHora("Miércoles").map((comision) => (
                                        <SoftBox
                                          key={comision.id}
                                          display="flex"
                                          flexDirection="column"
                                        >
                                          <SoftTypography
                                            variant="caption"
                                            fontWeight="bold"
                                            color="white"
                                          >{`${comision.codigo} - ${comision.nombre}`}</SoftTypography>
                                          <SoftTypography
                                            variant="caption"
                                            fontWeight="light"
                                            color="white"
                                          >
                                            {comision?.horarios?.find((d) => d.dia === "Miércoles")
                                              ?.aula?.nombre || ""}
                                          </SoftTypography>
                                        </SoftBox>
                                      ))}
                                    </TableCell>

                                    <TableCell
                                      sx={{
                                        backgroundColor:
                                          filtrarComisionesPorDiaYHora("Jueves").length > 0
                                            ? comisiones[step]?.id
                                              ? filtrarComisionesPorDiaYHora("Jueves").find(
                                                  (comision) => comision.id === comisiones[step]?.id
                                                )
                                                ? filtrarComisionesPorDiaYHora("Jueves").find(
                                                    (comision) =>
                                                      comision.id === comisiones[step]?.id
                                                  ).color
                                                : "#96a2b8"
                                              : filtrarComisionesPorDiaYHora("Jueves")[0].color
                                            : "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      {filtrarComisionesPorDiaYHora("Jueves").map((comision) => (
                                        <SoftBox
                                          key={comision.id}
                                          display="flex"
                                          flexDirection="column"
                                        >
                                          <SoftTypography
                                            variant="caption"
                                            fontWeight="bold"
                                            color="white"
                                          >{`${comision.codigo} - ${comision.nombre}`}</SoftTypography>
                                          <SoftTypography
                                            variant="caption"
                                            fontWeight="light"
                                            color="white"
                                          >
                                            {comision?.horarios?.find((d) => d.dia === "Jueves")
                                              ?.aula?.nombre || ""}
                                          </SoftTypography>
                                        </SoftBox>
                                      ))}
                                    </TableCell>

                                    <TableCell
                                      sx={{
                                        backgroundColor:
                                          filtrarComisionesPorDiaYHora("Viernes").length > 0
                                            ? comisiones[step]?.id
                                              ? filtrarComisionesPorDiaYHora("Viernes").find(
                                                  (comision) => comision.id === comisiones[step]?.id
                                                )
                                                ? filtrarComisionesPorDiaYHora("Viernes").find(
                                                    (comision) =>
                                                      comision.id === comisiones[step]?.id
                                                  ).color
                                                : "#96a2b8"
                                              : filtrarComisionesPorDiaYHora("Viernes")[0].color
                                            : "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      {filtrarComisionesPorDiaYHora("Viernes").map((comision) => (
                                        <SoftBox
                                          key={comision.id}
                                          display="flex"
                                          flexDirection="column"
                                        >
                                          <SoftTypography
                                            variant="caption"
                                            fontWeight="bold"
                                            color="white"
                                          >{`${comision.codigo} - ${comision.nombre}`}</SoftTypography>
                                          <SoftTypography
                                            variant="caption"
                                            fontWeight="light"
                                            color="white"
                                          >
                                            {comision?.horarios?.find((d) => d.dia === "Viernes")
                                              ?.aula?.nombre || ""}
                                          </SoftTypography>
                                        </SoftBox>
                                      ))}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </Table>
                          </SoftBox>
                        </Collapse>
                      </Card>
                    </Grid>
                  ) : (
                    <SinDatos />
                  )}
                </Grid>
              )}
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: inicio ? 0 : 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={() => {
                    if (inicio) {
                      handleClose();
                    } else {
                      if (step === 0) {
                        setInicio(true);
                      } else {
                        setFiltroNivel("Todos");
                        setStep((prev) => prev - 1);
                      }
                    }
                  }}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  <Icon sx={{ fontWeight: "light" }}>{inicio ? "close" : "arrow_back"}</Icon>
                  &nbsp;{inicio ? "Cancelar" : "Anterior"}
                </SoftButton>
              </SoftBox>
              {!inicio && (
                <SoftBox
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="uproGreen"
                    circular
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                    disabled={comisiones[step]?.id === -1 || comisiones[step]?.id === undefined}
                    onClick={() => {
                      if (step === materias.length - 1) {
                        let promises = comisiones.map((comision) => {
                          return addInscripcionComision({
                            variables: {
                              idComision: comision.id,
                              idAlumno: alumno?.id,
                            },
                          });
                        });

                        promises.push(
                          confirmarInscripcion({
                            variables: {
                              idInscripcion: idInscripcion,
                            },
                          })
                        );

                        Promise.all(promises)
                          .then(() => {
                            handleSnackbar("Inscripción realizada correctamente", "success");
                            refetch();
                            setOpenModalCertificado(true);
                          })
                          .catch((e) => {
                            handleSnackbar(
                              e.message || "Error al realizar la inscripción",
                              "error"
                            );
                          });
                      } else {
                        setStep((prev) => prev + 1);
                        setIndice(0);
                        setFiltroNivel("Todos");
                      }
                    }}
                  >
                    {loadingConfirmar || loadingInscripcion ? (
                      <CircularProgress
                        size={15}
                        sx={{
                          marginRight: 0.5,
                        }}
                        color="inherit"
                      />
                    ) : (
                      <Icon sx={{ fontWeight: "light" }}>
                        {step === materias.length - 1 ? "save" : "arrow_forward"}
                      </Icon>
                    )}
                    &nbsp;{step === materias.length - 1 ? "Guardar" : "Siguiente"}
                  </SoftButton>
                </SoftBox>
              )}
              <ModalCertificadoComision
                open={openModalCertificado}
                handleClose={() => {
                  setOpenModalCertificado(false);
                  handleClose();
                }}
                sede={selectedSede}
                comisiones={
                  comisiones.length === materias.length
                    ? materias
                        .map((materia, index) => {
                          return {
                            materia: materia,
                            comision: materias[index].comisiones.find(
                              (comision) => comision?.id === comisiones[index]?.id
                            ),
                          };
                        })
                        ?.map((item) => {
                          return item.comision;
                        }) || []
                    : []
                }
                alumno={alumno}
                carrera={carrera}
              />
              <ModalCertificadoLibre
                open={openModalCertificadoLibre}
                handleClose={() => {
                  setOpenModalCertificadoLibre(false);
                  handleClose();
                }}
                sede={selectedSede}
                alumno={alumno}
                carrera={carrera}
              />
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalInscripcionComision.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  alumno: PropTypes.object,
  carrera: PropTypes.string,
  idInscripcion: PropTypes.string,
  refetch: PropTypes.func,
};
