// @mui material components
import { Card, Fade, Icon, Modal, Tab, Tabs, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import ReporteCarreras from "../Tabs/Carreras";
import ReporteDemografico from "../Tabs/Demografico";
import ReporteEducativo from "../Tabs/Educativo";
import ReporteGeografico from "../Tabs/Geografico";
import { gql, useLazyQuery } from "@apollo/client";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "90%", xxl: "70%" },
  overflow: "auto",
  py: 4,
};

export default function ModalReporteInscripciones({ open, handleClose, tipo }) {
  const [item, setItem] = useState(0);

  const [getSedes, { data: sedes }] = useLazyQuery(gql`
    query getSedes {
      sedes(order: { field: "nombre", order: ASC },){
        id
        nombre
        codigo
      }
    }
  `);

  useEffect(() => {
    setItem(0);
    getSedes();
  }, [open]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" px={3}>
              <SoftTypography variant="h6">{`Reporte de ${
                tipo === "Preinscripto" ? "preinscripciones" : "inscripciones"
              }`}</SoftTypography>
              <Icon
                sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                  fontSize: `${size.lg} !important`,
                  fontWeight: `${fontWeightBold} !important`,
                  stroke: dark.main,
                  strokeWidth: "2px",
                  cursor: "pointer",
                })}
                onClick={handleClose}
              >
                close
              </Icon>
            </SoftBox>
            <SoftBox p={3}>
              <Tabs value={item} scrollButtons allowScrollButtonsMobile variant="fullWidth">
                <Tooltip title="Reporte de Carreras" placement="top">
                  <Tab
                    icon={
                      <SoftBox display="flex" justifyContent="center" alignItems="center" py={1}>
                        <Icon fontSize="xxl">history_edu</Icon>
                      </SoftBox>
                    }
                    onClick={() => {
                      setItem(0);
                    }}
                  />
                </Tooltip>
                <Tooltip title="Reporte demográfico" placement="top">
                  <Tab
                    icon={
                      <SoftBox display="flex" justifyContent="center" alignItems="center" py={1}>
                        <Icon fontSize="xxl">people</Icon>
                      </SoftBox>
                    }
                    onClick={() => setItem(1)}
                  />
                </Tooltip>
                <Tooltip title="Reporte educativo" placement="top">
                  <Tab
                    icon={
                      <SoftBox display="flex" justifyContent="center" alignItems="center" py={1}>
                        <Icon fontSize="xxl">menu_book</Icon>
                      </SoftBox>
                    }
                    onClick={() => setItem(2)}
                  />
                </Tooltip>
                <Tooltip title="Reporte geográfico" placement="top">
                  <Tab
                    icon={
                      <SoftBox display="flex" justifyContent="center" alignItems="center" py={1}>
                        <Icon fontSize="xxl">map</Icon>
                      </SoftBox>
                    }
                    onClick={() => setItem(3)}
                  />
                </Tooltip>
              </Tabs>
            </SoftBox>
            <SoftBox px={3} pb={3}>
              {item === 0 && <ReporteCarreras tipo={tipo} sedes={sedes?.sedes || []} />}
              {item === 1 && <ReporteDemografico tipo={tipo} sedes={sedes?.sedes || []} />}
              {item === 2 && <ReporteEducativo tipo={tipo} sedes={sedes?.sedes || []} />}
              {item === 3 && <ReporteGeografico tipo={tipo} sedes={sedes?.sedes || []} />}
            </SoftBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

ModalReporteInscripciones.defaultProps = {
  tipo: "Preinscripto",
};

ModalReporteInscripciones.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  tipo: PropTypes.string,
};
