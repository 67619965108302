// React

// Components
import { Card, Collapse, Grid, Icon, IconButton, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";

// Data
import { useState } from "react";
import { getUproColor } from "utils/colors";
import ModalCertificado from "../ModalCertificado";
import SinDatos from "components/Carteles/SinDatos";
import { gql, useQuery } from "@apollo/client";
import SoftBadge from "components/SoftBadge";
import dayjs from "dayjs";
import { API_URL } from "services/config";

export default function Certificados({ usuario, loading, carrera }) {
  const [expanded, setExpanded] = useState([]);
  const [openModalCertificado, setOpenModalCertificado] = useState();
  const { data: certificados } = useQuery(gql`
    query getCertificados {
      certificados(
        pagination: { page: 1, limit: 5 }
        order: { field: "id", order: DESC }
        filter: { url: { _not: null } }
      ) {
        id
        tipo
        createdAt
        url
      }
    }
  `);

  return (
    <SoftBox>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} xxl={6} container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <SoftBox p={2}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox mr={1} display="flex" alignItems="center">
                      <SoftBox
                        display="grid"
                        justifyContent="center"
                        alignItems="center"
                        bgColor="primary"
                        color="white"
                        width="3rem"
                        height="3rem"
                        shadow="md"
                        borderRadius="lg"
                        variant="gradient"
                      >
                        <Icon fontSize="default">description</Icon>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      display="flex-row"
                      alignItems="center"
                      sx={{
                        lineHeight: 0,
                      }}
                    >
                      <SoftTypography variant="h6" fontWeight="bold">
                        Certificado de Preinscripción
                      </SoftTypography>
                      <SoftTypography variant="caption" fontWeight="regular">
                        {certificados?.certificados?.filter(
                          (certificado) => certificado.tipo === "Certificado de Preinscripción"
                        ).length
                          ? `Último certificado generado: ${dayjs(
                              certificados?.certificados?.filter(
                                (certificado) =>
                                  certificado.tipo === "Certificado de Preinscripción"
                              )[0].createdAt
                            ).format("DD/MM/YYYY HH:mm")}`
                          : "No hay certificados generados"}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    display={{
                      xs: "none",
                      sm: "block",
                    }}
                  >
                    <Tooltip title="Generar certificado" placement="top">
                      <SoftButton
                        circular
                        color="uproGreen"
                        onClick={() => {
                          setOpenModalCertificado("Certificado de Preinscripción");
                        }}
                      >
                        <Icon>download</Icon>
                        &nbsp; Generar
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                  <SoftBox
                    display={{
                      xs: "block",
                      sm: "none",
                    }}
                  >
                    <Tooltip title="Generar certificado" placement="top">
                      <SoftButton
                        circular
                        iconOnly
                        color="uproGreen"
                        onClick={() => {
                          setOpenModalCertificado("Certificado de Preinscripción");
                        }}
                      >
                        <Icon>download</Icon>
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
                <SoftBox mt={2}>
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: !expanded.includes("Certificado de Preinscripción")
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                      onClick={(e) => {
                        setExpanded(
                          expanded.includes("Certificado de Preinscripción")
                            ? expanded.filter((i) => i !== "Certificado de Preinscripción")
                            : [...expanded, "Certificado de Preinscripción"]
                        );
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon
                            sx={{
                              color: getUproColor("uproGreen"),
                            }}
                          >
                            history
                          </Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          Historial de certificados
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                        <SoftBox>
                          <Tooltip
                            title={
                              expanded.includes("Certificado de Preinscripción")
                                ? "Ocultar"
                                : "Mostrar"
                            }
                            placement="top"
                          >
                            <SoftButton
                              color="primary"
                              circular
                              iconOnly
                              onClick={() =>
                                setExpanded(
                                  expanded.includes("Certificado de Preinscripción")
                                    ? expanded.filter((i) => i !== "Certificado de Preinscripción")
                                    : [...expanded, "Certificado de Preinscripción"]
                                )
                              }
                            >
                              <Icon
                                sx={{
                                  rotate: expanded.includes("Certificado de Preinscripción")
                                    ? "180deg"
                                    : "0deg",
                                  transition: "all 0.5s",
                                }}
                              >
                                expand_more
                              </Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={expanded.includes("Certificado de Preinscripción")}>
                      {certificados?.certificados
                        ?.filter(
                          (certificado) => certificado.tipo === "Certificado de Preinscripción"
                        )
                        ?.map((certificado, index) => (
                          <SoftBox key={certificado.id}>
                            <SoftBox
                              px={2}
                              py={1}
                              sx={{
                                borderBottom:
                                  certificados?.certificados?.filter(
                                    (certificado) =>
                                      certificado.tipo === "Certificado de Preinscripción"
                                  ).length -
                                    1 ===
                                  index
                                    ? "none"
                                    : "1px solid rgba(0, 0, 0, 0.12)",
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                                },
                              }}
                            >
                              <SoftBox display="flex" justifyContent="space-between">
                                <SoftBox display="flex" alignItems="center" pl={2}>
                                  <SoftBox mr={1}>
                                    <Icon
                                      sx={{
                                        color: getUproColor("uproPink"),
                                      }}
                                    >
                                      subdirectory_arrow_right
                                    </Icon>
                                  </SoftBox>
                                  <SoftBox mr={1}>
                                    <Tooltip title="Nivel" placement="top">
                                      <Icon
                                        sx={{
                                          color: getUproColor("uproYellow"),
                                        }}
                                      >
                                        article
                                      </Icon>
                                    </Tooltip>
                                  </SoftBox>
                                  <SoftTypography variant="h6">{`${certificado?.id} - ${certificado?.tipo}`}</SoftTypography>
                                  <SoftBox ml={1} display="flex" alignItems="center">
                                    <SoftBadge
                                      color="uproYellow"
                                      badgeContent={`${dayjs(certificado?.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}`}
                                    />
                                  </SoftBox>
                                </SoftBox>
                                <SoftBox
                                  display="flex"
                                  justifyContent="flex-end"
                                  alignItems="center"
                                >
                                  <SoftBox>
                                    <Tooltip title={"Descargar"} placement="top">
                                      <SoftButton
                                        color="uproGreen"
                                        circular
                                        iconOnly
                                        onClick={async () => {
                                          const file = await fetch(`${API_URL}/${certificado.url}`);
                                          //cambiar el nombre del archivo
                                          const name = `${certificado.id} - ${certificado.tipo} - ${usuario.dni}.pdf`;
                                          const blob = await file.blob();
                                          const url = URL.createObjectURL(blob);

                                          const a = document.createElement("a");
                                          a.href = url;
                                          a.download = name;
                                          a.click();
                                        }}
                                      >
                                        <Icon>download</Icon>
                                      </SoftButton>
                                    </Tooltip>
                                  </SoftBox>
                                </SoftBox>
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>
                        ))}
                    </Collapse>
                  </Card>
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <SoftBox p={2}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox mr={1} display="flex" alignItems="center">
                      <SoftBox
                        display="grid"
                        justifyContent="center"
                        alignItems="center"
                        bgColor="primary"
                        color="white"
                        width="3rem"
                        height="3rem"
                        shadow="md"
                        borderRadius="lg"
                        variant="gradient"
                      >
                        <Icon fontSize="default">description</Icon>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      display="flex-row"
                      alignItems="center"
                      sx={{
                        lineHeight: 0,
                      }}
                    >
                      <SoftTypography variant="h6" fontWeight="bold">
                        Certificado de Inscripción
                      </SoftTypography>
                      <SoftTypography variant="caption" fontWeight="regular">
                        No hay certificados generados
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    display={{
                      xs: "none",
                      sm: "block",
                    }}
                  >
                    <Tooltip title="Generar certificado" placement="top">
                      <SoftButton circular color="uproGreen" disabled>
                        <Icon>download</Icon>
                        &nbsp; Generar
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                  <SoftBox
                    display={{
                      xs: "block",
                      sm: "none",
                    }}
                  >
                    <Tooltip title="Generar certificado" placement="top">
                      <SoftButton
                        circular
                        iconOnly
                        color="uproGreen"
                        disabled
                      >
                        <Icon>download</Icon>
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
                <SoftBox mt={2}>
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: !expanded.includes("Certificado de Inscripción")
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                      onClick={(e) => {
                        setExpanded(
                          expanded.includes("Certificado de Inscripción")
                            ? expanded.filter((i) => i !== "Certificado de Inscripción")
                            : [...expanded, "Certificado de Inscripción"]
                        );
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon
                            sx={{
                              color: getUproColor("uproGreen"),
                            }}
                          >
                            history
                          </Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          Historial de certificados
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                        <SoftBox>
                          <Tooltip
                            title={
                              expanded.includes("Certificado de Inscripción")
                                ? "Ocultar"
                                : "Mostrar"
                            }
                            placement="top"
                          >
                            <SoftButton
                              color="primary"
                              circular
                              iconOnly
                              onClick={() =>
                                setExpanded(
                                  expanded.includes("Certificado de Inscripción")
                                    ? expanded.filter((i) => i !== "Certificado de Inscripción")
                                    : [...expanded, "Certificado de Inscripción"]
                                )
                              }
                            >
                              <Icon
                                sx={{
                                  rotate: expanded.includes("Certificado de Inscripción")
                                    ? "180deg"
                                    : "0deg",
                                  transition: "all 0.5s",
                                }}
                              >
                                expand_more
                              </Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={expanded.includes("Certificado de Inscripción")}>
                      <Grid container spacing={2} p={2}>
                        <SinDatos />
                      </Grid>
                    </Collapse>
                  </Card>
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <SoftBox p={2}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox mr={1} display="flex" alignItems="center">
                      <SoftBox
                        display="grid"
                        justifyContent="center"
                        alignItems="center"
                        bgColor="primary"
                        color="white"
                        width="3rem"
                        height="3rem"
                        shadow="md"
                        borderRadius="lg"
                        variant="gradient"
                      >
                        <Icon fontSize="default">description</Icon>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      display="flex-row"
                      alignItems="center"
                      sx={{
                        lineHeight: 0,
                      }}
                    >
                      <SoftTypography variant="h6" fontWeight="bold">
                        Certificado de Alumno Regular
                      </SoftTypography>
                      <SoftTypography variant="caption" fontWeight="regular">
                        No hay certificados generados
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    display={{
                      xs: "none",
                      sm: "block",
                    }}
                  >
                    <Tooltip title="Generar certificado" placement="top">
                      <SoftButton circular color="uproGreen" disabled>
                        <Icon>download</Icon>
                        &nbsp; Generar
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                  <SoftBox
                    display={{
                      xs: "block",
                      sm: "none",
                    }}
                  >
                    <Tooltip title="Generar certificado" placement="top">
                      <SoftButton
                        circular
                        iconOnly
                        color="uproGreen"
                        disabled
                      >
                        <Icon>download</Icon>
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
                <SoftBox mt={2}>
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: !expanded.includes("Certificado de Alumno Regular")
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                      onClick={(e) => {
                        setExpanded(
                          expanded.includes("Certificado de Alumno Regular")
                            ? expanded.filter((i) => i !== "Certificado de Alumno Regular")
                            : [...expanded, "Certificado de Alumno Regular"]
                        );
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon
                            sx={{
                              color: getUproColor("uproGreen"),
                            }}
                          >
                            history
                          </Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          Historial de certificados
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                        <SoftBox>
                          <Tooltip
                            title={
                              expanded.includes("Certificado de Alumno Regular")
                                ? "Ocultar"
                                : "Mostrar"
                            }
                            placement="top"
                          >
                            <SoftButton
                              color="primary"
                              circular
                              iconOnly
                              onClick={() =>
                                setExpanded(
                                  expanded.includes("Certificado de Alumno Regular")
                                    ? expanded.filter((i) => i !== "Certificado de Alumno Regular")
                                    : [...expanded, "Certificado de Alumno Regular"]
                                )
                              }
                            >
                              <Icon
                                sx={{
                                  rotate: expanded.includes("Certificado de Alumno Regular")
                                    ? "180deg"
                                    : "0deg",
                                  transition: "all 0.5s",
                                }}
                              >
                                expand_more
                              </Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={expanded.includes("Certificado de Alumno Regular")}>
                      <Grid container spacing={2} p={2}>
                        <SinDatos />
                      </Grid>
                    </Collapse>
                  </Card>
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} xxl={6} container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <SoftBox p={2}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox mr={1} display="flex" alignItems="center">
                      <SoftBox
                        display="grid"
                        justifyContent="center"
                        alignItems="center"
                        bgColor="primary"
                        color="white"
                        width="3rem"
                        height="3rem"
                        shadow="md"
                        borderRadius="lg"
                        variant="gradient"
                      >
                        <Icon fontSize="default">description</Icon>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      display="flex-row"
                      alignItems="center"
                      sx={{
                        lineHeight: 0,
                      }}
                    >
                      <SoftTypography variant="h6" fontWeight="bold">
                        Certificado de Historial Académico
                      </SoftTypography>
                      <SoftTypography variant="caption" fontWeight="regular">
                        No hay certificados generados
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    display={{
                      xs: "none",
                      sm: "block",
                    }}
                  >
                    <Tooltip title="Generar certificado" placement="top">
                      <SoftButton circular color="uproGreen" disabled>
                        <Icon>download</Icon>
                        &nbsp; Generar
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                  <SoftBox
                    display={{
                      xs: "block",
                      sm: "none",
                    }}
                  >
                    <Tooltip title="Generar certificado" placement="top">
                      <SoftButton
                        circular
                        iconOnly
                        color="uproGreen"
                        disabled
                      >
                        <Icon>download</Icon>
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
                <SoftBox mt={2}>
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: !expanded.includes("Certificado de Historial Académico")
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                      onClick={(e) => {
                        setExpanded(
                          expanded.includes("Certificado de Historial Académico")
                            ? expanded.filter((i) => i !== "Certificado de Historial Académico")
                            : [...expanded, "Certificado de Historial Académico"]
                        );
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon
                            sx={{
                              color: getUproColor("uproGreen"),
                            }}
                          >
                            history
                          </Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          Historial de certificados
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                        <SoftBox>
                          <Tooltip
                            title={
                              expanded.includes("Certificado de Historial Académico")
                                ? "Ocultar"
                                : "Mostrar"
                            }
                            placement="top"
                          >
                            <SoftButton
                              color="primary"
                              circular
                              iconOnly
                              onClick={() =>
                                setExpanded(
                                  expanded.includes("Certificado de Historial Académico")
                                    ? expanded.filter(
                                        (i) => i !== "Certificado de Historial Académico"
                                      )
                                    : [...expanded, "Certificado de Historial Académico"]
                                )
                              }
                            >
                              <Icon
                                sx={{
                                  rotate: expanded.includes("Certificado de Historial Académico")
                                    ? "180deg"
                                    : "0deg",
                                  transition: "all 0.5s",
                                }}
                              >
                                expand_more
                              </Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={expanded.includes("Certificado de Historial Académico")}>
                      <Grid container spacing={2} p={2}>
                        <SinDatos />
                      </Grid>
                    </Collapse>
                  </Card>
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <SoftBox p={2}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox mr={1} display="flex" alignItems="center">
                      <SoftBox
                        display="grid"
                        justifyContent="center"
                        alignItems="center"
                        bgColor="primary"
                        color="white"
                        width="3rem"
                        height="3rem"
                        shadow="md"
                        borderRadius="lg"
                        variant="gradient"
                      >
                        <Icon fontSize="default">description</Icon>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      display="flex-row"
                      alignItems="center"
                      sx={{
                        lineHeight: 0,
                      }}
                    >
                      <SoftTypography variant="h6" fontWeight="bold">
                        Certificado de Analítico en Trámite
                      </SoftTypography>
                      <SoftTypography variant="caption" fontWeight="regular">
                        No hay certificados generados
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    display={{
                      xs: "none",
                      sm: "block",
                    }}
                  >
                    <Tooltip title="Generar certificado" placement="top">
                      <SoftButton circular color="uproGreen" disabled>
                        <Icon>download</Icon>
                        &nbsp; Generar
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                  <SoftBox
                    display={{
                      xs: "block",
                      sm: "none",
                    }}
                  >
                    <Tooltip title="Generar certificado" placement="top">
                      <SoftButton
                        circular
                        iconOnly
                        color="uproGreen"
                        disabled
                      >
                        <Icon>download</Icon>
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
                <SoftBox mt={2}>
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: !expanded.includes("Certificado de Analítico en Trámite")
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                      onClick={(e) => {
                        setExpanded(
                          expanded.includes("Certificado de Analítico en Trámite")
                            ? expanded.filter((i) => i !== "Certificado de Analítico en Trámite")
                            : [...expanded, "Certificado de Analítico en Trámite"]
                        );
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon
                            sx={{
                              color: getUproColor("uproGreen"),
                            }}
                          >
                            history
                          </Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          Historial de certificados
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                        <SoftBox>
                          <Tooltip
                            title={
                              expanded.includes("Certificado de Analítico en Trámite")
                                ? "Ocultar"
                                : "Mostrar"
                            }
                            placement="top"
                          >
                            <SoftButton
                              color="primary"
                              circular
                              iconOnly
                              onClick={() =>
                                setExpanded(
                                  expanded.includes("Certificado de Analítico en Trámite")
                                    ? expanded.filter(
                                        (i) => i !== "Certificado de Analítico en Trámite"
                                      )
                                    : [...expanded, "Certificado de Analítico en Trámite"]
                                )
                              }
                            >
                              <Icon
                                sx={{
                                  rotate: expanded.includes("Certificado de Analítico en Trámite")
                                    ? "180deg"
                                    : "0deg",
                                  transition: "all 0.5s",
                                }}
                              >
                                expand_more
                              </Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={expanded.includes("Certificado de Analítico en Trámite")}>
                      <Grid container spacing={2} p={2}>
                        <SinDatos />
                      </Grid>
                    </Collapse>
                  </Card>
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <ModalCertificado
        open={openModalCertificado}
        handleClose={() => setOpenModalCertificado()}
        usuario={usuario}
        carrera={carrera}
        tipo={openModalCertificado}
      />
    </SoftBox>
  );
}

Certificados.propTypes = {
  usuario: PropTypes.object,
  carrera: PropTypes.object,
  loading: PropTypes.bool,
};
