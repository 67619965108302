import PropTypes from "prop-types";
import { useContext, useState } from "react";

// @mui material components
import { Card, Divider, Icon, Skeleton, Tooltip } from "@mui/material/";
import Button from "@mui/material/Button";

// Kaizen Dashboard components
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { API_URL } from "services/config";

import { MessageManager } from "context";
import SoftButton from "components/SoftButton";

function ProfileCard({ color, usuario, loading }) {
  const { handleSnackbar } = useContext(MessageManager);
  const copyOnClick = (e) => {
    navigator.clipboard.writeText(e.target.innerText);
    handleSnackbar("Copiado al portapapeles", "success");
  };
  const [openVerMotivos, setOpenVerMotivos] = useState(false);
  const handleOpenVerMotivos = () => {
    setOpenVerMotivos(true);
  };
  const handleCloseVerMotivos = () => {
    setOpenVerMotivos(false);
  };

  const handleVerCarnet = () => {
    window.open(`${API_URL}/${usuario.fileCarnet}`, "_blank");
  };

  return (
    <Card>
      <SoftBox p={2} display="flex" justifyContent="center">
        {loading ? (
          <Skeleton>
            <SoftBox
              display="grid"
              justifyContent="center"
              alignItems="center"
              bgColor={color}
              color="white"
              width="8rem"
              height="8rem"
              shadow="md"
              borderRadius="lg"
              variant="gradient"
            ></SoftBox>
          </Skeleton>
        ) : (
          <SoftBox display="flex" justifyContent="center" alignItems="center" py={2}>
            <SoftAvatar
              display="grid"
              bgColor={color}
              color="white"
              width="8rem"
              height="8rem"
              shadow="md"
              variant="rounded"
              src={usuario?.foto ? `${API_URL}/${usuario.foto}` : null}
              size="xxl"
            />
          </SoftBox>
        )}
      </SoftBox>
      <SoftBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {loading ? (
            <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Skeleton variant="text" width={200} sx={{ fontSize: "1rem" }} />
            </SoftBox>
          ) : (
            usuario?.nombre + " " + usuario?.apellido
          )}
        </SoftTypography>
        <SoftTypography variant="h7" color="text" fontWeight="regular">
          {loading ? (
            <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Skeleton variant="text" width={100} sx={{ fontSize: "0.5rem" }} />
            </SoftBox>
          ) : (
            usuario?.username
          )}
        </SoftTypography>
        <Divider />
        <SoftBox display="flex" alignItems="center" justifyContent="center" py={1}>
          <SoftBox display="flex" alignItems="center" mr={1}>
            <Icon color="primary">description</Icon>
          </SoftBox>
          <SoftTypography variant="h7" color="text" fontWeight="regular">
            {loading ? (
              <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Skeleton variant="text" width={100} sx={{ fontSize: "0.5rem" }} />
              </SoftBox>
            ) : (
              usuario?.nroLegajo ? `Legajo Nº: ${usuario?.nroLegajo}` : "Sin legajo"
            )}
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" alignItems="center" justifyContent="center" py={1}>
          <SoftBox display="flex" alignItems="center" mr={1}>
            <Icon color="primary">badge</Icon>
          </SoftBox>
          <SoftTypography variant="h7" color="text" fontWeight="regular">
            {loading ? (
              <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Skeleton variant="text" width={100} sx={{ fontSize: "0.5rem" }} />
              </SoftBox>
            ) : (
              `DNI: ${usuario?.dni}`
            )}
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" alignItems="center" justifyContent="center" py={1}>
            <SoftButton color="uproYellow" fullWidth circular>
              <Icon>edit</Icon>
              &nbsp;
              Editar Perfil
              </SoftButton>
</SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of ProfileCard
ProfileCard.defaultProps = {
  color: "primary",
  usuario: {
    sucursal: {},
    puesto: {},
    user: {},
    localidad: { provincia: {} },
    sucursales: [{}],
  },
  loading: true,
};

// Typechecking props for the ProfileCard
ProfileCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "terraPrimary",
    "terraSecondary",
  ]),
  usuario: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

export default ProfileCard;
