// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Icon, Tooltip } from "@mui/material";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { API_URL } from "services/config";

function DocumentacionItem({ name, description, value, onDelete, readOnly }) {
  return (
    <SoftBox key={name} component="li" py={1} mb={1}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center">
        <SoftBox display="flex" alignItems="center">
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium" gutterBottom>
              {name}
            </SoftTypography>
            <SoftTypography variant="caption" color="text">
              {description}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" justifyContent="center" alignItems="center">
          {(!readOnly) && (
            <SoftBox mr={1}>
              <Tooltip title="Eliminar" placement="top">
                <SoftButton
                  variant="outlined"
                  color="error"
                  size="small"
                  iconOnly
                  circular
                  onClick={onDelete}
                >
                  <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                </SoftButton>
              </Tooltip>
            </SoftBox>
          )}
          <SoftBox>
            <Tooltip title="Descargar" placement="top">
              <SoftButton
                variant="outlined"
                color="info"
                size="small"
                iconOnly
                circular
                onClick={async () => {
                  const file = await fetch(`${API_URL}/${value}`);
                  const blob = await file.blob();
                  const url = URL.createObjectURL(blob);

                  const a = document.createElement("a");
                  a.href = url;
                  a.download = name;
                  a.click();
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>file_download</Icon>
              </SoftButton>
            </Tooltip>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

DocumentacionItem.defaultProps = {
  readOnly: false,
},

// Typechecking props of the DocumentacionItem
DocumentacionItem.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default DocumentacionItem;
