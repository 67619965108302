import { useContext, useEffect, useState } from "react";

// @mui material components
import { CircularProgress, Divider, Grid, Icon, InputLabel, MenuItem, Select } from "@mui/material";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import ModalDocumentacion from "layouts/soporte/components/ModalDocumentacion";
import { addDocumento, addIncidente, getModulos } from "layouts/soporte/querys";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { UserContext } from "context/user";

// Custom styles for the Configurator
import ConfiguratorRoot from "./ConfiguratorRoot";

// Kaizen Dashboard context
import { setOpenConfigurator, useSoftUIController } from "context";

import { gql } from "@apollo/client";

const INSERT_ERROR = gql`
  mutation addErrorLog($errorLogInput: ErrorLogInput!) {
    addErrorLog(input: $errorLogInput) {
      id
    }
  }
`;

function Configurator() {
  const [controller, dispatch] = useSoftUIController();
  const { openConfigurator } = controller;
  const { user } = useContext(UserContext);

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

  useEffect(() => {
    if (window.location.pathname === "/soporte") {
      handleCloseConfigurator();
    }
  }, [window.location.pathname]);

  const [data, setData] = useState({
    idProyecto: "24",
    idSolicitante: 15,
    tarea: "",
    descripcionTarea: "",
    idPantalla: -1,
    prioridadSolicitanteUser: "Baja",
    estadoTarea: "Incidente",
    estadoTicketUser: "Nuevo",
    idUsuarioInterno: user?.id,
    nombreUsuarioInterno: user?.nombre + " " + user?.apellido,
    emailInterno: user?.email,
    fechaSprint: dayjs().format("YYYY-MM-DD"),
  });
  const [loading, setLoading] = useState(false);
  const [modulos, setModulos] = useState([]);
  const [documentacion, setDocumentacion] = useState([]);
  const { handleSnackbar } = useContext(MessageManager);
  const [openDocumentacion, setOpenDocumentacion] = useState(false);

  const handleOpenDocumentacion = () => {
    setOpenDocumentacion(true);
  };

  const handleCloseDocumentacion = () => {
    setOpenDocumentacion(false);
  };

  const handleChanges = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const closeAndClean = () => {
    handleCloseConfigurator();
    setData({
      idProyecto: "24",
      idSolicitante: 15,
      tarea: "",
      descripcionTarea: "",
      idPantalla: -1,
      prioridadSolicitanteUser: "Baja",
      estadoTarea: "Incidente",
      estadoTicketUser: "Nuevo",
      idUsuarioInterno: user?.id,
      nombreUsuarioInterno: user?.empleado?.nombre + " " + user?.empleado?.apellido,
      emailInterno: user?.empleado?.emailInterno,
    });
    setDocumentacion([]);
  };

  const handleAddIncidente = async () => {
    setLoading(true);
    await addIncidente(data)
      .then((response) => {
        if (documentacion.length > 0) {
          documentacion.forEach((doc) => {
            addDocumento(doc, response.tarea.id);
          });
        }

        handleSnackbar(response.mensaje, response.estado ? "success" : "error");
        setLoading(false);
        closeAndClean();
      })
      .catch((error) => {
        handleSnackbar("Error al agregar el incidente, intente nuevamente más tarde", "error");
        setLoading(false);
      });
  };

  useEffect(() => {
    getModulos().then((response) => {
      setModulos(response);
    });
  }, []);

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SoftBox>
          <SoftTypography variant="h5">Reportar error</SoftTypography>
          <SoftTypography variant="body2" color="text">
            Será revisado por el equipo de desarrollo
          </SoftTypography>
        </SoftBox>

        <Icon
          sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
            fontSize: `${size.md} !important`,
            fontWeight: `${fontWeightBold} !important`,
            stroke: dark.main,
            strokeWidth: "2px",
            cursor: "pointer",
            mt: 2,
          })}
          onClick={closeAndClean}
        >
          close
        </Icon>
      </SoftBox>

      <Divider />

      <SoftBox pt={1.25} px={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel htmlFor="nombre">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Nombre del incidente
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="nombre"
              placeholder="Ingrese el nombre del incidente"
              type="text"
              name="tarea"
              error={data.tarea.length > 250}
              value={data.tarea}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="modulo">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Módulo
              </SoftTypography>
            </InputLabel>
            <Select
              label="modulo"
              name="idPantalla"
              sx={{ cursor: "pointer" }}
              value={data.idPantalla}
              onChange={handleChanges}
            >
              <MenuItem value={-1} disabled>
                Seleccione un módulo
              </MenuItem>
              {modulos.map((modulo) => (
                <MenuItem key={modulo.id} value={modulo.id}>
                  {modulo.nombre}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="prioridad">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Prioridad
              </SoftTypography>
            </InputLabel>
            <Select
              label="prioridad"
              name="prioridadSolicitanteUser"
              sx={{ cursor: "pointer" }}
              value={data.prioridadSolicitanteUser}
              onChange={handleChanges}
            >
              <MenuItem value={"Baja"}>Baja</MenuItem>
              <MenuItem value={"Media"}>Media</MenuItem>
              <MenuItem value={"Alta"}>Alta</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="descripcion">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Descripción
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="descripcion"
              placeholder="Describa el incidente..."
              type="text"
              name="descripcionTarea"
              value={data.descripcionTarea}
              onChange={handleChanges}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <SoftBox>
              <SoftButton
                variant="gradient"
                color="primary"
                onClick={handleOpenDocumentacion}
                fullWidth
              >
                <Icon>link</Icon>&nbsp;
                {documentacion?.length > 0 && documentacion.length < 2
                  ? `${documentacion.length} Documento`
                  : documentacion.length > 1
                  ? `${documentacion.length} Documentos`
                  : "Documentación"}
              </SoftButton>
            </SoftBox>
          </Grid>
          <Grid item xs={12}>
            <SoftButton
              variant="gradient"
              color="dark"
              onClick={handleAddIncidente}
              disabled={
                !data.tarea ||
                !data.idPantalla ||
                data.idPantalla === -1 ||
                !data.prioridadSolicitanteUser ||
                !data.descripcionTarea ||
                data.tarea.length > 250
              }
              fullWidth
            >
              {loading ? <CircularProgress size={17} color="inherit" /> : "Agregar"}
            </SoftButton>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Link to="/soporte">
              <SoftButton variant="gradient" color="primary" fullWidth>
                Ver todos mis reportes
                <Icon>arrow_forward</Icon>
              </SoftButton>
            </Link>
          </Grid>
        </Grid>
      </SoftBox>
      <ModalDocumentacion
        open={openDocumentacion}
        handleClose={handleCloseDocumentacion}
        item={documentacion}
        setItem={setDocumentacion}
      />
    </ConfiguratorRoot>
  );
}

export default Configurator;
