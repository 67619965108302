// @mui material components
import { Card, Collapse, Fade, Grid, Icon, Modal, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { useContext, useEffect, useRef, useState } from "react";
import { API_URL } from "services/config";
import { formatDate } from "utils/formatters";
import DocumentacionItem from "../DocumentacionItem";
import Loading from "components/Carteles/Loading";
import ModalDocumentacionAdd from "../ModalDocumentacionAdd";
import SoftBadge from "components/SoftBadge";
import { getUproColor } from "utils/colors";
import dayjs from "dayjs";
import { MenuContext } from "context/menuContext";
import ModalDelete from "components/Modals/Delete";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "90%", xxl: "40%" },
  overflow: "auto",
};

export default function ModalDocumentacionCarrera({ open, handleClose, idCarrera, readOnly }) {
  const [files, setFiles] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const { handleSnackbar } = useContext(MessageManager);
  const [expanded, setExpanded] = useState(["Documentación del Alumno"]);
  const { menu } = useContext(MenuContext);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [selectedDocumento, setSelectedDocumento] = useState(null);

  const [getCarrera, { data, loading, refetch }] = useLazyQuery(gql`
    query getCarrera($id: ID!) {
      carrera(id: $id) {
        nombre
        documentos {
          id
          nombre
          file
          createdAt
        }
      }
    }
  `);

  const [deleteCarreraFile, { loading: loadingDelete }] = useMutation(gql`
    mutation deleteCarreraFile($id: ID!) {
      deleteCarreraFile(id: $id) {
        id
      }
    }
  `);

  useEffect(() => {
    if (idCarrera && open) {
      getCarrera({ variables: { id: idCarrera } });
    }
  }, [idCarrera, open]);

  useEffect(() => {
    if (data) {
      setFiles(data.carrera.documentos);
    }
  }, [data]);

  const handleDownloadAll = async () => {
    const zip = new JSZip();

    for (let file of files) {
      const res = await fetch(`${API_URL}/${file.file}`);
      let filename = file.nombre + " - " + dayjs(file.createdAt).format("DD-MM-YYYY") + ".pdf";
      const blob = await res.blob();
      zip.file(filename, blob);
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `Documentación - ${data.carrera.nombre} - ${dayjs().format("DD-MM-YYYY")}.zip`);
    });
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={0}>
              <SoftTypography variant="h6">Documentación</SoftTypography>
              <SoftBox display="flex" justifyContent="end" alignItems="center">
                <SoftBox display="flex" justifyContent="end" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox p={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: !expanded.includes("Documentación del Alumno")
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon color="primary">description</Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          {`Documentación de la carera ${data?.carrera?.nombre}`}
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                        <SoftBox mr={1}>
                          <Tooltip placement="top" title="Descargar todos">
                            <SoftButton color="primary" iconOnly circular onClick={handleDownloadAll} disabled={
                              files.length === 0
                            }>
                              <Icon sx={{ fontWeight: "regular" }}>download</Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                        <SoftBox>
                          <Tooltip placement="top" title="Agregar nuevo">
                            <SoftButton
                              color="uproGreen"
                              iconOnly
                              circular
                              onClick={() => {
                                setOpenModalAdd(true);
                              }}
                            >
                              <Icon sx={{ fontWeight: "regular" }}>add</Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={expanded.includes("Documentación del Alumno")}>
                      {loading ? (
                        <Loading />
                      ) : data?.carrera?.documentos?.length > 0 ? (
                        data?.carrera?.documentos?.map((documento, index) => (
                          <SoftBox key={documento.id}>
                            <SoftBox
                              px={2}
                              py={1}
                              sx={{
                                borderBottom:
                                  data?.carrera?.documentos?.length - 1 === index
                                    ? "none"
                                    : "1px solid rgba(0, 0, 0, 0.12)",
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                                },
                              }}
                            >
                              <SoftBox display="flex" justifyContent="space-between">
                                <SoftBox display="flex" alignItems="center" pl={2}>
                                  <SoftBox mr={1}>
                                    <Icon
                                      sx={{
                                        color: getUproColor("uproPink"),
                                      }}
                                    >
                                      subdirectory_arrow_right
                                    </Icon>
                                  </SoftBox>
                                  <SoftBox mr={1}>
                                    <Tooltip title={documento?.nombre} placement="top">
                                      <Icon
                                        sx={{
                                          color: getUproColor("uproYellow"),
                                        }}
                                      >
                                        file_present
                                      </Icon>
                                    </Tooltip>
                                  </SoftBox>
                                  <SoftTypography variant="h6">{documento.nombre}</SoftTypography>
                                </SoftBox>
                                <SoftBox
                                  display="flex"
                                  justifyContent="flex-end"
                                  alignItems="center"
                                >
                                  <SoftBox>
                                    <Tooltip title={"Opciones"} placement="top">
                                      <SoftButton
                                        color="uproYellow"
                                        circular
                                        iconOnly
                                        onClick={(event) => {
                                          menu({
                                            open: event.currentTarget,
                                            align: "right",
                                            options: [
                                              {
                                                name: "Descargar",
                                                icon: {
                                                  icon: "file_download",
                                                  color: "info",
                                                },
                                                onClick: async () => {
                                                  const file = await fetch(
                                                    `${API_URL}/${documento.file}`
                                                  );
                                                  const blob = await file.blob();
                                                  saveAs(blob, documento.nombre);
                                                },
                                              },

                                              {
                                                name: "Eliminar",
                                                icon: {
                                                  icon: "delete",
                                                  color: "error",
                                                },
                                                onClick: () => {
                                                  setSelectedDocumento(documento);
                                                  setOpenModalDelete(true);
                                                },
                                              },
                                            ],
                                            handleClose: () => {},
                                          });
                                        }}
                                      >
                                        <Icon>more_vert</Icon>
                                      </SoftButton>
                                    </Tooltip>
                                  </SoftBox>
                                </SoftBox>
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>
                        ))
                      ) : (
                        <Grid container spacing={2} p={2}>
                          <SinDatos />
                        </Grid>
                      )}
                    </Collapse>
                  </Card>
                </Grid>
              </Grid>
            </SoftBox>
            <ModalDocumentacionAdd
              open={openModalAdd}
              handleClose={() => setOpenModalAdd(false)}
              idCarrera={idCarrera}
              refetch={refetch}
            />
            <ModalDelete
              open={openModalDelete}
              handleClose={() => setOpenModalDelete(false)}
              nombre={`el documento ${selectedDocumento?.nombre}`}
              functionDelete={() => {
                deleteCarreraFile({
                  variables: { id: selectedDocumento.id },
                })
                  .then(() => {
                    refetch();
                    handleSnackbar("Documento eliminado correctamente", "success");
                  })
                  .catch((error) => {
                    handleSnackbar("Ha ocurrido un error al eliminar el archivo", "error");
                  });
                setSelectedDocumento(null);
                setOpenModalDelete(false);
              }}
            />
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

ModalDocumentacionCarrera.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  idCarrera: PropTypes.string,
  readOnly: PropTypes.bool,
};
