// @mui material components
import { useState, useEffect, useContext } from "react";
import { Modal, Fade, Card, Select, InputLabel, Grid, Icon } from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import ModalDelete from "components/Modals/Delete";
import dayjs from "dayjs";
import { validateText } from "utils/validations";
import { gql, useMutation } from "@apollo/client";
import { MessageManager } from "context";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "60%", xxl: "50%" },
  overflow: "auto",
  py: 4,
};

const SAVE_RECORDATORIO = gql`
  mutation saveRecordatorio($recordatorio: RecordatorioInput!) {
    saveRecordatorio(input: $recordatorio) {
      id
      titulo
    }
  }
`;

const DELETE_RECORDATORIO = gql`
  mutation deleteRecordatorio($id: ID!) {
    deleteRecordatorio(id: $id) {
      id
    }
  }
`;

export default function ModalRecordatorios({
  open,
  handleClose,
  recordatorio,
  setRecordatorio,
  idUser,
}) {
  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const minDate = dayjs().format("YYYY-MM-DD");
  const [thisRecordatorio, setThisRecordatorio] = useState({ ...recordatorio });
  const [saveRecordatorio] = useMutation(SAVE_RECORDATORIO);
  const [deleteRecordatorio] = useMutation(DELETE_RECORDATORIO);
  const { handleSnackbar } = useContext(MessageManager);

  useEffect(() => {
    setThisRecordatorio({ ...recordatorio });
  }, [recordatorio]);

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setThisRecordatorio({ ...thisRecordatorio, [name]: value });
  };

  const handleSave = async () => {
    const { data } = await saveRecordatorio({
      variables: {
        recordatorio: {
          id: thisRecordatorio.id,
          titulo: thisRecordatorio.titulo,
          descripcion: thisRecordatorio.descripcion,
          fechaVencimiento: thisRecordatorio.fechaVencimiento,
          idUser: idUser,
        },
      },
    });
    handleSnackbar(
      thisRecordatorio.id
        ? "Se guardó con éxito el recordatorio"
        : "Se agregó con éxito el recordatorio",
      "success"
    );
    setRecordatorio({ ...thisRecordatorio, id: data.saveRecordatorio.id });
    handleClose();
  };

  const handleDelete = async () => {
    await deleteRecordatorio({
      variables: {
        id: thisRecordatorio.id,
      },
    });
    handleSnackbar("Se eliminó con éxito el recordatorio", "success");
    setRecordatorio({});
    handleClose();
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" px={3}>
              <SoftTypography variant="h6">Recordatorio</SoftTypography>
              {recordatorio?.id && (
                <SoftButton variant="gradient" color="error" onClick={handleOpenDelete}>
                  Eliminar&nbsp;
                  <Icon>delete</Icon>
                </SoftButton>
              )}
            </SoftBox>
            <SoftBox px={3} py={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <InputLabel htmlFor="Título">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Título
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="Título"
                    placeholder="Ingrese el título del recordatorio"
                    type="text"
                    value={thisRecordatorio?.titulo}
                    name="titulo"
                    onChange={handleChanges}
                    error={
                      thisRecordatorio?.titulo?.length > 0 &&
                      !validateText(thisRecordatorio?.titulo)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel htmlFor="fechaVencimiento">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Vencimiento
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="Vencimiento"
                    type="date"
                    value={
                      thisRecordatorio?.fechaVencimiento
                        ? thisRecordatorio?.fechaVencimiento
                        : null
                    }
                    name="fechaVencimiento"
                    onChange={handleChanges}
                    inputProps={{
                      min: minDate,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="descripcion">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Descripción
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="Descripción"
                    type="text"
                    placeholder="Ingrese la descripción del recordatorio"
                    multiline
                    rows={4}
                    value={thisRecordatorio?.descripcion}
                    name="descripcion"
                    onChange={handleChanges}
                  />
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox display="flex" justifyContent="flex-end" alignItems="center" px={3}>
              <SoftBox mr={2}>
                <SoftButton variant="gradient" color="dark" onClick={handleClose}>
                  Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox>
                <SoftButton
                  variant="gradient"
                  color="primary"
                  disabled={
                    !thisRecordatorio?.titulo ||
                    !thisRecordatorio?.descripcion ||
                    !thisRecordatorio?.fechaVencimiento ||
                    !validateText(thisRecordatorio?.titulo)
                  }
                  onClick={handleSave}
                >
                  Guardar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </Card>
        </Fade>
      </Modal>
      <ModalDelete
        open={openDelete}
        handleClose={handleCloseDelete}
        nombre={recordatorio?.titulo}
        functionDelete={handleDelete}
      />
    </>
  );
}

ModalRecordatorios.defaultProps = {
  recordatorio: {
    titulo: "",
    descripcion: "",
    fechaVencimiento: "",
  },
};

ModalRecordatorios.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  recordatorio: PropTypes.object,
  setRecordatorio: PropTypes.func.isRequired,
  idUser: PropTypes.string.isRequired,
};
