import { useState, useEffect } from "react";
import { Modal, Fade, Backdrop, Card, Grid, List, ListItem, Icon } from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import { getVersion } from "services/config";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "70%", md: "30%" },
  p: 3,
};

export default function ModalVersion({ open, handleClose }) {
  const [version, setVersion] = useState();
  useEffect(() => {
    getVersion().then((response) => {
      setVersion(response);
    });
  }, []);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <Grid container justifyContent="space-between" alignItems="center" pb={1} pt={2}>
              <SoftTypography variant="h6">Novedades de la Versión&nbsp;</SoftTypography>
              <SoftTypography variant="h6" fontWeight="light">
                {version?.version}
              </SoftTypography>
            </Grid>
          </SoftBox>
          <SoftBox py={2}>
            <List>
              {version?.tareas?.map((tarea, index) => (
                <ListItem key={index}>
                  <Icon mr={2}>chevron_right</Icon>
                  <SoftTypography variant="caption">
                    {tarea.pantalla} - {tarea.tarea}
                  </SoftTypography>
                </ListItem>
              ))}
            </List>
          </SoftBox>
          <SoftBox>
            <Grid item xs={12}>
              <SoftTypography variant="caption" fontWeight={"medium"}>
                Recomendamos cerrar sesión y volver a ingresar para ver los cambios.
              </SoftTypography>
            </Grid>
          </SoftBox>
          <SoftBox display="flex" justifyContent="flex-end" alignItems="center" pt={3}>
            <SoftBox>
              <SoftButton
                variant="gradient"
                color="dark"
                onClick={handleClose}
                circular
                fullWidth={{
                  xs: true,
                  sm: false,
                }}
              >
                Aceptar
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalVersion.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
