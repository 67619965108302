import { gql, useMutation } from "@apollo/client";
import { Card, CircularProgress, Fade, Grid, Icon, Modal, Skeleton, Tooltip } from "@mui/material";
import cover from "assets/images/cover.png";
import logo from "assets/images/logo.png";
import logo_alt from "assets/images/logo_alt.png";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import { sha512 } from "js-sha512";
import jsPDF from "jspdf";
import PropTypes from "prop-types";
import { useContext, useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflowY: "auto",
};

export default function ModalCertificado({ open, handleClose, usuario, carrera, idUser }) {
  const { handleSnackbar } = useContext(MessageManager);
  const [addCertificado, { loading: loadingAdd, data: dataCertificado }] = useMutation(gql`
    mutation addCertificadoPublic($input: CertificadoInput!, $token: String!) {
      addCertificadoPublic(input: $input, token: $token) {
        id
      }
    }
  `);

  const [updateCertificadoPublic] = useMutation(gql`
    mutation updateCertificadoPublic($file: Upload!, $id: ID!, $token: String!) {
      updateCertificadoPublic(file: $file, id: $id, token: $token) {
        id
      }
    }
  `);

  const generatePDF = () => {
    if (dataCertificado?.addCertificadoPublic?.id) {
      const element = document.getElementById("certificado");

      if (element) {
        html2canvas(element, { scale: 2 }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");

          const img = document.createElement("img");
          img.src = imgData;
          document.body.appendChild(img);

          const report = new jsPDF({
            orientation: "portrait",
            compress: true,
          });

          const pdfWidth = report.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
          report.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
          report.save(
            `${dataCertificado?.addCertificadoPublic?.id} - Certificado de Preinscripción - ${usuario?.dni}.pdf`
          );

          img.remove();
        });
      }
      return;
    }

    addCertificado({
      variables: {
        input: {
          idUser: idUser,
          idCarrera: carrera.id,
          tipo: "Certificado de Preinscripción",
        },
        token: `${sha512(
          idUser +
            new Date().getFullYear() +
            new Date().getMonth() +
            new Date().getDate() +
            new Date().getHours()
        )}`,
      },
    })
      .then((data) => {
        const element = document.getElementById("certificado");
        setTimeout(() => {
          if (element) {
            html2canvas(element, { scale: 2 }).then((canvas) => {
              const imgData = canvas.toDataURL("image/png");

              // Debugging: Show the captured image in an <img> tag
              const img = document.createElement("img");
              img.src = imgData;
              document.body.appendChild(img);

              const report = new jsPDF({
                orientation: "portrait",
                compress: true,
              });

              const pdfWidth = report.internal.pageSize.getWidth();
              const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
              report.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
              report.save(
                `${data.data.addCertificadoPublic.id} - Certificado de Preinscripción - ${usuario?.dni}.pdf`
              );
              const idString = `${data.data.addCertificadoPublic.id}`;
              updateCertificadoPublic({
                variables: {
                  file: new File(
                    [report.output("blob")],
                    `${data.data.addCertificadoPublic.id} - Certificado de Preinscripción - ${usuario?.dni}.pdf`
                  ),
                  id: data.data.addCertificadoPublic.id,
                  token: `${sha512(
                    idString +
                      new Date().getFullYear() +
                      new Date().getMonth() +
                      new Date().getDate() +
                      new Date().getHours()
                  )}`,
                },
              })
                .then(() => {
                  console.log("Certificado actualizado");
                })
                .catch((e) => {
                  console.log(e);
                });

              //remove the image from the body
              img.remove();
            });
          }
        }, 500);
      })
      .catch((e) => {
        handleSnackbar(e.message || "Ha ocurrido un error generando el certificado", "error");
      });
  };

  useEffect(() => {
    if (open && carrera && usuario) {
      setTimeout(() => {
        generatePDF();
      }, 500);
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" p={3} pb={0}>
              <SoftBox>
                <SoftTypography variant="h6">Certificado generado</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox display="flex" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon
                      fontSize="medium"
                      onClick={() => {
                        handleClose();
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox id="certificado" p={3}>
              <SoftBox position="relative">
                <SoftBox
                  display="flex"
                  alignItems="top"
                  position="relative"
                  minHeight="18vh"
                  borderRadius="xl"
                  sx={{
                    backgroundImage: ({
                      functions: { rgba, linearGradient },
                      palette: { gradients },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.primary.main, 0.6),
                        rgba(gradients.primary.state, 0.6)
                      )}, url(${cover})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                  }}
                >
                  <Grid container justifyContent="center" p={4} mb={4} alignItems={"center"}>
                    <Grid item xs={4}>
                      <SoftBox component="img" src={logo_alt} alt="UPrO Logo" width="100%" p={3} />
                    </Grid>
                  </Grid>
                </SoftBox>
                <Card
                  sx={{
                    backdropFilter: `saturate(200%) blur(30px)`,
                    backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                      rgba(white.main, 1),
                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                    position: "relative",
                    mt: -8,
                    mx: 2,
                    py: 2,
                    px: 2,
                  }}
                >
                  <SoftBox display="flex" justifyContent="space-between">
                    <SoftBox>
                      <SoftTypography variant="h6" fontWeight="light">
                        Número de certificado
                      </SoftTypography>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {dataCertificado?.addCertificadoPublic?.id || (
                          <Skeleton variant="text" width={100} />
                        )}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox textAlign="right">
                      <SoftTypography variant="h6" fontWeight="light">
                        Fecha de emisión
                      </SoftTypography>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {dayjs().format("DD/MM/YYYY HH:mm")}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12}>
                      <SoftTypography variant="h5" fontWeight="bold" textAlign="center">
                        Certificado de Preinscripción
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <SoftTypography variant="h6" fontWeight="light">
                        El presente certificado acredita que el alumno{" "}
                        <span
                          style={{ fontWeight: "bold" }}
                        >{`${usuario?.nombre} ${usuario?.apellido}`}</span>
                        , DNI <span style={{ fontWeight: "bold" }}>{usuario?.dni}</span> ha
                        realizado la preinscripción a la carrera{" "}
                        <span
                          style={{ fontWeight: "bold" }}
                        >{`${carrera?.codigo} - ${carrera?.nombre}`}</span>{" "}
                        en la Universidad Provincial de Oficios.
                      </SoftTypography>
                    </Grid>
                  </Grid>
                  <SoftBox display="flex" justifyContent="space-between" mt={3} alignItems="end">
                    <SoftBox component="img" src={logo} alt="UPrO Logo" width="90px" />
                    {/* <SoftBox component="img" src={firma} alt="UPrO Logo" width="120px" /> */}
                  </SoftBox>
                  <SoftBox display="flex" justifyContent="center" mt={3} alignItems="center">
                    <SoftBox textAlign="center">
                      <SoftTypography variant="caption" fontWeight="light">
                        Powered by&nbsp;
                      </SoftTypography>
                      <SoftTypography variant="caption" fontWeight="bold">
                        UPrO
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
            </SoftBox>
            <SoftBox p={3} pt={0}>
              <SoftButton
                color="primary"
                onClick={generatePDF}
                circular
                fullWidth
                disabled={loadingAdd}
              >
                {loadingAdd ? (
                  <CircularProgress
                    size={15}
                    sx={{
                      marginRight: 0.5,
                    }}
                    color="white"
                  />
                ) : (
                  <Icon>download</Icon>
                )}
                &nbsp;Descargar
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalCertificado.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  usuario: PropTypes.object,
  carrera: PropTypes.object,
  idUser: PropTypes.string,
};
