import { useState, useContext } from "react";
import PropTypes from "prop-types";

// @mui material components
import { Card, Grid, Icon, Menu, MenuItem, Modal, Fade } from "@mui/material";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import ModalAddDocumentacion from "../ModalAddDocumentacion";
import DocumentacionItem from "../DocumentacionItem";
import dayjs from "dayjs";
import { deleteDocumento, addDocumento } from "layouts/soporte/querys";
import { MessageManager } from "context";
import { API_URL } from "services/config";
import SoftButton from "components/SoftButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "25%" },
  overflow: "auto",
  p: 3,
};

function ModalDocumentacion({ item, setItem, open, handleClose, incidente, refetch }) {
  const [menu, setMenu] = useState(null);
  const { handleSnackbar } = useContext(MessageManager);

  const [openDocumentacion, setOpenDocumentacion] = useState(false);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const handleOpenDocumentacion = () => {
    closeMenu();
    setOpenDocumentacion(true);
  };

  const handleCloseDocumentacion = () => {
    setOpenDocumentacion(false);
  };

  const handleDelete = (index) => {
    let doc = item[index];

    if (doc.id) {
      deleteDocumento(doc.id).then((response) => {
        handleSnackbar(response.mensaje, response.estado ? "success" : "error");
        refetch();
      });
    } else {
      let newDocumentacion = item || [];
      newDocumentacion.splice(index, 1);
      setItem(newDocumentacion);
    }
  };

  const handleAddDocumentos = (docs) => {
    docs.forEach((doc) => {
      addDocumento(doc, incidente.id);
    });

    handleSnackbar("Documentos agregados correctamente", "success");
    handleClose();
  };

  const downloadAll = () => {
    closeMenu();
    item?.forEach((doc) => {
      if (doc.path) {
        window.open("https://tickets.esamba.online/" + doc.path, "_blank");
      } else {
        const blob = new Blob([doc.file], { type: doc.file.type });
        const elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = doc.nombre;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    });
  };

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={handleOpenDocumentacion}>
        <Icon sx={{ mr: 1 }}>add</Icon>
        Agregar nueva
      </MenuItem>
      <MenuItem onClick={downloadAll} disabled={item?.length === 0}>
        <Icon sx={{ mr: 1 }}>file_download</Icon>
        Descargar todas
      </MenuItem>
    </Menu>
  );

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style} id="obras-10">
          <SoftBox display="flex" justifyContent="space-between" alignItems="center">
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Documentación
            </SoftTypography>
            <SoftBox display="flex" alignItems="flex-start" sx={{ cursor: "pointer" }}>
              <SoftBox color="text" mr={0.5} lineHeight={0}>
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  fontSize="small"
                  onClick={openMenu}
                >
                  more_vert
                </Icon>
              </SoftBox>
              {renderMenu}
            </SoftBox>
          </SoftBox>
          <SoftBox>
            {item?.length > 0 ? (
              <SoftBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={0}
                m={0}
                sx={{ listStyle: "none" }}
              >
                {item?.map((doc, index) => (
                  <DocumentacionItem
                    key={index}
                    name={doc.nombre || doc.nombreArchivo}
                    description={
                      doc.fecha
                        ? doc.fecha
                        : doc.createdAt
                        ? dayjs(doc.createdAt).format("DD/MM/YYYY HH:MM")
                        : doc.updated_at
                        ? dayjs(doc.updated_at).format("DD/MM/YYYY HH:MM")
                        : ""
                    }
                    url={doc.path ? "https://tickets.esamba.online/" + doc.path : null}
                    file={doc.file}
                    handleDelete={() => handleDelete(index)}
                  />
                ))}
              </SoftBox>
            ) : (
              <Grid container textAlign={"center"} py={3}>
                <Grid item xs={12}>
                  <Icon
                    sx={{
                      width: "50px",
                    }}
                  >
                    file_present
                  </Icon>
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Sin documentos</SoftTypography>
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="caption" component="p" color="text">
                    No hay documentación para este proyecto
                  </SoftTypography>
                </Grid>
              </Grid>
            )}
          </SoftBox>
          <SoftBox display="flex" justifyContent="end" alignItems="center">
            <SoftBox mr={incidente && 2}>
              <SoftButton variant="gradient" color="primary" onClick={handleClose}>
                Cerrar
              </SoftButton>
            </SoftBox>
            {incidente && (
              <SoftBox>
                <SoftButton
                  variant="gradient"
                  color="dark"
                  onClick={() => handleAddDocumentos(item.filter((doc) => !doc.id))}
                  disabled={item.filter((doc) => !doc.id).length === 0}
                >
                  Guardar
                </SoftButton>
              </SoftBox>
            )}
          </SoftBox>
          <ModalAddDocumentacion
            open={openDocumentacion}
            handleClose={handleCloseDocumentacion}
            item={item}
            setItem={setItem}
          />
        </Card>
      </Fade>
    </Modal>
  );
}

ModalDocumentacion.defaultProps = {
  item: [],
  setItem: () => {
    return null;
  },
};

ModalDocumentacion.propTypes = {
  item: PropTypes.array,
  setItem: PropTypes.func,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  incidente: PropTypes.object,
  refetch: PropTypes.func,
};

export default ModalDocumentacion;
