import { gql } from "@apollo/client";

export const login = gql`
  mutation login($userInput: UserInput!) {
    login(input: $userInput) {
      token
      user {
        id
        username
        nombre
        tipoUser
        tempPassword
        permisos{
          id
          nombre
          pantalla
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser {
    currentUser {
      id
      tipoUser
      username
      nombre
      apellido
      email
      permisos{
        id
        nombre
        pantalla
      }
    }
  }
`;

export const logout = () => {
  localStorage.removeItem("user");
  sessionStorage.removeItem("user");
  window.location.href = "/login";
};
