// Kaizen Dashboard layouts
import ErrorHandler from "components/ErrorHandler";
import SignIn from "layouts/authentication/sign-in";
import GestorPlanosAdd from "layouts/gestor-planos/add";
import Planos from "layouts/planos";
import Soporte from "layouts/soporte";
import Usuarios from "layouts/usuarios";

//PDFs

// Kaizen Dashboard icons
import { HistoryEdu, ManageAccounts, Person, School, SupervisorAccount } from "@mui/icons-material";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import Alumnos from "layouts/alumnos";
import Docentes from "layouts/docentes";
import AccesoAlumnos from "layouts/authentication/acceso-alumnos";
import Carreras from "layouts/carreras";
import Autogestion from "layouts/autogestion";
import Preinscripcion from "layouts/authentication/preinscripcion";

const routes = [
  {
    type: "collapse",
    name: "Soporte",
    key: "soporte",
    route: "/soporte",
    icon: <SupportAgentIcon />,
    component: <Soporte />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Usuarios",
    key: "usuarios",
    route: "/usuarios",
    icon: <ManageAccounts />,
    component: <Usuarios />,
    noCollapse: true,
    permiso: "Usuarios",
  },
  {
    type: "collapse",
    name: "Alumnos",
    key: "alumnos",
    route: "/alumnos",
    icon: <School />,
    component: <Alumnos />,
    noCollapse: true,
    permiso: "Alumnos",
  },
  {
    type: "collapse",
    name: "Docentes",
    key: "docentes",
    route: "/docentes",
    icon: <SupervisorAccount />,
    component: <Docentes />,
    noCollapse: true,
    permiso: "Docentes",
  },
  {
    type: "collapse",
    name: "Carreras",
    key: "carreras",
    route: "/carreras",
    icon: <HistoryEdu />,
    component: <Carreras />,
    noCollapse: true,
    permiso: "Carreras",
  },
  // {
  //   type: "collapse",
  //   name: "Planos",
  //   key: "planos",
  //   route: "/planos",
  //   icon: <MapOutlinedIcon />,
  //   component: <Planos />,
  //   noCollapse: true,
  //   permiso: "Planos",
  // },
  // {
  //   type: "collapse",
  //   name: "Agregar Plano",
  //   key: "agregar-plano",
  //   route: "/agregar-plano",
  //   icon: <AddLocationAltIcon />,
  //   component: <GestorPlanosAdd />,
  //   noCollapse: true,
  //   permiso: "AgregarPlanos",
  // },
  {
    type: "collapse",
    name: "Autogestión",
    key: "autogestion",
    route: "/autogestion",
    icon: <Person />,
    component: <Autogestion />,
    noCollapse: true,
    permiso: "Autogestion",
  },
  {
    route: "/error",
    component: <ErrorHandler />,
  },
  {
    route: "/login",
    component: <SignIn />,
  },
  // {
  //   route: "/acceso-alumnos",
  //   component: <AccesoAlumnos />,
  // },
  {
    route: "/preinscripcion",
    component: <Preinscripcion />,
  }
];

export default routes;
