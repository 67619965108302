// React

// Components
import { Card, Collapse, Grid, Icon, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import PropTypes from "prop-types";
import SoftTypography from "components/SoftTypography";

// Data
import ProfileCard from "components/Cards/ProfileCard";
import { useState } from "react";
import { getUproColor } from "utils/colors";
import SinDatos from "components/Carteles/SinDatos";

export default function Inscripciones({ usuario, loading }) {
  const [expanded, setExpanded] = useState(["Unidad de Competencia Inscriptas", "Examenes Inscriptos"]);
  return (
    <SoftBox>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <SoftBox
              display="flex"
              justifyContent="space-between"
              px={2}
              py={1}
              sx={{
                borderBottom: !expanded.includes("Unidad de Competencia Inscriptas")
                  ? "none"
                  : "1px solid rgba(0, 0, 0, 0.12)",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              onClick={(e) => {
                setExpanded(
                  expanded.includes("Unidad de Competencia Inscriptas")
                    ? expanded.filter((i) => i !== "Unidad de Competencia Inscriptas")
                    : [...expanded, "Unidad de Competencia Inscriptas"]
                );
              }}
            >
              <SoftBox display="flex" alignItems="center">
                <SoftBox mr={1} display="flex" alignItems="center">
                  <Icon color="primary">assignment_turned_in</Icon>
                </SoftBox>
                <SoftTypography variant="h6" fontWeight="bold">
                  Unidad de Competencia inscriptas
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox>
                  <Tooltip
                    title={expanded.includes("Unidad de Competencia Inscriptas") ? "Ocultar" : "Mostrar"}
                    placement="top"
                  >
                    <SoftButton
                      color="primary"
                      circular
                      iconOnly
                      onClick={() =>
                        setExpanded(
                          expanded.includes("Unidad de Competencia Inscriptas")
                            ? expanded.filter((i) => i !== "Unidad de Competencia Inscriptas")
                            : [...expanded, "Unidad de Competencia Inscriptas"]
                        )
                      }
                    >
                      <Icon
                        sx={{
                          rotate: expanded.includes("Unidad de Competencia Inscriptas") ? "180deg" : "0deg",
                          transition: "all 0.5s",
                        }}
                      >
                        expand_more
                      </Icon>
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <Collapse in={expanded.includes("Unidad de Competencia Inscriptas")}>
              <Grid container spacing={2} p={2}>
                <SinDatos />
              </Grid>
            </Collapse>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <SoftBox
              display="flex"
              justifyContent="space-between"
              px={2}
              py={1}
              sx={{
                borderBottom: !expanded.includes("Unidad de Competencia Disponibles")
                  ? "none"
                  : "1px solid rgba(0, 0, 0, 0.12)",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              onClick={(e) => {
                setExpanded(
                  expanded.includes("Unidad de Competencia Disponibles")
                    ? expanded.filter((i) => i !== "Unidad de Competencia Disponibles")
                    : [...expanded, "Unidad de Competencia Disponibles"]
                );
              }}
            >
              <SoftBox display="flex" alignItems="center">
                <SoftBox mr={1} display="flex" alignItems="center">
                  <Icon
                    sx={{
                      color: getUproColor("uproGreen"),
                    }}
                  >
                    assignment
                  </Icon>
                </SoftBox>
                <SoftTypography variant="h6" fontWeight="bold">
                  Unidad de Competencia disponibles
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox>
                  <Tooltip
                    title={expanded.includes("Unidad de Competencia Disponibles") ? "Ocultar" : "Mostrar"}
                    placement="top"
                  >
                    <SoftButton
                      color="primary"
                      circular
                      iconOnly
                      onClick={() =>
                        setExpanded(
                          expanded.includes("Unidad de Competencia Disponibles")
                            ? expanded.filter((i) => i !== "Unidad de Competencia Disponibles")
                            : [...expanded, "Unidad de Competencia Disponibles"]
                        )
                      }
                    >
                      <Icon
                        sx={{
                          rotate: expanded.includes("Unidad de Competencia Disponibles") ? "180deg" : "0deg",
                          transition: "all 0.5s",
                        }}
                      >
                        expand_more
                      </Icon>
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <Collapse in={expanded.includes("Unidad de Competencia Disponibles")}>
              <Grid container spacing={2} p={2}>
                <SinDatos />
              </Grid>
            </Collapse>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <SoftBox
              display="flex"
              justifyContent="space-between"
              px={2}
              py={1}
              sx={{
                borderBottom: !expanded.includes("Examenes Inscriptos")
                  ? "none"
                  : "1px solid rgba(0, 0, 0, 0.12)",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              onClick={(e) => {
                setExpanded(
                  expanded.includes("Examenes Inscriptos")
                    ? expanded.filter((i) => i !== "Examenes Inscriptos")
                    : [...expanded, "Examenes Inscriptos"]
                );
              }}
            >
              <SoftBox display="flex" alignItems="center">
                <SoftBox mr={1} display="flex" alignItems="center">
                  <Icon color="primary">task</Icon>
                </SoftBox>
                <SoftTypography variant="h6" fontWeight="bold">
                  Exámenes inscriptos
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox>
                  <Tooltip
                    title={expanded.includes("Examenes Inscriptos") ? "Ocultar" : "Mostrar"}
                    placement="top"
                  >
                    <SoftButton
                      color="primary"
                      circular
                      iconOnly
                      onClick={() =>
                        setExpanded(
                          expanded.includes("Examenes Inscriptos")
                            ? expanded.filter((i) => i !== "Examenes Inscriptos")
                            : [...expanded, "Examenes Inscriptos"]
                        )
                      }
                    >
                      <Icon
                        sx={{
                          rotate: expanded.includes("Examenes Inscriptos") ? "180deg" : "0deg",
                          transition: "all 0.5s",
                        }}
                      >
                        expand_more
                      </Icon>
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <Collapse in={expanded.includes("Examenes Inscriptos")}>
              <Grid container spacing={2} p={2}>
                <SinDatos />
              </Grid>
            </Collapse>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <SoftBox
              display="flex"
              justifyContent="space-between"
              px={2}
              py={1}
              sx={{
                borderBottom: !expanded.includes("Examenes Disponibles")
                  ? "none"
                  : "1px solid rgba(0, 0, 0, 0.12)",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              onClick={(e) => {
                setExpanded(
                  expanded.includes("Examenes Disponibles")
                    ? expanded.filter((i) => i !== "Examenes Disponibles")
                    : [...expanded, "Examenes Disponibles"]
                );
              }}
            >
              <SoftBox display="flex" alignItems="center">
                <SoftBox mr={1} display="flex" alignItems="center">
                  <Icon
                    sx={{
                      color: getUproColor("uproGreen"),
                    }}
                  >
                    note_add
                  </Icon>
                </SoftBox>
                <SoftTypography variant="h6" fontWeight="bold">
                  Exámenes disponibles
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox>
                  <Tooltip
                    title={expanded.includes("Examenes Disponibles") ? "Ocultar" : "Mostrar"}
                    placement="top"
                  >
                    <SoftButton
                      color="primary"
                      circular
                      iconOnly
                      onClick={() =>
                        setExpanded(
                          expanded.includes("Examenes Disponibles")
                            ? expanded.filter((i) => i !== "Examenes Disponibles")
                            : [...expanded, "Examenes Disponibles"]
                        )
                      }
                    >
                      <Icon
                        sx={{
                          rotate: expanded.includes("Examenes Disponibles") ? "180deg" : "0deg",
                          transition: "all 0.5s",
                        }}
                      >
                        expand_more
                      </Icon>
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <Collapse in={expanded.includes("Examenes Disponibles")}>
              <Grid container spacing={2} p={2}>
                <SinDatos />
              </Grid>
            </Collapse>
          </Card>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

Inscripciones.propTypes = {
  usuario: PropTypes.object,
  loading: PropTypes.bool,
};
