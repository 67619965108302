/* eslint-disable react/prop-types */

import { gql, useSubscription } from "@apollo/client";
import { createContext, useEffect, useState } from "react";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const { data: newLoginData } = useSubscription(
    gql`
      subscription newLogin {
        newLogin
      }
    `,
    {
      skip: !user,
    }
  );

  const handleUser = (user) => {
    if (user) {
      setUser(user);
    } else {
      setUser(null);
    }
  };

  const logout = () => {
    const tipoUser = user?.tipoUser;
    setUser(null);
    localStorage.removeItem("user");
    sessionStorage.removeItem("user");
    window.location.href = "/login";
  };

  const getToken = () => {
    if (localStorage.getItem("user")) {
      return JSON.parse(localStorage.getItem("user")).token;
    }
  };

  const verificarAcceso = (id, operation) => {
    if (user?.tipoUser === "Administrador") return true;
    if (Array.isArray(id)) {
      if (operation && operation === "or") {
        return id.some((item) => user?.permisos.find((permiso) => permiso.id == item));
      } else {
        if (id.every((item) => user?.permisos.find((permiso) => permiso.id == item))) {
          return true;
        }
      }
    } else {
      if (user?.permisos.find((item) => item.id == id)) {
        return true;
      }
    }
    return false;
  };

  const data = { user, handleUser, getToken, logout, verificarAcceso };

  useEffect(() => {
    if (newLoginData?.newLogin) {
      window.location.reload();
    }
  }, [newLoginData]);

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
