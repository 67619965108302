// React
import { useContext, useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";

// @mui material components
import { Card, Fade, Icon, Modal, Tooltip } from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { formatDate } from "utils/formatters";
import SoftInput from "components/SoftInput";
import { UserContext } from "context/user";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "80%", sm: "70%", xxl: "30%" },
  overflow: "auto",
  p: 2,
};

export default function ModalComentarios({
  open,
  handleClose,
  alumno,
  setCountComentarios,
  readOnly,
}) {
  const refMessageContainer = useRef();
  const { user } = useContext(UserContext);
  const [comentarios, setComentarios] = useState([]);
  const [mensaje, setMensaje] = useState("");
  const [getComentarios, { loading }] = useLazyQuery(
    gql`
      query getComentariosAlumno($idAlumno: ID!) {
        usuario(id: $idAlumno) {
          id
          comentarios {
            idUser
            emisor
            fecha
            comentario
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const [sendComentario, { loading: loadingSend }] = useMutation(
    gql`
      mutation addComentarioUser($input: InputUserComentario!) {
        addComentarioUser(input: $input) {
          idUser
          emisor
          fecha
          comentario
        }
      }
    `,
    {
      onCompleted: (data) => {
        setComentarios((prev) => [...prev, data.addComentarioUser]);
      },
    }
  );

  const scroll = () => {
    if (refMessageContainer.current) {
      refMessageContainer.current.scrollTop = refMessageContainer.current.scrollHeight;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      scroll();
    }, 100);
  }, [comentarios, loadingSend, open]);

  useEffect(() => {
    setCountComentarios(comentarios?.length || 0);
  }, [comentarios]);

  const handleSend = () => {
    if (readOnly || !mensaje || loading || loadingSend) return;
    let currentUser = user.nombre ? `${user.nombre} ${user.apellido}` : user.username;
    let newComentario = {
      emisor: currentUser,
      fecha: formatDate(new Date(), "yyyy-MM-dd hh:mm:ss"),
      comentario: mensaje,
    };
    sendComentario({ variables: { input: { idUser: alumno?.id, ...newComentario } } }).then(() => {
      getComentarios({ variables: { idAlumno: alumno?.id } }).then((res) => {
        setComentarios(res.data?.usuario?.comentarios || []);
      });
      setMensaje("");
    });
  };

  useEffect(() => {
    if (open && alumno) {
      getComentarios({ variables: { idAlumno: alumno?.id } }).then((res) => {
        setComentarios(res.data?.usuario?.comentarios || []);
      });
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox p={2}>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Observaciones</SoftTypography>
              </SoftBox>
              <SoftBox>
                <Tooltip title="Cerrar" placement="top">
                  <Icon sx={{ cursor: "pointer" }} onClick={handleClose} fontSize="medium">
                    close
                  </Icon>
                </Tooltip>
              </SoftBox>
            </SoftBox>
            <SoftBox
              ref={refMessageContainer}
              display="flex"
              flexDirection="column"
              gap={2}
              pr={2}
              sx={{ maxHeight: "40vh", overflowY: "scroll" }}
              onCompleted={scroll}
            >
              {comentarios.map((comentario, index) => (
                <SoftBox
                  key={index}
                  bgColor={comentario.idUser == user.id ? "grey-100" : "grey-200"}
                  borderRadius="lg"
                  p={2}
                  mr={comentario.idUser == user.id ? 0 : 3}
                  ml={comentario.idUser != user.id ? 0 : 3}
                >
                  <SoftBox display="flex" justifyContent="space-between">
                    <SoftBox display="flex" alignItems="center" gap={1}>
                      <Icon fontSize="medium">account_circle</Icon>
                      <SoftTypography variant="h6">{comentario.emisor}</SoftTypography>
                    </SoftBox>
                    <SoftBox>
                      <SoftTypography variant="caption">
                        {formatDate(comentario.fecha, "XXXX a las hh:mm")}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox ml={4}>
                    <SoftTypography variant="body2">{comentario.comentario}</SoftTypography>
                  </SoftBox>
                </SoftBox>
              ))}
              {comentarios.length === 0 && <SinDatos />}
              {loading || (loadingSend && <Loading />)}
            </SoftBox>
            <SoftBox display="flex" alignItems="center" gap={2} mt={2}>
              <SoftInput
                placeholder={"Escribe un comentario..."}
                inputProps={{ maxLength: 512 }}
                value={mensaje}
                onChange={(e) => setMensaje(e.target.value)}
                disabled={readOnly || loading || loadingSend}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && !loadingSend && !loading) {
                    handleSend();
                  }
                }}
              />
              <Icon fontSize="medium" color="dark" sx={{ cursor: "pointer" }} onClick={
                !loadingSend && !loading ? handleSend : () => {}
              }>
                send
              </Icon>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalComentarios.defaultProps = {
  open: false,
  handleClose: () => {},
  setCountComentarios: () => {},
  readOnly: false,
};

ModalComentarios.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  alumno: PropTypes.object,
  setCountComentarios: PropTypes.func,
  readOnly: PropTypes.bool,
};
