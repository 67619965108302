// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { validatePhone } from "utils/validations";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "50%", xxl: "40%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalSede({ open, handleClose, refetch, selectedSede }) {
  const [sede, setSede] = useState();
  const { handleSnackbar } = useContext(MessageManager);

  const handleChanges = (e) => {
    setSede({
      ...sede,
      [e.target.name]: e.target.value,
    });
  };

  const [saveSede, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveSede($input: SedeInput!) {
        saveSede(input: $input) {
          id
        }
      }
    `
  );

  const { data: docentes, refetch: refetchDocentes } = useQuery(
    gql`
      query getUsuarios {
        usuarios(filter: { tipoUser: ["Docente", "Gestión Académica"] }) {
          id
          nombre
          apellido
        }
      }
    `
  );

  const { data: provincias } = useQuery(gql`
    query GetProvincias {
      provincias {
        id
        nombre
      }
    }
  `);

  const [getLocalidades, { data: localidades }] = useLazyQuery(gql`
    query GetLocalidades($provincia: ID!) {
      localidades(provincia: $provincia) {
        id
        nombre
        provincia {
          id
        }
      }
    }
  `);

  const handleSave = () => {
    saveSede({
      variables: {
        input: {
          id: sede?.id,
          codigo: sede?.codigo,
          region: sede?.region,
          nombre: sede?.nombre,
          direccion: sede?.direccion,
          telefono: sede?.telefono,
          idLocalidad: sede?.idLocalidad,
          idResponsable: sede?.idResponsable,
        },
      },
    })
      .then(() => {
        refetch();
        handleSnackbar("Sede guardada correctamente", "success");
        handleClose();
      })
      .catch((e) => {
        handleSnackbar("Error al guardar la sede", "error");
      });
  };

  useEffect(() => {
    if (selectedSede && open) {
      refetchDocentes();
      setSede({
        id: selectedSede.id,
        nombre: selectedSede.nombre,
        codigo: selectedSede.codigo,
        region: selectedSede.region,
        direccion: selectedSede.direccion,
        telefono: selectedSede.telefono,
        idLocalidad: selectedSede.localidad.id,
        idResponsable: selectedSede.responsable?.id,
        idProvincia: selectedSede.localidad?.provincia?.id,
      });
    } else {
      setSede();
    }
  }, [selectedSede, open]);

  useEffect(() => {
    if (sede?.idProvincia) {
      getLocalidades({ variables: { provincia: sede?.idProvincia } }).then((data) => {
        if (!data?.data?.localidades?.find((localidad) => localidad.id === sede?.idLocalidad)) {
          let sedeNew = { ...sede };
          delete sedeNew.idLocalidad;
          setSede(sedeNew);
        }
      });
    }
  }, [sede?.idProvincia]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">
                  {selectedSede ? `Editar sede` : `Agregar sede`}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox py={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="nombre"
                    placeholder="Ingrese el nombre de la sede"
                    type="text"
                    name="nombre"
                    value={sede?.nombre || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="region">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Región
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select name="region" value={sede?.region || -1} onChange={handleChanges}>
                    <MenuItem value={-1} disabled>
                      Seleccione una
                    </MenuItem>
                    <MenuItem value={"Villa Mercedes"}>Villa Mercedes</MenuItem>
                    <MenuItem value={"San Luis"}>San Luis</MenuItem>
                    <MenuItem value={"Conlara"}>Conlara</MenuItem>
                    <MenuItem value={"Norte"}>Norte</MenuItem>
                    <MenuItem value={"Oeste"}>Oeste</MenuItem>
                    <MenuItem value={"Sur"}>Sur</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="codigo">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      CUE
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="codigo"
                    placeholder="Ingrese el código de la sede"
                    type="text"
                    name="codigo"
                    value={sede?.codigo || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Responsable
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="idResponsable"
                    value={sede?.idResponsable || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1}>Sin responsable</MenuItem>
                    {docentes?.usuarios?.map((docente) => (
                      <MenuItem key={docente.id} value={docente.id}>
                        {`${docente.nombre} ${docente.apellido}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="direccion">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Dirección
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="direccion"
                    placeholder="Ingrese la dirección de la sede"
                    type="text"
                    name="direccion"
                    value={sede?.direccion || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="telefono">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Teléfono
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="telefono"
                    placeholder="Ingrese el teléfono de la sede"
                    type="text"
                    name="telefono"
                    value={sede?.telefono || ""}
                    error={sede?.telefono && !validatePhone(sede?.telefono)}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="provincia">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Provincia
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="provincia"
                    value={sede?.idProvincia || -1}
                    onChange={(e) =>
                      setSede({
                        ...sede,
                        idProvincia: e.target.value,
                        provincia: provincias?.provincias.find(
                          (provincia) => provincia.id === e.target.value
                        ),
                      })
                    }
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    {provincias?.provincias?.map((provincia) => (
                      <MenuItem key={provincia.id} value={provincia.id}>
                        {provincia.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="localidad">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Localidad
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="localidad"
                    value={sede?.idLocalidad || -1}
                    onChange={(e) =>
                      setSede({
                        ...sede,
                        idLocalidad: e.target.value,
                        localidad: localidades?.localidades.find(
                          (localidad) => localidad.id === e.target.value
                        ),
                      })
                    }
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    {localidades?.localidades?.map((localidad) => (
                      <MenuItem key={localidad.id} value={localidad.id}>
                        {localidad.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={loadingSave}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="uproGreen"
                  circular
                  disabled={
                    loadingSave ||
                    !sede?.nombre ||
                    !sede?.codigo ||
                    !sede?.direccion ||
                    !sede?.telefono ||
                    !sede?.idProvincia ||
                    !sede?.idLocalidad ||
                    !sede?.region ||
                    (sede?.telefono && !validatePhone(sede?.telefono)) ||
                    (selectedSede?.nombre === sede?.nombre &&
                      selectedSede?.codigo === sede?.codigo &&
                      selectedSede?.direccion === sede?.direccion &&
                      selectedSede?.telefono === sede?.telefono &&
                      selectedSede?.localidad?.provincia?.id === sede?.idProvincia &&
                      selectedSede?.localidad?.id === sede?.idLocalidad &&
                      selectedSede?.region === sede?.region)
                  }
                  onClick={handleSave}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  {loadingSave ? (
                    <CircularProgress
                      size={15}
                      sx={{
                        marginRight: 0.5,
                      }}
                      color="inherit"
                    />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  )}
                  &nbsp;Guardar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalSede.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  selectedSede: PropTypes.object,
};
