// React
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";

import { gql, useMutation, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "50%", xxl: "40%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalPlan({ open, handleClose, selectedPlan, idCarrera, refetch }) {
  const [plan, setPlan] = useState();
  const { handleSnackbar } = useContext(MessageManager);
  const codigoRef = useRef(null);
  const nombreRef = useRef(null);

  const handleChanges = (e) => {
    setPlan({
      ...plan,
      [e.target.name]: e.target.value,
    });
  };

  const [savePlan, { loading }] = useMutation(
    gql`
      mutation savePlanEstudio($idCarrera: ID!, $input: PlanEstudioInput!) {
        savePlanEstudio(idCarrera: $idCarrera, input: $input) {
          id
        }
      }
    `
  );

  useEffect(() => {
    if (selectedPlan) {
      setPlan(selectedPlan);
    } else {
      setTimeout(() => {
        if (codigoRef.current) {
          codigoRef.current.focus();
        }
      }, 100);
      setPlan();
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">
                  {selectedPlan ? `Editar plan` : `Agregar plan`}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox py={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="codigo">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Código
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="codigo"
                    placeholder="Ingrese el código del plan"
                    type="text"
                    name="codigo"
                    value={plan?.codigo || ""}
                    onChange={handleChanges}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        nombreRef.current.focus();
                      }
                    }}
                    inputRef={codigoRef}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="nombre"
                    placeholder="Ingrese el nombre del plan"
                    type="text"
                    name="nombre"
                    value={plan?.nombre || ""}
                    onChange={handleChanges}
                    inputRef={nombreRef}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        if (
                          !loading &&
                          plan?.codigo &&
                          plan?.nombre &&
                          (plan?.codigo !== selectedPlan?.codigo ||
                            plan?.nombre !== selectedPlan?.nombre)
                        ) {
                          savePlan({
                            variables: {
                              idCarrera: idCarrera,
                              input: {
                                id: plan?.id || null,
                                codigo: plan?.codigo,
                                nombre: plan?.nombre,
                              },
                            },
                          })
                            .then(() => {
                              handleSnackbar("Plan guardado correctamente", "success");
                              refetch();
                              handleClose();
                            })
                            .catch((e) => {
                              handleSnackbar("Ocurrió un error al guardar el plan", "error");
                            });
                        }
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={loading}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="uproGreen"
                  circular
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={
                    loading ||
                    !plan?.codigo ||
                    !plan?.nombre ||
                    (plan?.codigo === selectedPlan?.codigo && plan?.nombre === selectedPlan?.nombre)
                  }
                  onClick={() => {
                    savePlan({
                      variables: {
                        idCarrera: idCarrera,
                        input: {
                          id: plan?.id || null,
                          codigo: plan?.codigo,
                          nombre: plan?.nombre,
                        },
                      },
                    })
                      .then(() => {
                        handleSnackbar("Plan guardado correctamente", "success");
                        refetch();
                        handleClose();
                      })
                      .catch((e) => {
                        handleSnackbar("Ocurrió un error al guardar el plan", "error");
                      });
                  }}
                >
                  {loading ? (
                    <CircularProgress size={15} sx={{
                marginRight: 0.5,
              }} color="inherit" />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  )}
                  &nbsp;Guardar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalPlan.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedPlan: PropTypes.object,
  idCarrera: PropTypes.string,
  refetch: PropTypes.func,
};
