// @mui material components
import { useState, useEffect, useContext } from "react";
import { Modal, Fade, Card, Select, MenuItem, Grid, Icon } from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { useQuery, gql, useMutation } from "@apollo/client";
import { MessageManager } from "context";
import SoftInput from "components/SoftInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflow: "auto",
  py: 4,
};

const GET_EMPRENDIMIENTOS = gql`
  query getEmprendimientos {
    emprendimientos {
      id
      nombreComercial
      dataPlano {
        dataPlano
        config
      }
    }
  }
`;

const UPDATE_EMPRENDIMIENTO = gql`
  mutation saveEmprendimientoPlano($id: ID!, $input: EmprendimientoPlanoInput) {
    saveEmprendimientoPlano(id: $id, input: $input) {
      dataPlano
      config
    }
  }
`;

export default function GuardarPlano({ open, handleClose, dataPlano }) {
  const [emprendimiento, setEmprendimiento] = useState();
  const [emprendimientos, setEmprendimientos] = useState([]);
  const { loading, error, data } = useQuery(GET_EMPRENDIMIENTOS);
  const [saveEmprendimientoPlano] = useMutation(UPDATE_EMPRENDIMIENTO);

  const { handleSnackbar } = useContext(MessageManager);

  useEffect(() => {
    if (data) {
      // Ordena los emprendimientos por nombreComercial
      let emprendimientosNew = JSON.parse(JSON.stringify(data.emprendimientos));
      emprendimientosNew.sort((a, b) => {
        if (a.nombreComercial > b.nombreComercial) {
          return 1;
        }
        if (a.nombreComercial < b.nombreComercial) {
          return -1;
        }
        return 0;
      });

      setEmprendimientos(emprendimientosNew);
    }
  }, [data]);

  useEffect(() => {
    setEmprendimiento();
  }, [open]);

  const formatarArreglo = (arreglo) => {
    return arreglo.map((elemento) => {
      return JSON.stringify(elemento, null, 2);
    });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(formatarArreglo(dataPlano?.dataPlano));
    handleSnackbar("Código copiado al portapapeles", "success");
  };

  const handleCopyConfig = () => {
    navigator.clipboard.writeText(JSON.stringify(dataPlano?.config));
    handleSnackbar("Configuración copiada al portapapeles", "success");
  };


  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" px={3}>
              <SoftTypography variant="h6">Código de plano generado</SoftTypography>
              <Icon
                sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                  fontSize: `${size.lg} !important`,
                  fontWeight: `${fontWeightBold} !important`,
                  stroke: dark.main,
                  strokeWidth: "2px",
                  cursor: "pointer",
                })}
                onClick={handleClose}
              >
                close
              </Icon>
            </SoftBox>
            <SoftBox px={3} pt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SoftInput value={formatarArreglo(dataPlano?.dataPlano)} multiline rows={20} />
                </Grid>
                <Grid item xs={12}>
                  <SoftButton fullWidth variant="contained" color="primary" onClick={handleCopy}>
                    Copiar
                  </SoftButton>
                </Grid>
                <Grid item xs={12}>
                  <SoftButton fullWidth variant="text" color="primary" onClick={handleCopyConfig}>
                    Copiar configuración
                  </SoftButton>
                </Grid>
              </Grid>
            </SoftBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

GuardarPlano.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dataPlano: PropTypes.object.isRequired,
};
