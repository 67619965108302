// React

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

import {
  CircularProgress,
  Collapse,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import dayjs from "dayjs";
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { validateDNI, validateEmail, validatePhone } from "utils/validations";
import ModalVerificar from "../ModalVerificar";
import ModalVerPlanCarrera from "../VerPlanCarrera";
import { validarName } from "utils/validations";

function PreinscripcionAlumno({ handleCancel, handleSuccess, googleReCaptchaProps, setIdUser }) {
  const { executeRecaptcha } = googleReCaptchaProps;
  const [usuario, setUsuario] = useState();
  const [openVerificar, setOpenVerificar] = useState(false);
  const { handleSnackbar } = useContext(MessageManager);
  const [openModalPlan, setOpenModalPlan] = useState(false);
  const [selectedCarrera, setSelectedCarrera] = useState(null);
  const [sedes, setSedes] = useState([]);

  const [addPreinscripcion, { data, loading, error }] = useMutation(gql`
    mutation addPreinscripcion($usuario: PublicUserInput!, $token: String!) {
      addPreinscripcion(usuario: $usuario, token: $token) {
        id
      }
    }
  `);

  const handleSave = async () => {
    if (!executeRecaptcha) {
      handleSnackbar("Error al verificar reCAPTCHA", "error");
      return;
    }

    const token = await executeRecaptcha("preinscripcion");
    if (!token) {
      handleSnackbar("No se pudo obtener el token de reCAPTCHA", "error");
      return;
    }
    let usuarioData = { ...usuario };
    usuarioData.esDiscapacitado = usuarioData.esDiscapacitado === "Si";
    usuarioData.poseeBeneficiosSociales = usuarioData.poseeBeneficiosSociales === "Si";
    delete usuarioData.provincia;
    delete usuarioData.localidad;
    await addPreinscripcion({
      variables: {
        usuario: usuarioData,
        token: token,
      },
    })
      .then((res) => {
        handleSnackbar(
          "Preinscripción realizada con éxito, se le enviará un email con la documentación a presentar.",
          "success"
        );
        handleSuccess(
          usuario,
          carreras?.carrerasPublic.find((carrera) => carrera.id === usuario.idCarrera)
        );
        setUsuario();
        setIdUser(res?.data?.addPreinscripcion?.id);
      })
      .catch(() => {
        handleSnackbar(
          error?.message || "Ha ocurrido un error al realizar la preinscripción",
          "error"
        );
      });
  };

  const handleChanges = (e) => {
    setUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  const { data: paises } = useQuery(
    gql`
      query getPaises {
        paises {
          id
          nombre
          nacionalidad
          codigo_internacional
        }
      }
    `
  );

  const { data: provincias } = useQuery(gql`
    query GetProvincias {
      provincias {
        id
        nombre
      }
    }
  `);

  const [getLocalidades, { data: localidades }] = useLazyQuery(gql`
    query GetLocalidades($provincia: ID!) {
      localidades(provincia: $provincia) {
        id
        nombre
        provincia {
          id
        }
      }
    }
  `);

  const { data: carreras } = useQuery(
    gql`
      query getCarreras {
        carrerasPublic(filter: { tipo: "Carrera" }) {
          id
          nombre
          codigo
          sedes {
            id
            nombre
          }
        }
      }
    `
  );

  useEffect(() => {
    if (carreras?.carrerasPublic) {
      const sedes = carreras.carrerasPublic.reduce((acc, carrera) => {
        if (carrera.sedes) {
          carrera.sedes.forEach((sede) => {
            if (!acc.find((s) => s.id === sede.id)) {
              acc.push(sede);
            }
          });
        }
        return acc;
      }, []);
      setSedes(sedes);
    }
  }, [carreras]);

  useEffect(() => {
    if (usuario?.idProvincia) {
      getLocalidades({ variables: { provincia: usuario?.idProvincia } }).then((data) => {
        if (!data?.data?.localidades?.find((localidad) => localidad.id === usuario?.idLocalidad)) {
          let userNew = { ...usuario };
          delete userNew.idLocalidad;
          setUsuario(userNew);
        }
      });
    }
  }, [usuario?.idProvincia]);

  return (
    <SoftBox p={3}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center">
        <SoftBox>
          <SoftTypography variant="h6">Preinscripción</SoftTypography>
        </SoftBox>
        <SoftBox display={{ xs: "block", sm: "none" }}>
          <SoftButton
            color="primary"
            circular
            onClick={handleCancel}
            fullWidth={{
              xs: true,
              sm: false,
            }}
            disabled={loading}
            iconOnly
          >
            <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
          </SoftButton>
        </SoftBox>
      </SoftBox>
      <SoftBox py={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="username">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Email de usuario
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="email"
              placeholder="Ingrese el email de usuario"
              type="text"
              name="email"
              value={usuario?.email || ""}
              error={usuario?.email?.length > 0 && !validateEmail(usuario?.email)}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="nombre">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Nombre
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="nombre"
              placeholder="Ingrese el nombre del usuario"
              type="text"
              name="nombre"
              value={usuario?.nombre || ""}
              error={usuario?.nombre?.length > 0 && !validarName(usuario?.nombre)}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="apellido">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Apellido
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="apellido"
              placeholder="Ingrese el apellido del usuario"
              type="text"
              name="apellido"
              value={usuario?.apellido || ""}
              error={usuario?.apellido?.length > 0 && !validarName(usuario?.apellido)}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel htmlFor="dni">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                DNI
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="dni"
              placeholder="Ingrese el dni del usuario"
              type="text"
              name="dni"
              value={usuario?.dni || ""}
              error={usuario?.dni?.length > 0 && !validateDNI(usuario?.dni)}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel htmlFor="fechaNacimiento">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Fecha de nacimiento
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="fechaNacimiento"
              type="date"
              name="fechaNacimiento"
              value={usuario?.fechaNacimiento || ""}
              error={
                usuario?.fechaNacimiento &&
                (dayjs(usuario?.fechaNacimiento).isAfter(dayjs().subtract(17, "year")) ||
                  dayjs(usuario?.fechaNacimiento).isBefore(dayjs().subtract(100, "year")))
              }
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={3} sx={{
                "& .MuiInputBase-root": { 
                  maxHeight: "40px",
                  paddingRight: "40px !important",
                },
              }}>
            <InputLabel htmlFor="nacionalidad">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Nacionalidad
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="idPais"
              value={usuario?.idPais || usuario?.pais?.id || -1}
              onChange={handleChanges}
            >
              <MenuItem value={-1} disabled>
                Seleccione uno
              </MenuItem>
              {paises?.paises?.map((pais) => (
                <MenuItem key={pais.id} value={pais.id}>
                  <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <SoftBox>
                      <SoftTypography variant="p">{pais.nacionalidad}</SoftTypography>
                    </SoftBox>
                    <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                      <SoftBox
                        component="img"
                        src={`https://flagsapi.com/${pais.codigo_internacional}/flat/64.png`}
                        alt={`${pais.nombre}`}
                        sx={{
                          height: "25px",
                        }}
                      />
                    </SoftBox>
                  </SoftBox>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel htmlFor="genero">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Genero
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select name="genero" value={usuario?.genero || -1} onChange={handleChanges}>
              <MenuItem value={-1} disabled>
                Seleccione uno
              </MenuItem>
              <MenuItem value={"Masculino"}>Masculino</MenuItem>
              <MenuItem value={"Femenino"}>Femenino</MenuItem>
              <MenuItem value={"X"}>X</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel htmlFor="esDiscapacitado">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Posee discapacidad
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="esDiscapacitado"
              value={usuario?.esDiscapacitado || -1}
              onChange={handleChanges}
            >
              <MenuItem value={-1} disabled>
                Seleccione uno
              </MenuItem>
              <MenuItem value={"Si"}>Si</MenuItem>
              <MenuItem value={"No"}>No</MenuItem>
            </Select>
          </Grid>
          <Grid
            item
            xs={12}
            sx={
              usuario?.esDiscapacitado === "No" || !usuario?.esDiscapacitado
                ? {
                    paddingTop: "0 !important",
                    transition: "padding 0.5s",
                  }
                : {
                    transition: "padding 0.5s",
                  }
            }
          >
            <Collapse in={usuario?.esDiscapacitado === "Si"}>
              <InputLabel htmlFor="discapacidad">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Discapacidad
                  <SoftTypography variant="caption" fontWeight="bold" color="primary">
                    &nbsp;*
                  </SoftTypography>
                </SoftTypography>
              </InputLabel>
              <SoftInput
                label="discapacidad"
                placeholder="Detalle la discapacidad"
                type="text"
                name="discapacidad"
                value={usuario?.discapacidad || ""}
                onChange={handleChanges}
              />
            </Collapse>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="provincia">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Provincia de residencia
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="provincia"
              value={usuario?.idProvincia || -1}
              onChange={(e) =>
                setUsuario({
                  ...usuario,
                  idProvincia: e.target.value,
                  provincia: provincias?.provincias.find(
                    (provincia) => provincia.id === e.target.value
                  ),
                })
              }
            >
              <MenuItem value={-1} disabled>
                Seleccione uno
              </MenuItem>
              {provincias?.provincias?.map((provincia) => (
                <MenuItem key={provincia.id} value={provincia.id}>
                  {provincia.nombre}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="localidad">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Localidad de residencia
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="localidad"
              value={usuario?.idLocalidad || -1}
              onChange={(e) =>
                setUsuario({
                  ...usuario,
                  idLocalidad: e.target.value,
                  localidad: localidades?.localidades.find(
                    (localidad) => localidad.id === e.target.value
                  ),
                })
              }
            >
              <MenuItem value={-1} disabled>
                {usuario?.idProvincia ? "Seleccione una" : "Seleccione una provincia"}
              </MenuItem>
              {localidades?.localidades?.map((localidad) => (
                <MenuItem key={localidad.id} value={localidad.id}>
                  {localidad.nombre}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="tipoEstudios">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Nivel de educación alcanzado
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="tipoEstudios"
              value={usuario?.tipoEstudios || -1}
              onChange={handleChanges}
            >
              <MenuItem value={-1} disabled>
                Seleccione uno
              </MenuItem>
              <MenuItem value={"Primario Incompleto"}>Primario Incompleto</MenuItem>
              <MenuItem value={"Primario Completo"}>Primario Completo</MenuItem>
              <MenuItem value={"Secundario Incompleto"}>Secundario Incompleto</MenuItem>
              <MenuItem value={"Secundario Completo"}>Secundario Completo</MenuItem>
              <MenuItem value={"Terciario Incompleto"}>Terciario Incompleto</MenuItem>
              <MenuItem value={"Terciario Completo"}>Terciario Completo</MenuItem>
              <MenuItem value={"Universitario Incompleto"}>Universitario Incompleto</MenuItem>
              <MenuItem value={"Universitario Completo"}>Universitario Completo</MenuItem>
              <MenuItem value={"Posgrado Incompleto"}>Posgrado Incompleto</MenuItem>
              <MenuItem value={"Posgrado Completo"}>Posgrado Completo</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel htmlFor="nombreEscuela">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Nombre de la institución donde asistió
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="nombreEscuela"
              placeholder="Ingrese el nombre de la última institución"
              type="text"
              name="nombreEscuela"
              value={usuario?.nombreEscuela || ""}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel htmlFor="telefono">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Teléfono de contacto
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="telefono"
              placeholder="Código de área + número"
              type="text"
              name="telefono"
              value={usuario?.telefono || ""}
              error={usuario?.telefono?.length > 0 && !validatePhone(usuario?.telefono)}
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel htmlFor="telefonoRespaldo">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Teléfono de contacto de respaldo
              </SoftTypography>
            </InputLabel>
            <SoftInput
              label="telefonoRespaldo"
              placeholder="Código de área + número"
              type="text"
              name="telefonoRespaldo"
              value={usuario?.telefonoRespaldo || ""}
              error={
                usuario?.telefonoRespaldo?.length > 0 && !validatePhone(usuario?.telefonoRespaldo)
              }
              onChange={handleChanges}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel htmlFor="preferenciaHorario">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Preferencia de horario del curso de nivelación
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="preferenciaHorario"
              value={usuario?.preferenciaHorario || -1}
              onChange={handleChanges}
            >
              <MenuItem value={-1} disabled>
                Seleccione una
              </MenuItem>
              <MenuItem value={"Mañana"}>Mañana</MenuItem>
              <MenuItem value={"Tarde"}>Tarde</MenuItem>
              <MenuItem value={"Noche"}>Noche</MenuItem>
              <MenuItem value={"Indistinto"}>Indistinto</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="poseeBeneficiosSociales">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Posee beneficios sociales
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="poseeBeneficiosSociales"
              value={usuario?.poseeBeneficiosSociales || -1}
              onChange={handleChanges}
            >
              <MenuItem value={-1} disabled>
                Seleccione una
              </MenuItem>
              <MenuItem value={"Si"}>Si</MenuItem>
              <MenuItem value={"No"}>No</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="escalaMatematicas">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Escala de conocimiento en matemáticas (1 a 10)
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="escalaMatematicas"
              value={usuario?.escalaMatematicas || -1}
              onChange={handleChanges}
            >
              <MenuItem value={-1} disabled>
                Seleccione una
              </MenuItem>
              {[...Array(10).keys()].map((i) => (
                <MenuItem key={i} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="escalaComprensionTextos">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Escala de conocimiento en comprensión de textos (1 a 10)
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="escalaComprensionTextos"
              value={usuario?.escalaComprensionTextos || -1}
              onChange={handleChanges}
            >
              <MenuItem value={-1} disabled>
                Seleccione una
              </MenuItem>
              {[...Array(10).keys()].map((i) => (
                <MenuItem key={i} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="idSede">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Sede
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="idSede"
              value={usuario?.idSede || -1}
              onChange={(e) => {
                setUsuario({
                  ...usuario,
                  idSede: e.target.value,
                  idCarrera: null,
                });
              }}
            >
              <MenuItem value={-1} disabled>
                Seleccione una
              </MenuItem>
              {sedes?.map((usuario) => (
                <MenuItem key={usuario.id} value={usuario.id}>
                  {usuario.nombre}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="idCarrera">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Carrera
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              </SoftTypography>
            </InputLabel>
            <Select
              name="idCarrera"
              value={usuario?.idCarrera || -1}
              onChange={(e) => {
                setUsuario({
                  ...usuario,
                  idCarreraRespaldo: null,
                  idCarrera: e.target.value,
                });
              }}
            >
              <MenuItem value={-1} disabled>
                {usuario?.idSede ? "Seleccione una" : "Seleccione una sede primero"}
              </MenuItem>
              {carreras?.carrerasPublic
                ?.filter((carrera) => carrera.sedes?.find((sede) => sede.id === usuario?.idSede))
                .map((carrera) => (
                  <MenuItem key={carrera.id} value={carrera.id}>
                    {carrera.nombre}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel htmlFor="idCarreraRespaldo">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Carrera opcional
              </SoftTypography>
            </InputLabel>
            <Select
              name="idCarreraRespaldo"
              value={usuario?.idCarreraRespaldo || -1}
              onChange={handleChanges}
            >
              <MenuItem value={-1} disabled>
                {usuario?.idSede ? "Seleccione una" : "Seleccione una sede primero"}
              </MenuItem>
              {carreras?.carrerasPublic
                ?.filter((carrera) => carrera.sedes?.find((sede) => sede.id === usuario?.idSede))
                .filter((carrera) => carrera.id !== usuario?.idCarrera)
                .map((carrera) => (
                  <MenuItem key={carrera.id} value={carrera.id}>
                    {carrera.nombre}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox
        display={{
          xs: "flex-row",
          sm: "flex",
        }}
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftBox>
          <SoftBox
            display={{
              xs: "flex-row",
              sm: "flex",
            }}
            gap={2}
          >
            <SoftBox display={{ xs: "none", sm: "block" }}>
              <SoftButton
                color="primary"
                circular
                onClick={handleCancel}
                fullWidth={{
                  xs: true,
                  sm: false,
                }}
                disabled={loading}
              >
                <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                &nbsp;Cancelar
              </SoftButton>
            </SoftBox>
            <SoftBox display={{ xs: "none", sm: "block" }}>
              <Collapse
                orientation="horizontal"
                in={usuario?.idCarrera}
                timeout={500}
                unmountOnExit
                mountOnEnter
              >
                <SoftButton
                  color="uproYellow"
                  circular
                  onClick={() => {
                    setSelectedCarrera(usuario?.idCarrera);
                    setOpenModalPlan(true);
                  }}
                  sx={{
                    textWrap: "nowrap",
                  }}
                >
                  <Icon sx={{ fontWeight: "light" }}>class</Icon>
                  &nbsp;Ver info de la carrera
                </SoftButton>
              </Collapse>
            </SoftBox>
            {usuario?.idCarrera && (
              <SoftBox display={{ xs: "block", sm: "none" }}>
                <SoftButton
                  color="uproYellow"
                  circular
                  onClick={() => {
                    setSelectedCarrera(usuario?.idCarrera);
                    setOpenModalPlan(true);
                  }}
                  sx={{
                    textWrap: "nowrap",
                  }}
                  fullWidth
                >
                  <Icon sx={{ fontWeight: "light" }}>class</Icon>
                  &nbsp;Ver info de la carrera
                </SoftButton>
              </SoftBox>
            )}
            <SoftBox display={{ xs: "none", sm: "block" }}>
              <Collapse
                orientation="horizontal"
                in={usuario?.idCarreraRespaldo}
                timeout={500}
                unmountOnExit
                mountOnEnter
              >
                <SoftButton
                  color="uproYellow"
                  circular
                  onClick={() => {
                    setSelectedCarrera(usuario?.idCarreraRespaldo);
                    setOpenModalPlan(true);
                  }}
                  sx={{
                    textWrap: "nowrap",
                  }}
                >
                  <Icon sx={{ fontWeight: "light" }}>class</Icon>
                  &nbsp;Ver info de la carrera de respaldo
                </SoftButton>
              </Collapse>
            </SoftBox>
            {usuario?.idCarreraRespaldo && (
              <SoftBox display={{ xs: "block", sm: "none" }} mt={2}>
                <SoftButton
                  color="uproYellow"
                  circular
                  onClick={() => {
                    setSelectedCarrera(usuario?.idCarreraRespaldo);
                    setOpenModalPlan(true);
                  }}
                  fullWidth
                >
                  <Icon sx={{ fontWeight: "light" }}>class</Icon>
                  &nbsp;Ver info de la carrera de respaldo
                </SoftButton>
              </SoftBox>
            )}
          </SoftBox>
        </SoftBox>
        <SoftBox
          mt={{
            xs: 2,
            sm: 0,
          }}
        >
          <SoftBox>
            <SoftButton
              color="uproGreen"
              circular
              disabled={
                loading ||
                !usuario?.email ||
                !usuario?.nombre ||
                !usuario?.apellido ||
                !usuario?.dni ||
                !usuario?.idPais ||
                !usuario?.genero ||
                !usuario?.preferenciaHorario ||
                !usuario?.idSede ||
                !usuario?.esDiscapacitado ||
                !usuario?.tipoEstudios ||
                !usuario?.nombreEscuela ||
                !usuario?.poseeBeneficiosSociales ||
                !usuario?.telefono ||
                !usuario?.idProvincia ||
                !usuario?.idLocalidad ||
                (usuario?.nombre?.length > 0 && !validarName(usuario?.nombre)) ||
                (usuario?.apellido?.length > 0 && !validarName(usuario?.apellido)) ||
                (usuario?.telefonoRespaldo && !validatePhone(usuario?.telefonoRespaldo)) ||
                (usuario?.telefono && !validatePhone(usuario?.telefono)) ||
                (usuario?.esDiscapacitado === "Si" && !usuario?.discapacidad) ||
                !usuario?.idCarrera ||
                (usuario?.fechaNacimiento &&
                  (dayjs(usuario?.fechaNacimiento).isAfter(dayjs().subtract(17, "year")) ||
                    dayjs(usuario?.fechaNacimiento).isBefore(dayjs().subtract(100, "year")))) ||
                (usuario?.email && !validateEmail(usuario?.email)) ||
                (usuario?.dni && !validateDNI(usuario?.dni))
              }
              fullWidth={{
                xs: true,
                sm: false,
              }}
              onClick={handleSave}
            >
              {loading ? (
                <CircularProgress
                  size={15}
                  sx={{
                    marginRight: 0.5,
                  }}
                  color="inherit"
                />
              ) : (
                <Icon sx={{ fontWeight: "light" }}>done</Icon>
              )}
              &nbsp;Preinscribirse
            </SoftButton>
          </SoftBox>
        </SoftBox>
        <ModalVerificar
          open={openVerificar}
          handleClose={() => setOpenVerificar(false)}
          usuario={usuario}
          handleCancel={handleCancel}
        />
        <ModalVerPlanCarrera
          open={openModalPlan}
          handleClose={() => {
            setOpenModalPlan(false);
          }}
          idCarerra={selectedCarrera}
        />
      </SoftBox>
    </SoftBox>
  );
}

PreinscripcionAlumno.defaultProps = {
  handleCancel: () => {},
  handleSuccess: () => {},
  setIdUser: () => {},
};

PreinscripcionAlumno.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  googleReCaptchaProps: PropTypes.object.isRequired,
  setIdUser: PropTypes.func.isRequired,
};

const WrappedPreinscripcionAlumno = withGoogleReCaptcha(PreinscripcionAlumno);

function Captcha({ handleCancel, handleSuccess, setIdUser }) {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY}>
      <WrappedPreinscripcionAlumno
        handleCancel={handleCancel}
        handleSuccess={handleSuccess}
        setIdUser={setIdUser}
      />
    </GoogleReCaptchaProvider>
  );
}

Captcha.defaultProps = {
  handleCancel: () => {},
  handleSuccess: () => {},
  setIdUser: () => {},
};

Captcha.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  setIdUser: PropTypes.func.isRequired,
};

export default Captcha;
