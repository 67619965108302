import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import { LayersControl, MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

import getLocationIcon from "assets/images/map/getLocation.png";
import markerLocation from "assets/images/map/location.png";
import markerDark from "assets/images/map/marker-dark.png";
import markerPrimary from "assets/images/map/marker-primary.png";
import markerSecondary from "assets/images/map/marker-secondary.png";
import shadow from "assets/images/map/shadow.png";
import { styled } from "@mui/material";

const defaultIconProps = {
  iconSize: [25, 40],
  iconAnchor: [12, 40],
  popupAnchor: [0, -30],
  shadowUrl: shadow,
  shadowSize: [25, 40],
  shadowAnchor: [0, 38],
};

const icons = {
  primary: L.icon({
    ...defaultIconProps,
    iconUrl: markerPrimary,
  }),
  secondary: L.icon({
    ...defaultIconProps,
    iconUrl: markerSecondary,
  }),
  dark: L.icon({
    ...defaultIconProps,
    iconUrl: markerDark,
  }),
  location: L.icon({
    iconUrl: markerLocation,
    iconSize: [30, 30],
  }),
};
function MapControls({ newItem, markers, selectedItem }) {
  const map = useMap();
  const [location, setLocation] = useState(null);
  const [lastMarker, setLastMarker] = useState(null);
  const [newMarker, setNewMarker] = useState(null);
  const [currentCircles, setCurrentCircles] = useState([]); // Estado para almacenar los círculos actuales

  const handleLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation([position.coords.latitude, position.coords.longitude]);
      });
    }
  };

  useEffect(() => {
    handleLocation();
  }, []);

  useEffect(() => {
    if (location) {
      if (lastMarker) {
        map.removeLayer(lastMarker);
      }

      const marker = L.marker(location);
      map.addLayer(marker);
      marker.setIcon(icons["location"]);
      marker.bindPopup("Tu ubicación");

      setLastMarker(marker);
    }
  }, [location]);

  useEffect(() => {
    if (newItem) {
      if (newMarker) {
        map.removeLayer(newMarker);
      }

      const marker = L.marker([newItem.latitud, newItem.longitud]);
      map.addLayer(marker);
      marker.setIcon(icons[newItem.color || "dark"]);

      setNewMarker(marker);
    } else {
      if (newMarker) {
        map.removeLayer(newMarker);
      }
    }
  }, [newItem]);

  useEffect(() => {
    // Limpiar los círculos anteriores
    currentCircles.forEach(circle => {
      map.removeLayer(circle);
    });

    if (markers) {
      const newCircles = markers.map(item => {
        const circle = L.circle([item.latitud, item.longitud], {
          color: item.color,
          fillColor: item.color,
          fillOpacity: 0.3,
          stroke: 0.5,
          radius: 300 * item.cantidad > 20000 ? 20000 : item.cantidad * 300,
          zIndex: 1000 / item.cantidad,
        });

        map.addLayer(circle);

        circle.addEventListener("click", () => {
          const popup = L.popup()
            .setLatLng([item.latitud, item.longitud])
            .setContent(`<p>${item.title} - Salidas: ${item.cantidad} </p>`)
            .openOn(map);
        });

        return circle;
      });

      setCurrentCircles(newCircles);

      // Controlar el vuelo del mapa
      const firstItem = markers[0];
      if (firstItem) {
        map.flyTo([firstItem.latitud, firstItem.longitud], 8, {
          animate: true,
          duration: 1.5,
        });
      }

      if (selectedItem) {
        const selected = markers.find(item => item.id === selectedItem.id);
        if (selected) {
          map.flyTo([selected.latitud, selected.longitud], 13, {
            animate: true,
            duration: 1.5,
          });
        }
      }
    }
  }, [markers, selectedItem]);

  const style = {
    bottom: "0",
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    margin: "0.6rem",
    paddingTop: "0.6rem",
    paddingLeft: "0.6rem",
    paddingRight: "0.6rem",
    paddingBottom: "0.3rem",
    boxShadow: "rgba(0, 0, 0, 0.11) 0px 4px 7px -1px, rgba(0, 0, 0, 0.07) 0px 2px 4px -1px"
  };

  return (
    <div className="leaflet-bottom leaflet-right" style={style}>
      <div className="leaflet-control" style={{ padding: 0, margin: 0 }}>
        <div
          className="custom-control-container"
          onClick={handleLocation}
          style={{ cursor: "pointer" }}
        >
          <img
            src={getLocationIcon}
            alt="Locate"
            className="custom-control-icon"
            width="25px"
            style={
              !location
                ? {
                    filter: "grayscale(100%)",
                  }
                : {}
            }
          />
        </div>
      </div>
    </div>
  );
}


MapControls.propTypes = {
  newItem: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "dark"]),
    latitud: PropTypes.string.isRequired,
    longitud: PropTypes.string.isRequired,
    title: PropTypes.string,
    action: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
    data: PropTypes.object,
  }),
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.oneOf(["primary", "secondary", "dark"]),
      latitud: PropTypes.string.isRequired,
      longitud: PropTypes.string.isRequired,
      title: PropTypes.string,
      action: PropTypes.shape({
        name: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
      }),
      data: PropTypes.object,
    })
  ),
  selectedItem: PropTypes.object,
};

const StyledMapContainer = styled(MapContainer)`
  width: 100%;
  height: 100%;
  border-radius: 1rem;

  .leaflet-control-attribution {
    display: none !important;
  }
`;

const StyledLayersControl = styled(LayersControl)`
.leaflet-control-layers {
    background-color: #333;
    color: white;
    border-radius: 8px;
    padding: 10px;
  }
  
  .leaflet-control-layers-toggle {
    background-image: url('path/to/your/icon.png');
    background-size: cover;
    width: 30px;
    height: 30px;
  }
  `;

export default function Map({ items, newItem, markers, selectedItem }) {
  return (
    <StyledMapContainer
      center={[-38.416097, -63.616672]}
      zoom={5}
      scrollWheelZoom={true}
    >
      <StyledLayersControl position="topright">
        <LayersControl.BaseLayer checked name="Normal">
          <TileLayer
            attribution="OpenStreetMap"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Dark">
          <TileLayer
            attribution="Stadia Maps"
            url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Satellite">
          <TileLayer
            attribution="ArcGIS"
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          />
        </LayersControl.BaseLayer>
      </StyledLayersControl>
      {items.map((item, index) => (
        <Marker
          key={index}
          position={[item.latitud, item.longitud]}
          icon={icons[item.color || "dark"]}
        >
          <Popup>
            <SoftBox mb={1}>
              <SoftTypography variant="button">{item.title}</SoftTypography>
            </SoftBox>
            {Object.keys(item.data).map((key, index) => (
              <SoftBox mb={0.5} key={index}>
                <SoftTypography variant="caption" fontWeight="medium">
                  {key}:{" "}
                </SoftTypography>
                <SoftTypography variant="caption">{item.data[key]}</SoftTypography>
              </SoftBox>
            ))}
            {item.action && (
              <SoftBox mt={1}>
                <SoftButton
                  variant="gradient"
                  color={item.color || "dark"}
                  fullWidth
                  onClick={item.action.onClick}
                >
                  {item.action.name}
                </SoftButton>
              </SoftBox>
            )}
          </Popup>
        </Marker>
      ))}
      <MapControls newItem={newItem} markers={markers} selectedItem={selectedItem} />
    </StyledMapContainer>
  );
}

Map.defaultProps = {
  items: [],
};

Map.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.oneOf(["primary", "secondary", "dark"]),
      latitud: PropTypes.string.isRequired,
      longitud: PropTypes.string.isRequired,
      title: PropTypes.string,
      action: PropTypes.shape({
        name: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
      }),
      data: PropTypes.object,
    })
  ),
  newItem: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "dark"]),
    latitud: PropTypes.string.isRequired,
    longitud: PropTypes.string.isRequired,
  }),
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.oneOf(["primary", "secondary", "dark"]),
      latitud: PropTypes.string.isRequired,
      longitud: PropTypes.string.isRequired,
      title: PropTypes.string,
      action: PropTypes.shape({
        name: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
      }),
      data: PropTypes.object,
    })
  ),
  selectedItem: PropTypes.object,
};
