import { useContext, useEffect, useState } from "react";

// react-router components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";

// Kaizen Dashboard components
import Logout from "components/Modals/Logout";
import ModalVersion from "components/Modals/Version";
import ModalAdd from "components/Notificaciones/ModalAdd";
import ModalAlerta from "components/Recordatorios/ModalAlerta";
import ModalRecordatorios from "components/Recordatorios/ModalRecordatorio";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { adminKey, getVersion } from "services/config";

// Kaizen Dashboard examples
import Breadcrumbs from "components/Breadcrumbs";
import NotificationItem from "components/Items/NotificationItem";
import RecordatorioItem from "components/Items/RecordatorioItem";
import ModalNotificacionVer from "components/Notificaciones/ModalVer";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarIconButton,
  navbarMobileMenu,
  navbarRow,
} from "components/Navbars/DashboardNavbar/styles";

// Kaizen Dashboard context
import { gql, useQuery } from "@apollo/client";
import { Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftButton from "components/SoftButton";
import {
  setMiniSidenav,
  setOpenConfigurator,
  setTransparentNavbar,
  useSoftUIController,
} from "context";
import { NotificationContext } from "context/notificationContext";
import { UserContext } from "context/user";
import dayjs from "dayjs";
import ModalOptions from "components/Modals/Options";

const GET_RECORDATORIOS = gql`
  query recordatorios($filter: [RecordatorioInput]) {
    recordatorios(filter: $filter) {
      id
      titulo
      descripcion
      fechaVencimiento
    }
  }
`;

const GET_NOTIFICACIONES = gql`
  query notificaciones {
    notificaciones {
      id
      createdAt
      header
      body
      url
      programmedAt
    }
  }
`;

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openAddNotification, setOpenAddNotification] = useState(false);
  const { user, verificarAcceso, logout } = useContext(UserContext);
  const [recordatorios, setRecordatorios] = useState([]);
  const [selectedRecordatorio, setSelectedRecordatorio] = useState(null);
  const [openVersion, setOpenVersion] = useState(false);
  const [openAlertaRecordatorio, setOpenAlertaRecordatorio] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const { refetch, data } = useQuery(GET_RECORDATORIOS, {
    variables: { filter: [{ idUser: user?.id ?? null }] },
  });
  const [recordatoriosToday, setRecordatoriosToday] = useState([]);
  const [recordatoriosVencidos, setRecordatoriosVencidos] = useState([]);
  const [notificaciones, setNotificaciones] = useState([]);
  const { data: dataNotificaciones } = useQuery(GET_NOTIFICACIONES);
  const notificationSubscription = useContext(NotificationContext);
  const [notificationSelected, setNotificationSelected] = useState(null);
  const [version, setVersion] = useState();
  const [openUserModal, setOpenUserModal] = useState(false);

  useEffect(() => {
    getVersion().then((response) => {
      setVersion(response.version);
    });
  }, []);

  const route = useLocation().pathname.split("/").slice(1);
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleOpenNotifications = (event) => setOpenNotifications(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseNotifications = () => setOpenNotifications(false);

  const handleOpenLogout = () => {
    setOpenLogout(true);
  };

  const handleCloseLogout = () => {
    setOpenLogout(false);
  };

  const handleCloseVersion = () => {
    setOpenVersion(false);
    localStorage.setItem("novedades", JSON.stringify({ version: version }));
  };

  const esHoy = (fecha) => {
    const hoy = new Date();
    return (
      fecha.getDate() == hoy.getDate() &&
      fecha.getMonth() == hoy.getMonth() &&
      fecha.getFullYear() == hoy.getFullYear()
    );
  };

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    refetch();
  }, [selectedRecordatorio, recordatoriosToday, openMenu]);

  useEffect(() => {
    if (version) {
      if (localStorage.getItem("novedades")) {
        let novedades = JSON.parse(localStorage.getItem("novedades"));
        if (novedades.version === version) {
          setOpenVersion(false);
        } else {
          setOpenVersion(true);
        }
      } else {
        setOpenVersion(true);
      }
    }
  }, [version]);

  useEffect(() => {
    if (dataNotificaciones) {
      let notificaciones = [];
      dataNotificaciones.notificaciones.forEach((notificacion) => {
        notificaciones.push({
          id: notificacion.id,
          header: notificacion.header,
          body: notificacion.body,
          programmedAt: notificacion.programmedAt,
          vista: false,
        });
      });
      if (localStorage.getItem("notificaciones")) {
        let notificacionesLocalStorage = JSON.parse(localStorage.getItem("notificaciones"));
        notificacionesLocalStorage.forEach((notificacion) => {
          if (notificaciones.find((n) => n.id === notificacion)) {
            notificaciones.find((n) => n.id === notificacion).vista = true;
          }
        });
      }
      setNotificaciones(notificaciones);
    }
  }, [dataNotificaciones]);

  const handleClickNotification = (notificacion) => {
    let notificacionesArray = JSON.parse(JSON.stringify(notificaciones));
    let thisNotification = notificacionesArray.find((n) => n.id === notificacion.id);
    thisNotification.vista = true;
    if (localStorage.getItem("notificaciones")) {
      let notificacionesLocalStorage = JSON.parse(localStorage.getItem("notificaciones"));
      notificacionesLocalStorage.push(notificacion.id);
      localStorage.setItem("notificaciones", JSON.stringify(notificacionesLocalStorage));
    } else {
      localStorage.setItem("notificaciones", JSON.stringify([notificacion.id]));
    }
    setNotificaciones(notificacionesArray);
    setNotificationSelected(notificacion);
  };

  useEffect(() => {
    if (data) {
      let recordatoriosArray = JSON.parse(JSON.stringify(data.recordatorios));
      if (recordatoriosArray) {
        recordatoriosArray.sort((a, b) => {
          return new Date(a.fechaVencimiento) - new Date(b.fechaVencimiento);
        });
        setRecordatorios(recordatoriosArray);
        let recordatoriosToday = data.recordatorios.filter((recordatorio) => {
          return esHoy(dayjs(recordatorio.fechaVencimiento).toDate());
        });
        setRecordatoriosToday(recordatoriosToday);
        if (recordatoriosToday.length > 0) {
          setOpenAlertaRecordatorio(true);
        }
        let recordatoriosVencidos = data.recordatorios.filter((recordatorio) => {
          return (
            dayjs(recordatorio.fechaVencimiento).toDate() < dayjs().toDate() &&
            !esHoy(dayjs(recordatorio.fechaVencimiento).toDate())
          );
        });
        setRecordatoriosVencidos(recordatoriosVencidos);
      }
    }
  }, [data]);

  useEffect(() => {
    if (notificationSubscription) {
      let notificacionesArray = JSON.parse(JSON.stringify(notificaciones));
      notificacionesArray.unshift(notificationSubscription.notificaciones);
      setNotificaciones(notificacionesArray);
    }
  }, [notificationSubscription]);

  // Render the notifications menu
  const renderRecordatorios = () => (
    <Menu
      anchorEl={openMenu}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2, ml: -2 }}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      PaperProps={{
        elevation: 0,
        sx: {
          maxHeight: "300px",
          overflowY: "scroll",
          mr: 2,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
          },
        },
      }}
    >
      <SoftBox component="li">
        <SoftBox p={2} pb={0}>
          <SoftTypography variant="h6">Recordatorios</SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox p={2}>
        {recordatorios.map((recordatorio) => (
          <RecordatorioItem
            key={recordatorio.id}
            titulo={recordatorio.titulo}
            vencimiento={recordatorio.fechaVencimiento}
            onClick={() => setSelectedRecordatorio(recordatorio)}
          />
        ))}
        {recordatorios.length === 0 && (
          <SoftBox py={1} textAlign="center">
            <SoftTypography variant="caption">No hay recordatorios</SoftTypography>
          </SoftBox>
        )}
      </SoftBox>
      <SoftBox component="li" sx={{ minWidth: "300px" }}>
        <SoftBox p={2} pt={1}>
          {verificarAcceso(16) && (
            <SoftButton
              fullWidth
              color="primary"
              circular
              onClick={() =>
                setSelectedRecordatorio({
                  id: null,
                  titulo: "",
                  descripcion: "",
                  fechaVencimiento: dayjs().format("YYYY-MM-DD"),
                })
              }
            >
              Agregar
            </SoftButton>
          )}
        </SoftBox>
      </SoftBox>
    </Menu>
  );

  const renderNotifications = () => (
    <Menu
      anchorEl={openNotifications}
      anchorReference={null}
      open={Boolean(openNotifications)}
      onClose={handleCloseNotifications}
      sx={{ mt: 2 }}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      PaperProps={{
        elevation: 0,
        sx: {
          minWidth: "300px",
          maxHeight: "300px",
          overflowY: "scroll",
          mr: 2,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
          },
        },
      }}
    >
      <SoftBox component="li">
        <SoftBox p={2} pb={0} display="flex" justifyContent="space-between" alignItems="center">
          <SoftTypography variant="h6">Notificaciones</SoftTypography>
          <SoftBox>
            <Tooltip title="Marcar todas como leídas" placement="left">
              <SoftButton
                iconOnly
                circular
                color="primary"
                onClick={() => {
                  if (notificaciones.length > 0) {
                    let notificacionesArray = JSON.parse(JSON.stringify(notificaciones));
                    notificacionesArray.forEach((notificacion) => {
                      notificacion.vista = true;
                    });
                    localStorage.setItem(
                      "notificaciones",
                      JSON.stringify(notificacionesArray.map((n) => n.id))
                    );
                    setNotificaciones(notificacionesArray);
                  }
                }}
              >
                <Icon>visibility_off</Icon>
              </SoftButton>
            </Tooltip>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox>
          {notificaciones.map((notificacion) => (
            <NotificationItem
              key={notificacion.id}
              titulo={notificacion.header}
              vencimiento={notificacion.programmedAt}
              vista={notificacion.vista}
              onClickFunc={() => handleClickNotification(notificacion)}
            />
          ))}
          {notificaciones.length === 0 && (
            <SoftBox py={1} textAlign="center">
              <SoftTypography variant="caption">No hay notificaciones</SoftTypography>
            </SoftBox>
          )}
        </SoftBox>
      </SoftBox>
      {verificarAcceso(15) && (
        <SoftBox component="li" sx={{ minWidth: "300px", width: "100%" }}>
          <SoftBox p={2} pt={1}>
            <SoftButton
              fullWidth
              color="primary"
              onClick={() => setOpenAddNotification(true)}
              circular
            >
              Agregar
            </SoftButton>
          </SoftBox>
        </SoftBox>
      )}
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox
          sx={{
            width: "100%",
          }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <SoftBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => navbarRow(theme, { isMini })}
          >
            <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          </SoftBox>
          <SoftBox
            display={{
              xs: "none",
              sm: "flex",
            }}
          >
            <IconButton
              size="small"
              color="inherit"
              sx={navbarIconButton}
              aria-controls="notification-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={handleOpenNotifications}
            >
              {notificaciones.filter((notificacion) => !notificacion.vista).length > 0 && (
                <SoftBox mr={1}>
                  <SoftBadge
                    badgeContent={
                      notificaciones.filter((notificacion) => !notificacion.vista).length
                    }
                    color="primary"
                    onClick={handleOpenNotifications}
                  />
                </SoftBox>
              )}
              <Tooltip title="Notificaciones" placement="top">
                <Icon color="dark">notifications</Icon>
              </Tooltip>
            </IconButton>
            <IconButton
              size="small"
              color="inherit"
              sx={navbarIconButton}
              aria-controls="notification-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={handleOpenMenu}
            >
              {recordatoriosToday.length > 0 && (
                <SoftBox mr={1}>
                  <SoftBadge
                    badgeContent={recordatoriosToday.length + " hoy"}
                    color="info"
                    onClick={handleOpenMenu}
                  />
                </SoftBox>
              )}
              {recordatoriosVencidos.length > 0 && (
                <SoftBox mr={1}>
                  <SoftBadge
                    badgeContent={recordatoriosVencidos.length + " vencidos"}
                    color="error"
                    onClick={handleOpenMenu}
                  />
                </SoftBox>
              )}
              <Tooltip title="Recordatorios" placement="top">
                <Icon color="dark">event</Icon>
              </Tooltip>
            </IconButton>
            <IconButton sx={navbarIconButton} size="small">
              <Icon
                sx={({ palette: { dark, white } }) => ({
                  color: light ? white.main : dark.main,
                })}
              >
                account_circle
              </Icon>
              <SoftTypography variant="button" fontWeight="medium" color="dark">
                {user?.nombre || user?.username || "Usuario"}
              </SoftTypography>
            </IconButton>

            <IconButton sx={navbarIconButton} size="small" onClick={handleOpenLogout}>
              <Icon
                sx={({ palette: { dark, white } }) => ({
                  color: light ? white.main : dark.main,
                })}
              >
                logout
              </Icon>
              <SoftTypography variant="button" fontWeight="medium" color={light ? "white" : "dark"}>
                Salir
              </SoftTypography>
            </IconButton>

            <IconButton
              size="small"
              color="inherit"
              sx={navbarMobileMenu}
              onClick={handleMiniSidenav}
            >
              <Icon className={light ? "text-white" : "text-dark"}>
                {miniSidenav ? "menu_open" : "menu"}
              </Icon>
            </IconButton>
            {renderRecordatorios()}
            {renderNotifications()}
          </SoftBox>
          <SoftBox
            display={{
              xs: "flex",
              sm: "none",
            }}
          >
            <SoftBox mr={2}>
              <SoftButton color="primary" circular iconOnly onClick={() => setOpenUserModal(true)}>
                <Icon>person</Icon>
              </SoftButton>
            </SoftBox>
            <SoftBox>
              <SoftButton color="uproGreen" circular iconOnly onClick={handleMiniSidenav}>
                <Icon>{miniSidenav ? "menu_open" : "menu"}</Icon>
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Toolbar>
      <Logout open={openLogout} handleClose={handleCloseLogout} btnFunction={logout} />
      <ModalVersion open={openVersion} handleClose={handleCloseVersion} />
      <ModalRecordatorios
        open={selectedRecordatorio ? true : false}
        handleClose={() => setSelectedRecordatorio(null)}
        recordatorio={selectedRecordatorio}
        setRecordatorio={setSelectedRecordatorio}
        idUser={user?.id ?? null}
      />
      <ModalAlerta
        open={openAlertaRecordatorio}
        handleClose={() => setOpenAlertaRecordatorio(false)}
        recordatorios={recordatoriosToday}
        setRecordatorios={setRecordatoriosToday}
      />
      <ModalAdd open={openAddNotification} handleClose={() => setOpenAddNotification(false)} />
      <ModalNotificacionVer
        open={notificationSelected ? true : false}
        handleClose={() => setNotificationSelected(null)}
        notificacion={notificationSelected}
      />
      <ModalOptions
        open={openUserModal}
        handleClose={() => setOpenUserModal(false)}
        options={[
          {
            title: "Notificaciones",
            icon: "notifications",
            onClick: (e) => setOpenNotifications(e.currentTarget),
            color: "uproGreen",
          },
          {
            title: "Recordatorios",
            icon: "event",
            onClick: (e) => setOpenMenu(e.currentTarget),
            color: "uproYellow",
          },
          {
            title: "Cerrar sesión",
            icon: "logout",
            onClick: handleOpenLogout,
            color: "uproPink",
          },
        ]}
      />
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
