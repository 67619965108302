// React
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";

import { gql, useMutation, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "50%", xxl: "40%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalNivel({ open, handleClose, selectedNivel, idPlan, refetch }) {
  const [nivel, setNivel] = useState();
  const { handleSnackbar } = useContext(MessageManager);
  const codigoRef = useRef(null);
  const nombreRef = useRef(null);

  const handleChanges = (e) => {
    setNivel({
      ...nivel,
      [e.target.name]: e.target.value,
    });
  };

  const [saveNivel, { loading }] = useMutation(
    gql`
      mutation saveNivel($idPlan: ID!, $input: NivelInput!) {
        saveNivel(idPlan: $idPlan, input: $input) {
          id
        }
      }
    `
  );

  useEffect(() => {
    if (selectedNivel) {
      setNivel(selectedNivel);
    } else {
      setTimeout(() => {
        if (codigoRef.current) {
          codigoRef.current.focus();
        }
      }, 100);
      setNivel();
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">
                  {selectedNivel ? `Editar nivel` : `Agregar nivel`}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox py={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="codigo">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Código
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="codigo"
                    placeholder="Ingrese el código del nivel"
                    type="text"
                    name="codigo"
                    value={nivel?.codigo || ""}
                    onChange={handleChanges}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        nombreRef.current.focus();
                      }
                    }}
                    inputRef={codigoRef}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="nombre"
                    placeholder="Ingrese el nombre del nivel"
                    type="text"
                    name="nombre"
                    value={nivel?.nombre || ""}
                    onChange={handleChanges}
                    inputRef={nombreRef}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        if(!loading && nivel?.codigo && nivel?.nombre && (nivel?.codigo !== selectedNivel?.codigo || nivel?.nombre !== selectedNivel?.nombre)) {
                          saveNivel({
                            variables: {
                              idPlan: idPlan,
                              input: {
                                id: nivel?.id || null,
                                codigo: nivel?.codigo,
                                nombre: nivel?.nombre,
                              },
                            },
                          })
                            .then(() => {
                              handleSnackbar("Nivel guardado correctamente", "success");
                              refetch();
                              handleClose();
                            })
                            .catch((e) => {
                              handleSnackbar("Ocurrió un error al guardar el nivel", "error");
                            });
                        }
                      }
                    }
                  }
                  />
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={loading}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="uproGreen"
                  circular
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={
                    loading ||
                    !nivel?.codigo ||
                    !nivel?.nombre ||
                    (nivel?.codigo === selectedNivel?.codigo && nivel?.nombre === selectedNivel?.nombre)
                  }
                  onClick={() => {
                    saveNivel({
                      variables: {
                        idPlan: idPlan,
                        input: {
                          id: nivel?.id || null,
                          codigo: nivel?.codigo,
                          nombre: nivel?.nombre,
                        },
                      },
                    })
                      .then(() => {
                        handleSnackbar("Nivel guardado correctamente", "success");
                        refetch();
                        handleClose();
                      })
                      .catch((e) => {
                        handleSnackbar("Ocurrió un error al guardar el nivel", "error");
                      });
                  }}
                >
                  {loading ? (
                    <CircularProgress size={15} sx={{
                marginRight: 0.5,
              }} color="inherit" />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  )}
                  &nbsp;Guardar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalNivel.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedNivel: PropTypes.object,
  idPlan: PropTypes.string,
  refetch: PropTypes.func,
};
