// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";

import { gql, useMutation, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "50%", xxl: "40%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalUnidadAcademica({ open, handleClose, refetch, selectedUnidad }) {
  const [unidadAcademica, setUnidadAcademica] = useState();
  const { handleSnackbar } = useContext(MessageManager);

  const handleChanges = (e) => {
    setUnidadAcademica({
      ...unidadAcademica,
      [e.target.name]: e.target.value,
    });
  };

  const [saveUnidadAcademica, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveUnidadAcademica($input: UnidadAcademicaInput!) {
        saveUnidadAcademica(input: $input) {
          id
        }
      }
    `
  );

  const { data: cursos, refetch: refetchCursos } = useQuery(
    gql`
      query {
        carreras(filter: { tipo: "Curso de Ingreso" }) {
          id
          tipo
          nombre
          codigo
        }
      }
    `
  );

  const handleSave = () => {
    saveUnidadAcademica({
      variables: {
        input: {
          ...unidadAcademica,
          idCurso: unidadAcademica.idCurso === -1 ? null : unidadAcademica.idCurso,
        },
      },
    })
      .then(() => {
        refetch();
        handleSnackbar("Unidad académica guardada correctamente", "success");
        handleClose();
      })
      .catch((e) => {
        handleSnackbar("Error al guardar la unidad académica", "error");
      });
  };

  useEffect(() => {
    if (selectedUnidad && open) {
      refetchCursos();
      setUnidadAcademica({
        id: selectedUnidad.id,
        nombre: selectedUnidad.nombre,
        codigo: selectedUnidad.codigo,
        idCurso: selectedUnidad.cursoIngreso?.id,
      });
    } else {
      setUnidadAcademica();
    }
  }, [selectedUnidad, open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">
                  {selectedUnidad ? `Editar unidad académica` : `Agregar unidad académica`}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox py={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="codigo">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Código
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="codigo"
                    placeholder="Ingrese el código de la unidad académica"
                    type="text"
                    name="codigo"
                    value={unidadAcademica?.codigo || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="nombre"
                    placeholder="Ingrese el nombre de la unidad académica"
                    type="text"
                    name="nombre"
                    value={unidadAcademica?.nombre || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="idCurso">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Curso de ingreso
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="idCurso"
                    value={unidadAcademica?.idCurso || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1}>Sin curso</MenuItem>
                    {cursos?.carreras?.map((curso) => (
                      <MenuItem key={curso.id} value={curso.id}>
                        {curso.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={loadingSave}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="uproGreen"
                  circular
                  disabled={
                    loadingSave ||
                    !unidadAcademica?.nombre ||
                    !unidadAcademica?.codigo ||
                    !unidadAcademica?.idCurso ||
                    (selectedUnidad?.nombre === unidadAcademica?.nombre &&
                      selectedUnidad?.codigo === unidadAcademica?.codigo &&
                      selectedUnidad?.cursoIngreso?.id === unidadAcademica?.idCurso)
                  }
                  onClick={handleSave}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  {loadingSave ? (
                    <CircularProgress
                      size={15}
                      sx={{
                        marginRight: 0.5,
                      }}
                      color="inherit"
                    />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  )}
                  &nbsp;Guardar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalUnidadAcademica.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  selectedUnidad: PropTypes.object,
};
