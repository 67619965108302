// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Autocomplete,
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import SinDatos from "components/Carteles/SinDatos";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { ConfirmarContext } from "context/ConfirmarContext";
import { getUproColor } from "utils/colors";
import { validateEmail, validateUsername } from "utils/validations";
import { UserContext } from "context/user";
import { validateDNI } from "utils/validations";
import dayjs from "dayjs";
import ModalAddPlantilla from "layouts/usuarios/ModalAddPlantilla";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "70%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalDocente({ open, handleClose, refetch, selectedUsuario, tipo }) {
  const [usuario, setUsuario] = useState();
  const [permisosGroup, setPermisosGroup] = useState([]);
  const [expandedPermisos, setExpandedPermisos] = useState([]);
  const [selectedPermisos, setSelectedPermisos] = useState([]);
  const [newTempPassword, setNewTempPassword] = useState();
  const { handleSnackbar } = useContext(MessageManager);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModalAddPlantiilla, setOpenModalAddPlantiilla] = useState(false);
  const [openMenuPlantilla, setOpenMenuPlantilla] = useState(false);
  const { confirmar } = useContext(ConfirmarContext);
  const [administrarPlantillas, setAdministrarPlantillas] = useState(false);
  const { verificarAcceso } = useContext(UserContext);

  const handleChanges = (e) => {
    setUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  const { data: permisos } = useQuery(
    gql`
      query getPermisos($filter: JSON) {
        permisos(filter: $filter) {
          id
          nombre
          pantalla
        }
      }
    `,
    {
      variables: {
        filter: {
          id: { _lt: 100 },
        },
      },
    }
  );

  const { data: paises } = useQuery(
    gql`
      query getPaises {
        paises {
          id
          nombre
          nacionalidad
        }
      }
    `
  );

  const { data: usuarios } = useQuery(
    gql`
      query getUsuarios {
        usuarios {
          id
          username
          email
        }
      }
    `
  );

  const [saveUsuario, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveUser($input: UserInput!) {
        saveUser(input: $input) {
          id
        }
      }
    `
  );

  const [restoreTempPassword] = useMutation(
    gql`
      mutation restoreTempPassword($id: ID!) {
        restoreTempPassword(id: $id) {
          id
          tempPassword
        }
      }
    `
  );

  const [
    getPlantillas,
    { data: plantillas, refetch: refetchPlantillas, loading: loadingPlantillas },
  ] = useLazyQuery(
    gql`
      query getPlantillas($page: Int, $order: Order, $filter: JSON) {
        plantillas(pagination: { page: $page, limit: 50 }, order: $order, filter: $filter) {
          id
          nombre
          user {
            id
            nombre
            apellido
            username
          }
          plantillaDetalles {
            id
            permiso {
              id
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  const [deletePlantilla, { loading: loadingDeletePlantilla }] = useMutation(
    gql`
      mutation deletePlantilla($id: ID!) {
        deletePlantilla(id: $id) {
          id
        }
      }
    `
  );

  const handleOpenPlantillaMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuPlantilla(true);
  };

  const handleClosePlantillaMenu = () => {
    setAnchorEl(null);
    setOpenMenuPlantilla(false);
  };

  const handleApplyPlantilla = (plantilla) => {
    setSelectedPermisos([]);
    let idsPermisos = plantilla.plantillaDetalles.map((detalle) => detalle?.permiso?.id);
    setSelectedPermisos(idsPermisos);
    handleClosePlantillaMenu();
  };

  const handleDeletePlantilla = (plantilla) => {
    confirmar({
      title: "Eliminar plantilla",
      message: "¿Está seguro que desea eliminar esta plantilla?",
      icon: "warning",
      func: () => {
        deletePlantilla({ variables: { id: plantilla.id } })
          .then(() => {
            handleSnackbar("Plantilla eliminada correctamente", "success");
            refetchPlantillas();
          })
          .catch(() => {
            handleSnackbar("Ha ocurrido un error al eliminar la plantilla", "error");
          });
      },
    });
  };

  const renderMenuPlantillas = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(openMenuPlantilla)}
      onClose={handleClosePlantillaMenu}
    >
      {plantillas?.plantillas?.length > 0 ? (
        plantillas?.plantillas?.map((plantilla) => (
          <Tooltip
            key={plantilla.id}
            title={`${administrarPlantillas ? "Eliminar" : ""} ${plantilla.nombre} (${
              plantilla?.plantillaDetalles?.length
            } permisos)`}
            placement="left"
          >
            <MenuItem
              onClick={() => {
                if (administrarPlantillas) handleDeletePlantilla(plantilla);
                else if (selectedPermisos.length > 0) {
                  confirmar({
                    title: "Sobreescribir permisos",
                    message: "Esta acción sobreescrbirá los permisos actuales, ¿desea continuar?",
                    icon: "warning",
                    func: () => {
                      handleApplyPlantilla(plantilla);
                    },
                  });
                } else {
                  handleApplyPlantilla(plantilla);
                }
              }}
              disabled={loadingPlantillas || loadingDeletePlantilla}
            >
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                }}
              >
                <SoftBox display="flex" mr={2}>
                  <SoftBox mr={1}>
                    <SoftTypography variant="p" fontWeight="bold">
                      {plantilla.nombre.length > 20
                        ? `${plantilla.nombre.substring(0, 20)}...`
                        : plantilla.nombre}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox>
                    <SoftTypography
                      variant="caption"
                      color="uproGreen"
                    >{`${plantilla?.plantillaDetalles?.length} permisos`}</SoftTypography>
                  </SoftBox>
                </SoftBox>
                <SoftBox>
                  <Icon
                    fontSize="small"
                    sx={{
                      verticalAlign: "middle",
                      color: administrarPlantillas
                        ? getUproColor("uproPink")
                        : getUproColor("uproGreen"),
                    }}
                  >
                    {administrarPlantillas ? "delete" : "playlist_add"}
                  </Icon>
                </SoftBox>
              </SoftBox>
            </MenuItem>
          </Tooltip>
        ))
      ) : (
        <SinDatos />
      )}
      {plantillas?.plantillas?.length > 0 && (
        <MenuItem
          onClick={() => setAdministrarPlantillas(!administrarPlantillas)}
          disabled={loadingPlantillas || loadingDeletePlantilla}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SoftBox display="flex" mr={2}>
              <SoftBox mr={1}>
                <SoftTypography variant="p" fontWeight="bold">
                  {administrarPlantillas ? "Cancelar" : "Administrar"}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox>
              <Icon
                fontSize="small"
                sx={{
                  verticalAlign: "middle",
                  color: getUproColor("uproYellow"),
                }}
              >
                {administrarPlantillas ? "arrow_back" : "settings"}
              </Icon>
            </SoftBox>
          </SoftBox>
        </MenuItem>
      )}
    </Menu>
  );

  const handleSave = () => {
    saveUsuario({
      variables: {
        input: {
          ...usuario,
          tipoUser: tipo,
          username: usuario?.username || usuario?.email,
          esDiscapacitado: usuario?.esDiscapacitado === "Si",
          idsPermisos: selectedPermisos.length > 0 ? selectedPermisos : undefined,
        },
      },
    })
      .then(() => {
        refetch();
        handleSnackbar("Usuario guardado correctamente", "success");
        handleClose();
      })
      .catch((e) => {
        handleSnackbar(e.message || "Error al guardar el usuario", "error");
      });
  };

  // Agregar el permiso al arreglo de permisos seleccionados, si ya está, quitarlo
  const handleSelectPermiso = (permiso) => {
    if (selectedPermisos.includes(permiso)) {
      setSelectedPermisos(selectedPermisos.filter((p) => p !== permiso));
    } else {
      setSelectedPermisos([...selectedPermisos, permiso]);
    }
  };

  const handleExpandPantalla = (pantalla) => {
    if (expandedPermisos.includes(pantalla)) {
      setExpandedPermisos(expandedPermisos.filter((p) => p !== pantalla));
    } else {
      setExpandedPermisos([...expandedPermisos, pantalla]);
    }
  };

  const handleResetPassword = () => {
    restoreTempPassword({
      variables: {
        id: selectedUsuario.id,
      },
    })
      .then((data) => {
        refetch();
        handleSnackbar("Contraseña temporal restaurada correctamente", "success");
        setNewTempPassword(data.data.restoreTempPassword.tempPassword);
      })
      .catch((e) => {
        handleSnackbar("Error al restaurar la contraseña temporal", "error");
      });
  };

  useEffect(() => {
    setAdministrarPlantillas(false);
    if (open) getPlantillas({ variables: { page: 1, filter: { tipo: "Permiso" } } });
    if (selectedUsuario) {
      setUsuario({
        id: selectedUsuario.id,
        nombre: selectedUsuario.nombre,
        apellido: selectedUsuario.apellido,
        nroLegajo: selectedUsuario.nroLegajo,
        dni: selectedUsuario.dni,
        fechaNacimiento: selectedUsuario.fechaNacimiento,
        idPais: selectedUsuario.pais?.id,
        genero: selectedUsuario.genero,
        esDiscapacitado: selectedUsuario.esDiscapacitado ? "Si" : "No",
        discapacidad: selectedUsuario.discapacidad,
        idsPermisos: selectedUsuario.idsPermisos,
      });
      if (selectedUsuario.idsPermisos) {
        setSelectedPermisos(selectedUsuario.idsPermisos);
        setNewTempPassword();
        setExpandedPermisos([]);
      } else {
        setSelectedPermisos([]);
        setNewTempPassword();
        setExpandedPermisos([]);
      }
    } else {
      setUsuario();
      setSelectedPermisos([]);
      setExpandedPermisos([]);
      setNewTempPassword();
    }
  }, [selectedUsuario, open]);

  useEffect(() => {
    if (permisos) {
      //Agrupar permisos por pantalla
      let permisosGroup = [];
      permisos.permisos.forEach((permiso) => {
        let permisoGroup = permisosGroup.find((pg) => pg.pantalla === permiso.pantalla);
        if (permisoGroup) {
          permisoGroup.permisos.push(permiso);
        } else {
          permisosGroup.push({
            pantalla: permiso.pantalla,
            permisos: [permiso],
          });
        }
      });
      setPermisosGroup(permisosGroup);
    }
  }, [permisos]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">
                  {selectedUsuario
                    ? `Editar ${tipo.toLowerCase()}`
                    : `Agregar ${tipo.toLowerCase()}`}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox py={3}>
              <Grid container spacing={2}>
                {tipo === "Empleado" && (
                  <Grid item xs={12} sm={4}>
                    <InputLabel htmlFor="username">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Nombre de usuario
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <Tooltip
                      placement="bottom"
                      open={
                        (usuario?.username?.length > 0 && !validateUsername(usuario?.username)) ||
                        (usuario?.username?.length > 0 &&
                          usuarios?.usuarios?.find((u) => u.username === usuario?.username))
                      }
                      title={
                        usuario?.username?.length > 0 && !validateUsername(usuario?.username)
                          ? "El nombre de usuario debe contener al menos 6 caracteres y no debe contener espacios ni caracteres especiales"
                          : usuarios?.usuarios.find((u) => u.username === usuario?.username)
                          ? "El nombre de usuario ya existe"
                          : "Ingrese un nombre de usuario"
                      }
                    >
                      <SoftInput
                        label="username"
                        placeholder="Ingrese el nombre de usuario"
                        type="text"
                        name="username"
                        value={selectedUsuario?.username || usuario?.username || ""}
                        error={
                          usuario?.username?.length > 0 &&
                          (!validateUsername(usuario?.username) ||
                            usuarios?.usuarios?.find((u) => u.username === usuario?.username))
                        }
                        onChange={handleChanges}
                        disabled={selectedUsuario?.username}
                        readOnly={selectedUsuario?.username ? true : false}
                      />
                    </Tooltip>
                  </Grid>
                )}

                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="username">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Email de usuario
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Tooltip
                    placement="bottom"
                    open={
                      (usuario?.email?.length > 0 && !validateEmail(usuario?.email)) ||
                      (usuario?.email?.length > 0 &&
                        usuarios?.usuarios.find((u) => u.email === usuario?.email))
                    }
                    title={
                      usuario?.email?.length > 0 && !validateEmail(usuario?.email)
                        ? "Ingrese un email válido"
                        : usuarios?.usuarios.find((u) => u.email === usuario?.email)
                        ? "El email ya existe"
                        : "Ingrese un email"
                    }
                  >
                    <SoftInput
                      label="email"
                      placeholder="Ingrese el email de usuario"
                      type="text"
                      name="email"
                      value={selectedUsuario?.email || usuario?.email || ""}
                      error={
                        usuario?.email?.length > 0 &&
                        (!validateEmail(usuario?.email) ||
                          usuarios?.usuarios.find((u) => u.email === usuario?.email))
                      }
                      onChange={handleChanges}
                      disabled={selectedUsuario?.email}
                      readOnly={selectedUsuario?.username ? true : false}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="nroLegajo">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nº de Legajo
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="nroLegajo"
                    placeholder="Ingrese el número de legajo"
                    type="text"
                    name="nroLegajo"
                    value={usuario?.nroLegajo || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="nombre"
                    placeholder="Ingrese el nombre del usuario"
                    type="text"
                    name="nombre"
                    value={usuario?.nombre || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="apellido">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Apellido
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="apellido"
                    placeholder="Ingrese el apellido del usuario"
                    type="text"
                    name="apellido"
                    value={usuario?.apellido || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="dni">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      DNI
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="dni"
                    placeholder="Ingrese el dni del usuario"
                    type="text"
                    name="dni"
                    value={usuario?.dni || ""}
                    error={usuario?.dni?.length > 0 && !validateDNI(usuario?.dni)}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={tipo === "Empleado" ? 3 : 4}>
                  <InputLabel htmlFor="fechaNacimiento">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Fecha de nacimiento
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="fechaNacimiento"
                    type="date"
                    name="fechaNacimiento"
                    value={usuario?.fechaNacimiento || ""}
                    error={
                      usuario?.fechaNacimiento &&
                      dayjs(usuario?.fechaNacimiento).isAfter(dayjs().subtract(17, "year"))
                    }
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={tipo === "Empleado" ? 3 : 4}>
                  <InputLabel htmlFor="nacionalidad">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nacionalidad
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="idPais"
                    value={usuario?.idPais || usuario?.pais?.id || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    {paises?.paises?.map((pais) => (
                      <MenuItem key={pais.id} value={pais.id}>
                        {pais.nacionalidad}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={tipo === "Empleado" ? 3 : 4}>
                  <InputLabel htmlFor="genero">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Genero
                    </SoftTypography>
                  </InputLabel>
                  <Select name="genero" value={usuario?.genero || -1} onChange={handleChanges}>
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    <MenuItem value={"Masculino"}>Masculino</MenuItem>
                    <MenuItem value={"Femenino"}>Femenino</MenuItem>
                    <MenuItem value={"Otro"}>Otro</MenuItem>
                    <MenuItem value={"Prefiero no decirlo"}>Prefiero no decirlo</MenuItem>
                  </Select>
                </Grid>
                {tipo === "Alumno" && (
                  <Grid item xs={12} sm={4}>
                    <InputLabel htmlFor="esDiscapacitado">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Posee discapacidad
                      </SoftTypography>
                    </InputLabel>
                    <Select
                      name="esDiscapacitado"
                      value={usuario?.esDiscapacitado || -1}
                      onChange={handleChanges}
                    >
                      <MenuItem value={-1} disabled>
                        Seleccione uno
                      </MenuItem>
                      <MenuItem value={"Si"}>Si</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Select>
                  </Grid>
                )}
                {usuario?.esDiscapacitado === "Si" && (
                  <Grid item xs={12}>
                    <InputLabel htmlFor="discapacidad">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Discapacidad
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="discapacidad"
                      placeholder="Detalle la discapacidad"
                      type="text"
                      name="discapacidad"
                      value={usuario?.discapacidad || ""}
                      onChange={handleChanges}
                    />
                  </Grid>
                )}
                {tipo === "Gestión Académica" && verificarAcceso(20) && (
                  <Grid item xs={12}>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      py={2}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftTypography variant="h6">Permisos</SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="end" alignItems="center">
                        <SoftBox mr={2}>
                          <Tooltip title="Plantillas" placement="top">
                            <SoftButton
                              circular
                              color="uproGreen"
                              onClick={handleOpenPlantillaMenu}
                              disabled={loadingPlantillas || loadingDeletePlantilla}
                            >
                              {(loadingPlantillas || loadingDeletePlantilla) && (
                                <CircularProgress
                                  size={15}
                                  sx={{
                                    marginRight: 0.5,
                                  }}
                                  color="inherit"
                                />
                              )}
                              {!loadingPlantillas && !loadingDeletePlantilla && <Icon>sort</Icon>}
                              &nbsp;Plantillas
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                        {renderMenuPlantillas}
                        <SoftBox>
                          <Tooltip title="Guardar como plantilla" placement="top">
                            <SoftButton
                              circular
                              iconOnly
                              color="primary"
                              disabled={selectedPermisos.length === 0}
                              onClick={() => setOpenModalAddPlantiilla(true)}
                            >
                              <Icon>save</Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <Card>
                      <SoftBox
                        sx={{
                          maxHeight: "30vh",
                          overflowY: "auto",
                        }}
                      >
                        <SoftBox
                          p={{
                            xs: 0,
                            sm: 3,
                          }}
                        >
                          {permisosGroup.map((permiso, index) => (
                            <SoftBox key={index}>
                              <MenuItem>
                                <SoftBox
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  sx={{
                                    width: "100%",
                                  }}
                                >
                                  <SoftBox
                                    display="flex"
                                    alignItems="center"
                                    sx={{
                                      width: "100%",
                                    }}
                                    onClick={() => handleExpandPantalla(permiso.pantalla)}
                                  >
                                    <Icon
                                      sx={{
                                        verticalAlign: "middle",
                                        cursor: "pointer",
                                        color: getUproColor("uproPink"),
                                      }}
                                      fontSize="lg"
                                    >
                                      {expandedPermisos.includes(permiso.pantalla)
                                        ? "expand_more"
                                        : "chevron_right"}
                                    </Icon>
                                    <SoftTypography variant="p" fontWeight="bold">
                                      {permiso.pantalla}
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox>
                                    <SoftBadge
                                      color={
                                        permiso.permisos.every((p) =>
                                          selectedPermisos.includes(p.id)
                                        )
                                          ? "uproGreen"
                                          : permiso.permisos.every(
                                              (p) => !selectedPermisos.includes(p.id)
                                            )
                                          ? "secondary"
                                          : "uproYellow"
                                      }
                                      badgeContent={
                                        permiso.permisos.every((p) =>
                                          selectedPermisos.includes(p.id)
                                        )
                                          ? "Acceso total"
                                          : permiso.permisos.every(
                                              (p) => !selectedPermisos.includes(p.id)
                                            )
                                          ? "Sin acceso"
                                          : "Acceso parcial"
                                      }
                                      onClick={() => {
                                        if (!expandedPermisos.includes(permiso.pantalla)) {
                                          setExpandedPermisos([
                                            ...expandedPermisos,
                                            permiso.pantalla,
                                          ]);
                                        }

                                        const newSelectedPermisos = selectedPermisos.filter(
                                          (p) => !permiso.permisos.map((p) => p.id).includes(p)
                                        );

                                        if (
                                          permiso.permisos.every((p) =>
                                            selectedPermisos.includes(p.id)
                                          )
                                        ) {
                                          setSelectedPermisos(newSelectedPermisos);
                                        } else {
                                          setSelectedPermisos([
                                            ...newSelectedPermisos,
                                            ...permiso.permisos.map((p) => p.id),
                                          ]);
                                        }
                                      }}
                                    />
                                  </SoftBox>
                                </SoftBox>
                              </MenuItem>
                              {expandedPermisos.includes(permiso.pantalla) &&
                                permiso.permisos.map((p, index) => (
                                  <MenuItem key={index}>
                                    <SoftBox
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      ml={{
                                        xs: 0,
                                        sm: 3,
                                      }}
                                      sx={{
                                        width: "100%",
                                      }}
                                    >
                                      <SoftBox mr={2} onClick={() => handleSelectPermiso(p.id)}>
                                        <SoftTypography variant="p">{p.nombre}</SoftTypography>
                                      </SoftBox>
                                      <SoftBox
                                        sx={{
                                          display: {
                                            xs: "none",
                                            sm: "block",
                                          },
                                        }}
                                      >
                                        <SoftBadge
                                          color={
                                            selectedPermisos.includes(p.id) ? "dark" : "secondary"
                                          }
                                          badgeContent={
                                            selectedPermisos.includes(p.id)
                                              ? "Acceso"
                                              : "Sin acceso"
                                          }
                                          onClick={() => handleSelectPermiso(p.id)}
                                        />
                                      </SoftBox>
                                      <SoftBox
                                        sx={{
                                          display: {
                                            xs: "block",
                                            sm: "none",
                                          },
                                        }}
                                      >
                                        <Icon
                                          color={
                                            selectedPermisos.includes(p.id)
                                              ? "success"
                                              : "secondary"
                                          }
                                          onClick={() => handleSelectPermiso(p.id)}
                                          fontSize="small"
                                        >
                                          {selectedPermisos.includes(p.id) ? "check" : "close"}
                                        </Icon>
                                      </SoftBox>
                                    </SoftBox>
                                  </MenuItem>
                                ))}
                            </SoftBox>
                          ))}
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="space-between"
              alignItems="center"
            >
              <SoftBox>
                {verificarAcceso(21) &&
                  selectedUsuario &&
                  (selectedUsuario?.tempPassword || newTempPassword) &&
                  selectedUsuario?.tipoUser !== "Administrador" && (
                    <Tooltip title="Copiar contraseña temporal" placement="top">
                      <SoftButton
                        color="uproYellow"
                        circular
                        onClick={() => {
                          if (newTempPassword) {
                            navigator.clipboard.writeText(newTempPassword);
                          } else if (selectedUsuario.tempPassword) {
                            navigator.clipboard.writeText(selectedUsuario.tempPassword);
                          }
                          handleSnackbar("Contraseña temporal copiada", "success");
                        }}
                        fullWidth={{
                          xs: true,
                          sm: false,
                        }}
                      >
                        <Icon sx={{ fontWeight: "light" }}>copy</Icon>
                        &nbsp;Contraseña temporal
                      </SoftButton>
                    </Tooltip>
                  )}
                {verificarAcceso(21) &&
                  selectedUsuario &&
                  !selectedUsuario?.tempPassword &&
                  !newTempPassword &&
                  selectedUsuario?.tipoUser !== "Administrador" && (
                    <Tooltip title="Generar contraseña temporal nueva" placement="top">
                      <SoftButton
                        color="uproGreen"
                        circular
                        onClick={handleResetPassword}
                        fullWidth={{
                          xs: true,
                          sm: false,
                        }}
                      >
                        <Icon sx={{ fontWeight: "light" }}>vpn_key</Icon>
                        &nbsp;Reiniciar contraseña
                      </SoftButton>
                    </Tooltip>
                  )}
              </SoftBox>
              <SoftBox
                display={{
                  xs: "flex-row",
                  sm: "flex",
                }}
                justifyContent="end"
                alignItems="center"
              >
                <SoftBox
                  mr={{
                    xs: 0,
                    sm: 2,
                  }}
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="primary"
                    circular
                    onClick={handleClose}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                    &nbsp;Cancelar
                  </SoftButton>
                </SoftBox>
                <SoftBox
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="uproGreen"
                    circular
                    disabled={
                      loadingSave ||
                      (selectedUsuario
                        ? !usuario?.nombre ||
                          !usuario?.apellido ||
                          (usuario?.esDiscapacitado === "Si" && !usuario?.discapacidad) ||
                          (usuario?.nombre === selectedUsuario.nombre &&
                            usuario?.apellido === selectedUsuario.apellido &&
                            selectedPermisos === selectedUsuario.idsPermisos &&
                            usuario?.nroLegajo === selectedUsuario.nroLegajo &&
                            usuario?.dni === selectedUsuario.dni &&
                            usuario?.fechaNacimiento === selectedUsuario.fechaNacimiento &&
                            usuario?.idPais === selectedUsuario.pais?.id &&
                            usuario?.genero === selectedUsuario.genero &&
                            usuario?.esDiscapacitado ===
                              (selectedUsuario.esDiscapacitado ? "Si" : "No") &&
                            usuario?.discapacidad === selectedUsuario.discapacidad)
                        : !usuario?.email ||
                          (tipo === "Empleado" && !usuario?.username) ||
                          !usuario?.nombre ||
                          !usuario?.apellido ||
                          !usuario?.dni ||
                          (usuario?.esDiscapacitado === "Si" && !usuario?.discapacidad) ||
                          (usuario?.dni && !validateDNI(usuario?.dni)) ||
                          (usuario?.fechaNacimiento &&
                            dayjs(usuario?.fechaNacimiento).isAfter(
                              dayjs().subtract(17, "year")
                            )) ||
                          !validateEmail(usuario?.email) ||
                          (tipo === "Empleado" && !validateUsername(usuario?.username)) ||
                          usuarios?.usuarios.find((u) => u.username === usuario?.username) ||
                          usuarios?.usuarios.find((u) => u.email === usuario?.email))
                    }
                    onClick={handleSave}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    {loadingSave ? (
                      <CircularProgress
                        size={15}
                        sx={{
                          marginRight: 0.5,
                        }}
                        color="inherit"
                      />
                    ) : (
                      <Icon sx={{ fontWeight: "light" }}>save</Icon>
                    )}
                    &nbsp;Guardar
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <ModalAddPlantilla
              open={openModalAddPlantiilla}
              handleClose={() => setOpenModalAddPlantiilla(false)}
              permisos={selectedPermisos}
              refetch={refetchPlantillas}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalDocente.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  selectedUsuario: PropTypes.object,
  tipo: PropTypes.string,
};
