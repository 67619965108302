// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Autocomplete,
  Card,
  CircularProgress,
  Collapse,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import { getUproColor } from "utils/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "70%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalMateria({ open, handleClose, selectedMateria, idNivel, refetch }) {
  const [materia, setMateria] = useState({
    aprobablePorResolucion: 0,
    promocionable: 0,
    permiteRendirLibre: 0,
    promediable: 0,
    requiereCursada: 0,
    requiereExamen: 0,
  });
  const { handleSnackbar } = useContext(MessageManager);
  const [expanded, setExpanded] = useState(["Datos generales"]);

  const handleChanges = (e) => {
    setMateria({
      ...materia,
      [e.target.name]: e.target.value,
    });
  };

  const [saveMateria, { loading }] = useMutation(
    gql`
      mutation saveMateria($idNivel: ID!, $input: MateriaInput!) {
        saveMateria(idNivel: $idNivel, input: $input) {
          id
        }
      }
    `
  );

  const [getMateria, { loading: loadingMateria, data: dataMateria }] = useLazyQuery(
    gql`
      query getMateria($id: ID!) {
        materia(id: $id) {
          id
          nivel {
            id
            planEstudio {
              id
            }
          }
          codigo
          nombre
          descripcion
          docentesTitulares {
            id
          }
          docentesAsociados {
            id
          }
          docentesAdjuntos {
            id
          }
          jefesTrabajosPracticos {
            id
          }
          ayudantesPrimera {
            id
          }
          ayudantesSegunda {
            id
          }
          correlativasRegularesCursar {
            id
          }
          correlativasAprobadasCursar {
            id
          }
          correlativasRegularesRendir {
            id
          }
          correlativasAprobadasRendir {
            id
          }
          aprobablePorResolucion
          promocionable
          permiteRendirLibre
          promediable
          requiereCursada
          requiereExamen
          cantidadAplazos
          horasTotales
          horasSemanales
          escalaRegularidad
          escalaPromocion
          escalaEquivalencias
          escalaExamen
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      cachePolicy: "no-cache",
    }
  );

  const [getCorrelativas, { data: materias }] = useLazyQuery(
    gql`
      query getMaterias($filter: JSON, $idNivel: ID) {
        materias(filter: $filter, idNivel: $idNivel) {
          id
          codigo
          nombre
        }
      }
    `
  );

  const [getDocentes, { data: docentes }] = useLazyQuery(
    gql`
      query getUsuarios($search: String, $order: Order) {
        paginationInfo {
          pages
          total
        }
        usuarios(search: $search, order: $order, filter: { tipoUser: "Docente" }) {
          id
          nombre
          apellido
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (selectedMateria) {
      getMateria({
        variables: {
          id: selectedMateria.id,
        },
      });
      getCorrelativas({
        variables: {
          filter: {
            id: {
              _not: selectedMateria.id,
            },
          },
          idNivel: idNivel,
        },
      });
      getDocentes();
    } else {
      setMateria({
        aprobablePorResolucion: 0,
        promocionable: 0,
        permiteRendirLibre: 0,
        promediable: 0,
        requiereCursada: 0,
        requiereExamen: 0,
      });
    }
  }, [open]);

  useEffect(() => {
    if (dataMateria?.materia && selectedMateria?.id) {
      setMateria({
        id: dataMateria?.materia.id,
        codigo: dataMateria?.materia.codigo,
        nombre: dataMateria?.materia.nombre,
        descripcion: dataMateria?.materia.descripcion,
        idsDocentesTitulares:
          dataMateria?.materia.docentesTitulares?.map((docente) => docente.id) || [],
        idsDocentesAsociados:
          dataMateria?.materia.docentesAsociados?.map((docente) => docente.id) || [],
        idsDocentesAdjuntos:
          dataMateria?.materia.docentesAdjuntos?.map((docente) => docente.id) || [],
        idsJefesTrabajosPracticos:
          dataMateria?.materia.jefesTrabajosPracticos?.map((docente) => docente.id) || [],
        idsAyudantesPrimera:
          dataMateria?.materia.ayudantesPrimera?.map((docente) => docente.id) || [],
        idsAyudantesSegunda:
          dataMateria?.materia.ayudantesSegunda?.map((docente) => docente.id) || [],
        idsCorrelativasRegularesCursar:
          dataMateria?.materia.correlativasRegularesCursar?.map((materia) => materia.id) || [],
        idsCorrelativasAprobadasCursar:
          dataMateria?.materia.correlativasAprobadasCursar?.map((materia) => materia.id) || [],
        idsCorrelativasRegularesRendir:
          dataMateria?.materia.correlativasRegularesRendir?.map((materia) => materia.id) || [],
        idsCorrelativasAprobadasRendir:
          dataMateria?.materia.correlativasAprobadasRendir?.map((materia) => materia.id) || [],
        aprobablePorResolucion: dataMateria?.materia.aprobablePorResolucion ? 1 : 0,
        promocionable: dataMateria?.materia.promocionable ? 1 : 0,
        permiteRendirLibre: dataMateria?.materia.permiteRendirLibre ? 1 : 0,
        promediable: dataMateria?.materia.promediable ? 1 : 0,
        requiereCursada: dataMateria?.materia.requiereCursada ? 1 : 0,
        requiereExamen: dataMateria?.materia.requiereExamen ? 1 : 0,
        cantidadAplazos: dataMateria?.materia.cantidadAplazos,
        horasTotales: dataMateria?.materia.horasTotales,
        horasSemanales: dataMateria?.materia.horasSemanales,
        escalaRegularidad: dataMateria?.materia.escalaRegularidad,
        escalaPromocion: dataMateria?.materia.escalaPromocion,
        escalaEquivalencias: dataMateria?.materia.escalaEquivalencias,
        escalaExamen: dataMateria?.materia.escalaExamen,
      });
    }
  }, [dataMateria]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">
                  {selectedMateria
                    ? `Editar Unidad de Competencia`
                    : `Agregar Unidad de Competencia`}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox py={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: !expanded.includes("Datos generales")
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                      onClick={(e) => {
                        setExpanded(
                          expanded.includes("Datos generales")
                            ? expanded.filter((i) => i !== "Datos generales")
                            : [...expanded, "Datos generales"]
                        );
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon color="primary">description</Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          Datos generales
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                        <SoftBox>
                          <Tooltip
                            title={expanded.includes("Datos generales") ? "Ocultar" : "Mostrar"}
                            placement="top"
                          >
                            <SoftButton
                              color="primary"
                              circular
                              iconOnly
                              onClick={() =>
                                setExpanded(
                                  expanded.includes("Datos generales")
                                    ? expanded.filter((i) => i !== "Datos generales")
                                    : [...expanded, "Datos generales"]
                                )
                              }
                            >
                              <Icon
                                sx={{
                                  rotate: expanded.includes("Datos generales") ? "180deg" : "0deg",
                                  transition: "all 0.5s",
                                }}
                              >
                                expand_more
                              </Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={expanded.includes("Datos generales")}>
                      <Grid container spacing={2} p={2}>
                        <Grid item xs={12} sm={4}>
                          <InputLabel htmlFor="codigo">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Código
                              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                                &nbsp;*
                              </SoftTypography>
                            </SoftTypography>
                          </InputLabel>
                          <SoftInput
                            label="codigo"
                            placeholder="Ingrese el código de la Unidad de Competencia"
                            type="text"
                            name="codigo"
                            value={materia?.codigo || ""}
                            onChange={handleChanges}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <InputLabel htmlFor="nombre">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Nombre
                              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                                &nbsp;*
                              </SoftTypography>
                            </SoftTypography>
                          </InputLabel>
                          <SoftInput
                            label="nombre"
                            placeholder="Ingrese el nombre de la Unidad de Competencia"
                            type="text"
                            name="nombre"
                            value={materia?.nombre || ""}
                            onChange={handleChanges}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <InputLabel htmlFor="descripcion">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Descripción
                            </SoftTypography>
                          </InputLabel>
                          <SoftInput
                            label="descripcion"
                            placeholder="Ingrese la descripcion de la Unidad de Competencia"
                            type="text"
                            multiline
                            rows={4}
                            name="descripcion"
                            value={materia?.descripcion || ""}
                            onChange={handleChanges}
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: !expanded.includes("Docentes")
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                      onClick={(e) => {
                        setExpanded(
                          expanded.includes("Docentes")
                            ? expanded.filter((i) => i !== "Docentes")
                            : [...expanded, "Docentes"]
                        );
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon color="primary">group</Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          Docentes
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                        <SoftBox>
                          <Tooltip
                            title={expanded.includes("Docentes") ? "Ocultar" : "Mostrar"}
                            placement="top"
                          >
                            <SoftButton
                              color="primary"
                              circular
                              iconOnly
                              onClick={() =>
                                setExpanded(
                                  expanded.includes("Docentes")
                                    ? expanded.filter((i) => i !== "Docentes")
                                    : [...expanded, "Docentes"]
                                )
                              }
                            >
                              <Icon
                                sx={{
                                  rotate: expanded.includes("Docentes") ? "180deg" : "0deg",
                                  transition: "all 0.5s",
                                }}
                              >
                                expand_more
                              </Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={expanded.includes("Docentes")}>
                      <Grid container spacing={2} p={2}>
                        <Grid item xs={12} sm={4}>
                          <InputLabel htmlFor="docentesTitulares">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Docentes titulares
                            </SoftTypography>
                          </InputLabel>
                          <Autocomplete
                            multiple
                            noOptionsText="No hay docentes"
                            id="tags-outlined"
                            options={docentes?.usuarios.map((docente) => docente.id) || []}
                            getOptionLabel={(option) => {
                              let docente = docentes?.usuarios.find((s) => s.id == option);
                              return `${docente?.nombre} ${docente?.apellido}`;
                            }}
                            filterSelectedOptions
                            value={materia?.idsDocentesTitulares || []}
                            onChange={(e, value) => {
                              setMateria({
                                ...materia,
                                idsDocentesTitulares: value,
                              });
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                            sx={{
                              ".MuiAutocomplete-inputRoot": {
                                padding: `0 !important`,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <InputLabel htmlFor="docentesAsociados">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Docentes asociados
                            </SoftTypography>
                          </InputLabel>
                          <Autocomplete
                            multiple
                            noOptionsText="No hay docentes"
                            id="tags-outlined"
                            options={docentes?.usuarios.map((docente) => docente.id) || []}
                            getOptionLabel={(option) => {
                              let docente = docentes?.usuarios.find((s) => s.id == option);
                              return `${docente?.nombre} ${docente?.apellido}`;
                            }}
                            filterSelectedOptions
                            value={materia?.idsDocentesAsociados || []}
                            onChange={(e, value) => {
                              setMateria({
                                ...materia,
                                idsDocentesAsociados: value,
                              });
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                            sx={{
                              ".MuiAutocomplete-inputRoot": {
                                padding: `0 !important`,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <InputLabel htmlFor="docentesAdjuntos">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Docentes adjuntos
                            </SoftTypography>
                          </InputLabel>
                          <Autocomplete
                            multiple
                            noOptionsText="No hay docentes"
                            id="tags-outlined"
                            options={docentes?.usuarios.map((docente) => docente.id) || []}
                            getOptionLabel={(option) => {
                              let docente = docentes?.usuarios.find((s) => s.id == option);
                              return `${docente?.nombre} ${docente?.apellido}`;
                            }}
                            filterSelectedOptions
                            value={materia?.idsDocentesAdjuntos || []}
                            onChange={(e, value) => {
                              setMateria({
                                ...materia,
                                idsDocentesAdjuntos: value,
                              });
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                            sx={{
                              ".MuiAutocomplete-inputRoot": {
                                padding: `0 !important`,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <InputLabel htmlFor="jefesDeTrabajosPracticos">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Jefes de Trabajos Prácticos
                            </SoftTypography>
                          </InputLabel>
                          <Autocomplete
                            multiple
                            noOptionsText="No hay docentes"
                            id="tags-outlined"
                            options={docentes?.usuarios.map((docente) => docente.id) || []}
                            getOptionLabel={(option) => {
                              let docente = docentes?.usuarios.find((s) => s.id == option);
                              return `${docente?.nombre} ${docente?.apellido}`;
                            }}
                            filterSelectedOptions
                            value={materia?.idsJefesTrabajosPracticos || []}
                            onChange={(e, value) => {
                              setMateria({
                                ...materia,
                                idsJefesTrabajosPracticos: value,
                              });
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                            sx={{
                              ".MuiAutocomplete-inputRoot": {
                                padding: `0 !important`,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <InputLabel htmlFor="ayudantesPrimera">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Ayudantes de primera
                            </SoftTypography>
                          </InputLabel>
                          <Autocomplete
                            multiple
                            noOptionsText="No hay docentes"
                            id="tags-outlined"
                            options={docentes?.usuarios.map((docente) => docente.id) || []}
                            getOptionLabel={(option) => {
                              let docente = docentes?.usuarios.find((s) => s.id == option);
                              return `${docente?.nombre} ${docente?.apellido}`;
                            }}
                            filterSelectedOptions
                            value={materia?.idsAyudantesPrimera || []}
                            onChange={(e, value) => {
                              setMateria({
                                ...materia,
                                idsAyudantesPrimera: value,
                              });
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                            sx={{
                              ".MuiAutocomplete-inputRoot": {
                                padding: `0 !important`,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <InputLabel htmlFor="ayudantesSegunda">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Ayudantes de segunda
                            </SoftTypography>
                          </InputLabel>
                          <Autocomplete
                            multiple
                            noOptionsText="No hay docentes"
                            id="tags-outlined"
                            options={docentes?.usuarios.map((docente) => docente.id) || []}
                            getOptionLabel={(option) => {
                              let docente = docentes?.usuarios.find((s) => s.id == option);
                              return `${docente?.nombre} ${docente?.apellido}`;
                            }}
                            filterSelectedOptions
                            value={materia?.idsAyudantesSegunda || []}
                            onChange={(e, value) => {
                              setMateria({
                                ...materia,
                                idsAyudantesSegunda: value,
                              });
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                            sx={{
                              ".MuiAutocomplete-inputRoot": {
                                padding: `0 !important`,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: !expanded.includes("Datos académicos")
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                      onClick={(e) => {
                        setExpanded(
                          expanded.includes("Datos académicos")
                            ? expanded.filter((i) => i !== "Datos académicos")
                            : [...expanded, "Datos académicos"]
                        );
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon color="primary">school</Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          Datos académicos
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                        <SoftBox>
                          <Tooltip
                            title={expanded.includes("Datos académicos") ? "Ocultar" : "Mostrar"}
                            placement="top"
                          >
                            <SoftButton
                              color="primary"
                              circular
                              iconOnly
                              onClick={() =>
                                setExpanded(
                                  expanded.includes("Datos académicos")
                                    ? expanded.filter((i) => i !== "Datos académicos")
                                    : [...expanded, "Datos académicos"]
                                )
                              }
                            >
                              <Icon
                                sx={{
                                  rotate: expanded.includes("Datos académicos") ? "180deg" : "0deg",
                                  transition: "all 0.5s",
                                }}
                              >
                                expand_more
                              </Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={expanded.includes("Datos académicos")}>
                      <Grid container spacing={2} p={2}>
                        <Grid item container xs={12} sm={4} spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor="aprobablePorResolucion">
                              <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Aprobable por resolución
                              </SoftTypography>
                            </InputLabel>
                            <Tabs
                              value={materia?.aprobablePorResolucion || 0}
                              name="aprobablePorResolucion"
                              onChange={(e, value) => {
                                setMateria({
                                  ...materia,
                                  aprobablePorResolucion: value,
                                });
                              }}
                            >
                              <Tab
                                label={
                                  <SoftTypography
                                    variant="h6"
                                    fontWeight={
                                      materia?.aprobablePorResolucion === 0 ? "bold" : "regular"
                                    }
                                  >
                                    No
                                  </SoftTypography>
                                }
                                value={0}
                              />
                              <Tab
                                label={
                                  <SoftTypography
                                    variant="h6"
                                    fontWeight={
                                      materia?.aprobablePorResolucion === 1 ? "bold" : "regular"
                                    }
                                  >
                                    Si
                                  </SoftTypography>
                                }
                                value={1}
                              />
                            </Tabs>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor="promocionable">
                              <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Promocionable
                              </SoftTypography>
                            </InputLabel>
                            <Tabs
                              value={materia?.promocionable || 0}
                              name="promocionable"
                              onChange={(e, value) => {
                                setMateria({
                                  ...materia,
                                  promocionable: value,
                                });
                              }}
                            >
                              <Tab
                                label={
                                  <SoftTypography
                                    variant="h6"
                                    fontWeight={materia?.promocionable === 0 ? "bold" : "regular"}
                                  >
                                    No
                                  </SoftTypography>
                                }
                                value={0}
                              />
                              <Tab
                                label={
                                  <SoftTypography
                                    variant="h6"
                                    fontWeight={materia?.promocionable === 1 ? "bold" : "regular"}
                                  >
                                    Si
                                  </SoftTypography>
                                }
                                value={1}
                              />
                            </Tabs>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor="permiteRendirLibre">
                              <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Permite rendir libre
                              </SoftTypography>
                            </InputLabel>
                            <Tabs
                              value={materia?.permiteRendirLibre || 0}
                              name="permiteRendirLibre"
                              onChange={(e, value) => {
                                setMateria({
                                  ...materia,
                                  permiteRendirLibre: value,
                                });
                              }}
                            >
                              <Tab
                                label={
                                  <SoftTypography
                                    variant="h6"
                                    fontWeight={
                                      materia?.permiteRendirLibre === 0 ? "bold" : "regular"
                                    }
                                  >
                                    No
                                  </SoftTypography>
                                }
                                value={0}
                              />
                              <Tab
                                label={
                                  <SoftTypography
                                    variant="h6"
                                    fontWeight={
                                      materia?.permiteRendirLibre === 1 ? "bold" : "regular"
                                    }
                                  >
                                    Si
                                  </SoftTypography>
                                }
                                value={1}
                              />
                            </Tabs>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor="promediable">
                              <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Promediable
                              </SoftTypography>
                            </InputLabel>
                            <Tabs
                              value={materia?.promediable || 0}
                              name="promediable"
                              onChange={(e, value) => {
                                setMateria({
                                  ...materia,
                                  promediable: value,
                                });
                              }}
                            >
                              <Tab
                                label={
                                  <SoftTypography
                                    variant="h6"
                                    fontWeight={materia?.promediable === 0 ? "bold" : "regular"}
                                  >
                                    No
                                  </SoftTypography>
                                }
                                value={0}
                              />
                              <Tab
                                label={
                                  <SoftTypography
                                    variant="h6"
                                    fontWeight={materia?.promediable === 1 ? "bold" : "regular"}
                                  >
                                    Si
                                  </SoftTypography>
                                }
                                value={1}
                              />
                            </Tabs>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor="requiereCursada">
                              <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Requiere cursada
                              </SoftTypography>
                            </InputLabel>
                            <Tabs
                              value={materia?.requiereCursada || 0}
                              name="requiereCursada"
                              onChange={(e, value) => {
                                setMateria({
                                  ...materia,
                                  requiereCursada: value,
                                });
                              }}
                            >
                              <Tab
                                label={
                                  <SoftTypography
                                    variant="h6"
                                    fontWeight={materia?.requiereCursada === 0 ? "bold" : "regular"}
                                  >
                                    No
                                  </SoftTypography>
                                }
                                value={0}
                              />
                              <Tab
                                label={
                                  <SoftTypography
                                    variant="h6"
                                    fontWeight={materia?.requiereCursada === 1 ? "bold" : "regular"}
                                  >
                                    Si
                                  </SoftTypography>
                                }
                                value={1}
                              />
                            </Tabs>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor="requiereExamen">
                              <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Requiere examen
                              </SoftTypography>
                            </InputLabel>
                            <Tabs
                              value={materia?.requiereExamen || 0}
                              name="requiereExamen"
                              onChange={(e, value) => {
                                setMateria({
                                  ...materia,
                                  requiereExamen: value,
                                });
                              }}
                            >
                              <Tab
                                label={
                                  <SoftTypography
                                    variant="h6"
                                    fontWeight={materia?.requiereExamen === 0 ? "bold" : "regular"}
                                  >
                                    No
                                  </SoftTypography>
                                }
                                value={0}
                              />
                              <Tab
                                label={
                                  <SoftTypography
                                    variant="h6"
                                    fontWeight={materia?.requiereExamen === 1 ? "bold" : "regular"}
                                  >
                                    Si
                                  </SoftTypography>
                                }
                                value={1}
                              />
                            </Tabs>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={8} spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <InputLabel htmlFor="cantidadAplazos">
                              <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Cantidad de aplazos
                              </SoftTypography>
                            </InputLabel>
                            <SoftInputNumber
                              label="cantidadAplazos"
                              placeholder="Ingrese la cantidad de aplazos"
                              name="cantidadAplazos"
                              value={materia?.cantidadAplazos || ""}
                              onChange={handleChanges}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <InputLabel htmlFor="horasTotales">
                              <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Horas totales
                              </SoftTypography>
                            </InputLabel>
                            <SoftInputNumber
                              label="horasTotales"
                              placeholder="Ingrese la cantidad de horas totales"
                              name="horasTotales"
                              value={materia?.horasTotales || ""}
                              onChange={handleChanges}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <InputLabel htmlFor="horasSemanales">
                              <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Horas semanalas
                              </SoftTypography>
                            </InputLabel>
                            <SoftInputNumber
                              label="horasSemanales"
                              placeholder="Ingrese la cantidad de horas semanales"
                              name="horasSemanales"
                              value={materia?.horasSemanales || ""}
                              onChange={handleChanges}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor="escalaRegularidad">
                              <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Escala de regularidad
                              </SoftTypography>
                            </InputLabel>
                            <Select
                              name="escalaRegularidad"
                              value={materia?.escalaRegularidad || -1}
                              onChange={handleChanges}
                            >
                              <MenuItem value={-1}>Seleccionar</MenuItem>
                              <MenuItem value={"0 a 10 con 2 decimales"}>
                                0 a 10 con 2 decimales
                              </MenuItem>
                              <MenuItem value={"Equivalencia"}>Equivalencia</MenuItem>
                              <MenuItem value={"Promoción"}>Promoción</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor="escalaPromocion">
                              <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Escala de promoción
                              </SoftTypography>
                            </InputLabel>
                            <Select
                              name="escalaPromocion"
                              value={materia?.escalaPromocion || -1}
                              onChange={handleChanges}
                            >
                              <MenuItem value={-1}>Seleccionar</MenuItem>
                              <MenuItem value={"0 a 10 con 2 decimales"}>
                                0 a 10 con 2 decimales
                              </MenuItem>
                              <MenuItem value={"Equivalencia"}>Equivalencia</MenuItem>
                              <MenuItem value={"Promoción"}>Promoción</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor="escalaEquivalencias">
                              <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Escala de equivalencias
                              </SoftTypography>
                            </InputLabel>
                            <Select
                              name="escalaEquivalencias"
                              value={materia?.escalaEquivalencias || -1}
                              onChange={handleChanges}
                            >
                              <MenuItem value={-1}>Seleccionar</MenuItem>
                              <MenuItem value={"0 a 10 con 2 decimales"}>
                                0 a 10 con 2 decimales
                              </MenuItem>
                              <MenuItem value={"Equivalencia"}>Equivalencia</MenuItem>
                              <MenuItem value={"Promoción"}>Promoción</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor="escalaExamen">
                              <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Escala de examen
                              </SoftTypography>
                            </InputLabel>
                            <Select
                              name="escalaExamen"
                              value={materia?.escalaExamen || -1}
                              onChange={handleChanges}
                            >
                              <MenuItem value={-1}>Seleccionar</MenuItem>
                              <MenuItem value={"0 a 10 con 2 decimales"}>
                                0 a 10 con 2 decimales
                              </MenuItem>
                              <MenuItem value={"Equivalencia"}>Equivalencia</MenuItem>
                              <MenuItem value={"Promoción"}>Promoción</MenuItem>
                            </Select>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: !expanded.includes("Correlativas")
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                      onClick={(e) => {
                        setExpanded(
                          expanded.includes("Correlativas")
                            ? expanded.filter((i) => i !== "Correlativas")
                            : [...expanded, "Correlativas"]
                        );
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon color="primary">history_edu</Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          Correlativas
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                        <SoftBox>
                          <Tooltip
                            title={expanded.includes("Correlativas") ? "Ocultar" : "Mostrar"}
                            placement="top"
                          >
                            <SoftButton
                              color="primary"
                              circular
                              iconOnly
                              onClick={() =>
                                setExpanded(
                                  expanded.includes("Correlativas")
                                    ? expanded.filter((i) => i !== "Correlativas")
                                    : [...expanded, "Correlativas"]
                                )
                              }
                            >
                              <Icon
                                sx={{
                                  rotate: expanded.includes("Correlativas") ? "180deg" : "0deg",
                                  transition: "all 0.5s",
                                }}
                              >
                                expand_more
                              </Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={expanded.includes("Correlativas")}>
                      <Grid container spacing={2} p={2}>
                        <Grid item xs={12} sm={6}>
                          <InputLabel htmlFor="correlativasRegularesCursar">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Correlativas regulares para cursar
                            </SoftTypography>
                          </InputLabel>
                          <Autocomplete
                            multiple
                            id="tags-outlined"
                            noOptionsText="No hay materias"
                            options={materias?.materias.map((materia) => materia.id) || []}
                            getOptionLabel={(option) => {
                              let materia = materias?.materias.find((s) => s.id == option);
                              return `${materia?.codigo} - ${materia?.nombre}`;
                            }}
                            filterSelectedOptions
                            value={materia?.idsCorrelativasRegularesCursar || []}
                            onChange={(e, value) => {
                              setMateria({
                                ...materia,
                                idsCorrelativasRegularesCursar: value,
                              });
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                            sx={{
                              ".MuiAutocomplete-inputRoot": {
                                padding: `0 !important`,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputLabel htmlFor="correlativasAprobadasCursar">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Correlativas aprobadas para cursar
                            </SoftTypography>
                          </InputLabel>
                          <Autocomplete
                            multiple
                            id="tags-outlined"
                            noOptionsText="No hay materias"
                            options={materias?.materias.map((materia) => materia.id) || []}
                            getOptionLabel={(option) => {
                              let materia = materias?.materias.find((s) => s.id == option);
                              return `${materia?.codigo} - ${materia?.nombre}`;
                            }}
                            filterSelectedOptions
                            value={materia?.idsCorrelativasAprobadasCursar || []}
                            onChange={(e, value) => {
                              setMateria({
                                ...materia,
                                idsCorrelativasAprobadasCursar: value,
                              });
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                            sx={{
                              ".MuiAutocomplete-inputRoot": {
                                padding: `0 !important`,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputLabel htmlFor="correlativasRegularesRendir">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Correlativas regulares para rendir
                            </SoftTypography>
                          </InputLabel>
                          <Autocomplete
                            multiple
                            id="tags-outlined"
                            noOptionsText="No hay materias"
                            options={materias?.materias.map((materia) => materia.id) || []}
                            getOptionLabel={(option) => {
                              let materia = materias?.materias.find((s) => s.id == option);
                              return `${materia?.codigo} - ${materia?.nombre}`;
                            }}
                            filterSelectedOptions
                            value={materia?.idsCorrelativasRegularesRendir || []}
                            onChange={(e, value) => {
                              setMateria({
                                ...materia,
                                idsCorrelativasRegularesRendir: value,
                              });
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                            sx={{
                              ".MuiAutocomplete-inputRoot": {
                                padding: `0 !important`,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputLabel htmlFor="correlativasAprobadasRendir">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Correlativas aprobadas para rendir
                            </SoftTypography>
                          </InputLabel>
                          <Autocomplete
                            multiple
                            id="tags-outlined"
                            noOptionsText="No hay materias"
                            options={materias?.materias.map((materia) => materia.id) || []}
                            getOptionLabel={(option) => {
                              let materia = materias?.materias.find((s) => s.id == option);
                              return `${materia?.codigo} - ${materia?.nombre}`;
                            }}
                            filterSelectedOptions
                            value={materia?.idsCorrelativasAprobadasRendir || []}
                            onChange={(e, value) => {
                              setMateria({
                                ...materia,
                                idsCorrelativasAprobadasRendir: value,
                              });
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                            sx={{
                              ".MuiAutocomplete-inputRoot": {
                                padding: `0 !important`,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Card>
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={loading}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="uproGreen"
                  circular
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={loading || !materia?.codigo || !materia?.nombre}
                  onClick={() => {
                    saveMateria({
                      variables: {
                        idNivel: idNivel,
                        input: {
                          id: materia?.id || null,
                          codigo: materia?.codigo,
                          nombre: materia?.nombre,
                          descripcion: materia?.descripcion,
                          idsDocentesTitulares: materia?.idsDocentesTitulares,
                          idsDocentesAsociados: materia?.idsDocentesAsociados,
                          idsDocentesAdjuntos: materia?.idsDocentesAdjuntos,
                          idsJefesTrabajosPracticos: materia?.idsJefesTrabajosPracticos,
                          idsAyudantesPrimera: materia?.idsAyudantesPrimera,
                          idsAyudantesSegunda: materia?.idsAyudantesSegunda,
                          idsCorrelativasRegularesCursar: materia?.idsCorrelativasRegularesCursar,
                          idsCorrelativasAprobadasCursar: materia?.idsCorrelativasAprobadasCursar,
                          idsCorrelativasRegularesRendir: materia?.idsCorrelativasRegularesRendir,
                          idsCorrelativasAprobadasRendir: materia?.idsCorrelativasAprobadasRendir,
                          aprobablePorResolucion: materia?.aprobablePorResolucion === 1,
                          promocionable: materia?.promocionable === 1,
                          permiteRendirLibre: materia?.permiteRendirLibre === 1,
                          promediable: materia?.promediable === 1,
                          requiereCursada: materia?.requiereCursada === 1,
                          requiereExamen: materia?.requiereExamen === 1,
                          cantidadAplazos: materia?.cantidadAplazos,
                          horasTotales: materia?.horasTotales,
                          horasSemanales: materia?.horasSemanales,
                          escalaRegularidad: materia?.escalaRegularidad,
                          escalaPromocion: materia?.escalaPromocion,
                          escalaEquivalencias: materia?.escalaEquivalencias,
                          escalaExamen: materia?.escalaExamen,
                        },
                      },
                    })
                      .then(() => {
                        handleSnackbar("Unidad de Competencia guardada correctamente", "success");
                        refetch();
                        handleClose();
                      })
                      .catch((e) => {
                        handleSnackbar(
                          "Ocurrió un error al guardar la Unidad de Competencia",
                          "error"
                        );
                      });
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      size={15}
                      sx={{
                        marginRight: 0.5,
                      }}
                      color="inherit"
                    />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  )}
                  &nbsp;Guardar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalMateria.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedMateria: PropTypes.object,
  idNivel: PropTypes.string,
  refetch: PropTypes.func,
};
