// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Collapse,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import dayjs from "dayjs";
import { validateDNI, validateEmail } from "utils/validations";
import { validatePhone } from "utils/validations";
import { UserContext } from "context/user";
import ModalComentarios from "../ModalComentarios";
import SoftInputNumber from "components/SoftInputNumberUpgrade";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "70%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalSocioambiental({ open, handleClose, refetch, idUsuario }) {
  const [alumno, setAlumno] = useState();
  const { handleSnackbar } = useContext(MessageManager);

  const [saveSocioambiental, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveSocioambiental($input: SocioambientalInput!) {
        saveSocioambiental(input: $input) {
          id
        }
      }
    `
  );

  const [getDatosSocioambientales] = useLazyQuery(
    gql`
      query getSocioambiental($idUser: ID!) {
        socioambiental(idUser: $idUser) {
          id
          estadoCivil
          cantidadHijos
          cantidadFamiliares
          situacionPadre
          situacionMadre
          coberturaSalud
          otraCobertura
          tipoVivienda
          otroTipoVivienda
          accesoVivienda
          esCeliaco
          discapacidad
          descripcionDiscapacidad
          disponePC
          accesoInternet
          financiamientoEstudios
          otroFinanciamiento
          tipoPlanSocial
          nombrePlanSocial
          comoConocioInstitucion
          preinscripcionSimultanea
          motivosInstitucion
          motivosPropuesta
          estadoLaboral
          descuentosJubilatorios
          trabajaEnNegocioFamiliar
          pagoPorTrabajo
          horasSemanales
          tareaRealizada
          localidad {
            id
          }
          provincia {
            id
          }
          domicilio
          tipoTrabajo
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const { data: provincias } = useQuery(gql`
    query GetProvincias {
      provincias {
        id
        nombre
      }
    }
  `);

  const [getLocalidades, { data: localidades }] = useLazyQuery(gql`
    query GetLocalidades($provincia: ID!) {
      localidades(provincia: $provincia) {
        id
        nombre
        provincia {
          id
        }
      }
    }
  `);

  const handleChanges = (e) => {
    setAlumno({
      ...alumno,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setAlumno();
    if (open && idUsuario) {
      getDatosSocioambientales({
        variables: {
          idUser: idUsuario,
        },
      }).then((data) => {
        const socioambiental = data?.data?.socioambiental;
        let datosAlumno = {
          ...socioambiental,
          esCeliaco: socioambiental?.esCeliaco ? 1 : 0,
          discapacidad: socioambiental?.discapacidad
            ? "Declara condición de discapacidad"
            : "No presenta ninguna condición que implique Discapacidad",
          disponePC: socioambiental?.disponePC ? 1 : 0,
          preinscripcionSimultanea: socioambiental?.preinscripcionSimultanea ? 1 : 0,
          descuentosJubilatorios: socioambiental?.descuentosJubilatorios ? 1 : 0,
          trabajaEnNegocioFamiliar: socioambiental?.trabajaEnNegocioFamiliar ? 1 : 0,
          pagoPorTrabajo: socioambiental?.pagoPorTrabajo ? 1 : 0,
          idProvinciaNacimiento: socioambiental?.provincia?.id,
          idLocalidadNacimiento: socioambiental?.localidad?.id,
        };
        delete datosAlumno.localidad;
        delete datosAlumno.provincia;
        setAlumno(datosAlumno);
      });
    }
  }, [open]);

  useEffect(() => {
    if (alumno?.idProvinciaNacimiento) {
      getLocalidades({ variables: { provincia: alumno?.idProvinciaNacimiento } }).then((data) => {
        if (
          !data?.data?.localidades?.find(
            (localidad) => localidad.id === alumno?.idLocalidadNacimiento
          )
        ) {
          let userNew = { ...alumno };
          delete userNew.idLocalidadNacimiento;
          setAlumno(userNew);
        }
      });
    }
  }, [alumno?.idProvinciaNacimiento]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center">
              <SoftBox>
                <SoftTypography variant="h6">Formulario Socioambiental</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox py={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="provincia">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Provincia de residencia
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="provincia"
                    value={alumno?.idProvinciaNacimiento || -1}
                    onChange={(e) =>
                      setAlumno({
                        ...alumno,
                        idProvinciaNacimiento: e.target.value,
                        provincia: provincias?.provincias.find(
                          (provincia) => provincia.id === e.target.value
                        ),
                      })
                    }
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    {provincias?.provincias?.map((provincia) => (
                      <MenuItem key={provincia.id} value={provincia.id}>
                        {provincia.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="localidad">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Localidad de residencia
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="localidad"
                    value={alumno?.idLocalidadNacimiento || -1}
                    onChange={(e) =>
                      setAlumno({
                        ...alumno,
                        idLocalidadNacimiento: e.target.value,
                        localidad: localidades?.localidades.find(
                          (localidad) => localidad.id === e.target.value
                        ),
                      })
                    }
                  >
                    <MenuItem value={-1} disabled>
                      {alumno?.idProvinciaNacimiento
                        ? "Seleccione una"
                        : "Seleccione una provincia"}
                    </MenuItem>
                    {localidades?.localidades?.map((localidad) => (
                      <MenuItem key={localidad.id} value={localidad.id}>
                        {localidad.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="domicilio">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Domicilio
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="domicilio"
                    placeholder="Ingrese el domicilio"
                    type="text"
                    name="domicilio"
                    value={alumno?.domicilio || ""}
                    onChange={handleChanges}
                  />
                </Grid>

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="estadoCivil">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Estado Civil
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="estadoCivil"
                    value={alumno?.estadoCivil || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    <MenuItem value={"Soltero"}>Soltero</MenuItem>
                    <MenuItem value={"Casado"}>Casado</MenuItem>
                    <MenuItem value={"Divorciado"}>Divorciado</MenuItem>
                    <MenuItem value={"Viudo"}>Viudo</MenuItem>
                    <MenuItem value={"Unión de hecho"}>Unión de hecho</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="cantidadHijos">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Cantidad de hijos
                    </SoftTypography>
                  </InputLabel>
                  <SoftInputNumber
                    label="cantidadHijos"
                    placeholder="Ingrese la cantidad de hijos"
                    name="cantidadHijos"
                    value={alumno?.cantidadHijos || ""}
                    onChange={handleChanges}
                  />
                </Grid>

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="cantidadFamiliares">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Cantidad de familiares a cargo
                    </SoftTypography>
                  </InputLabel>
                  <SoftInputNumber
                    label="cantidadFamiliares"
                    placeholder="Ingrese la cantidad de familiares a cargo"
                    name="cantidadFamiliares"
                    value={alumno?.cantidadFamiliares || ""}
                    onChange={handleChanges}
                  />
                </Grid>

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="situacionPadre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Situación del padre
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="situacionPadre"
                    value={alumno?.situacionPadre || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione una opción
                    </MenuItem>
                    <MenuItem value={"Vive"}>Vive</MenuItem>
                    <MenuItem value={"Fallecido"}>Fallecido</MenuItem>
                    <MenuItem value={"Desconocido"}>Desconocido</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="situacionMadre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Situación de la madre
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="situacionMadre"
                    value={alumno?.situacionMadre || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione una opción
                    </MenuItem>
                    <MenuItem value={"Vive"}>Vive</MenuItem>
                    <MenuItem value={"Fallecida"}>Fallecida</MenuItem>
                    <MenuItem value={"Desconocida"}>Desconocida</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="coberturaSalud">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Cobertura de salud
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="coberturaSalud"
                    value={alumno?.coberturaSalud || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione una opción
                    </MenuItem>
                    <MenuItem value={"Obra Social"}>Obra Social</MenuItem>
                    <MenuItem value={"Prepaga"}>Prepaga</MenuItem>
                    <MenuItem value={"PAMI"}>PAMI</MenuItem>
                    <MenuItem value={"Ninguna"}>Ninguna</MenuItem>
                    <MenuItem value={"Otra"}>Otra (especificar)</MenuItem>
                  </Select>
                </Grid>

                {alumno?.coberturaSalud === "Otra" && (
                  <Grid item xs={12} sm={4} xxl={3}>
                    <InputLabel htmlFor="coberturaSalud">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Especifique otra cobertura de salud
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="otraCobertura"
                      placeholder="Especifique otra cobertura de salud"
                      type="text"
                      name="otraCobertura"
                      value={alumno?.otraCobertura || ""}
                      onChange={handleChanges}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="tipoVivienda">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      ¿Cuál es el tipo de vivienda en la que vives?
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="tipoVivienda"
                    value={alumno?.tipoVivienda || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    <MenuItem value={"Casa propia"}>Casa propia</MenuItem>
                    <MenuItem value={"Casa rentada"}>Casa rentada</MenuItem>
                    <MenuItem value={"Departamento propio"}>Departamento propio</MenuItem>
                    <MenuItem value={"Departamento rentado"}>Departamento rentado</MenuItem>
                    <MenuItem value={"Prestado"}>Prestado</MenuItem>
                    <MenuItem value={"Otra"}>Otra (especificar)</MenuItem>
                  </Select>
                </Grid>

                {alumno?.tipoVivienda === "Otra" && (
                  <Grid item xs={12} sm={4} xxl={3}>
                    <InputLabel htmlFor="tipoVivienda">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Especifique otro tipo de vivienda
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="otroTipoVivienda"
                      placeholder="Especifique otro tipo de vivienda"
                      type="text"
                      name="otroTipoVivienda"
                      value={alumno?.otroTipoVivienda || ""}
                      onChange={handleChanges}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="accesoVivienda">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      ¿Tiene tu vivienda acceso a?
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="accesoVivienda"
                    value={alumno?.accesoVivienda || []}
                    onChange={(e) => {
                      setAlumno({
                        ...alumno,
                        accesoVivienda: e.target.value,
                      });
                    }}
                    multiple
                  >
                    <MenuItem value={"Electricidad"}>Electricidad</MenuItem>
                    <MenuItem value={"Agua potable"}>Agua potable</MenuItem>
                    <MenuItem value={"Cloaca"}>Cloaca</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="esCeliaco">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      ¿Es celíaco?
                    </SoftTypography>
                  </InputLabel>
                  <Tabs
                    value={alumno?.esCeliaco || 0}
                    name="esCeliaco"
                    onChange={(e, value) => {
                      setAlumno({
                        ...alumno,
                        esCeliaco: value,
                      });
                    }}
                  >
                    <Tab
                      label={
                        <SoftTypography
                          variant="h6"
                          fontWeight={alumno?.esCeliaco === 0 ? "bold" : "regular"}
                        >
                          No
                        </SoftTypography>
                      }
                      value={0}
                    />
                    <Tab
                      label={
                        <SoftTypography
                          variant="h6"
                          fontWeight={alumno?.esCeliaco === 1 ? "bold" : "regular"}
                        >
                          Sí
                        </SoftTypography>
                      }
                      value={1}
                    />
                  </Tabs>
                </Grid>

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="discapacidad">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Condición de Discapacidad
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="discapacidad"
                    value={alumno?.discapacidad || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    <MenuItem value={"No presenta ninguna condición que implique Discapacidad"}>
                      No presenta ninguna condición que implique discapacidad
                    </MenuItem>
                    <MenuItem value={"Declara condición de discapacidad"}>
                      Declara condición de discapacidad
                    </MenuItem>
                  </Select>
                </Grid>

                {alumno?.discapacidad === "Declara condición de discapacidad" && (
                  <Grid item xs={12} sm={8} xxl={6}>
                    <InputLabel htmlFor="descripcionDiscapacidad">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Descripción de la Discapacidad
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="descripcionDiscapacidad"
                      placeholder="Ingrese el tipo de discapacidad"
                      type="text"
                      name="descripcionDiscapacidad"
                      value={alumno?.descripcionDiscapacidad || ""}
                      onChange={handleChanges}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="disponePC">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      ¿Dispone de PC?
                    </SoftTypography>
                  </InputLabel>
                  <Tabs
                    value={alumno?.disponePC || 0}
                    name="disponePC"
                    onChange={(e, value) => {
                      setAlumno({
                        ...alumno,
                        disponePC: value,
                      });
                    }}
                  >
                    <Tab
                      label={
                        <SoftTypography
                          variant="h6"
                          fontWeight={alumno?.disponePC === 0 ? "bold" : "regular"}
                        >
                          No
                        </SoftTypography>
                      }
                      value={0}
                    />
                    <Tab
                      label={
                        <SoftTypography
                          variant="h6"
                          fontWeight={alumno?.disponePC === 1 ? "bold" : "regular"}
                        >
                          Sí
                        </SoftTypography>
                      }
                      value={1}
                    />
                  </Tabs>
                </Grid>

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="accesoInternet">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Acceso a Internet
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="accesoInternet"
                    value={alumno?.accesoInternet || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    <MenuItem value={"Casa"}>En su casa</MenuItem>
                    <MenuItem value={"Trabajo"}>En el trabajo</MenuItem>
                    <MenuItem value={"Universidad"}>En la universidad</MenuItem>
                    <MenuItem value={"Moviles"}>En dispositivos móviles</MenuItem>
                    <MenuItem value={"Otro"}>En otro lugar</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="financiamientoEstudios">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      ¿Cómo costea sus estudios?
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="financiamientoEstudios"
                    value={alumno?.financiamientoEstudios || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    <MenuItem value={"Familiares"}>Con el aporte de familiares</MenuItem>
                    <MenuItem value={"Planes sociales"}>Planes sociales</MenuItem>
                    <MenuItem value={"Trabajo"}>Con su trabajo</MenuItem>
                    <MenuItem value={"Beca"}>Con beca</MenuItem>
                    <MenuItem value={"Otra"}>Otra</MenuItem>
                  </Select>
                </Grid>

                {alumno?.financiamientoEstudios === "Otra" && (
                  <Grid item xs={12} sm={4} xxl={3}>
                    <InputLabel htmlFor="financiamientoEstudios">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Especifique otro tipo de financiamiento
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="otroFinanciamiento"
                      placeholder="Especifique otro tipo de financiamiento"
                      type="text"
                      name="otroFinanciamiento"
                      value={alumno?.otroFinanciamiento || ""}
                      onChange={handleChanges}
                    />
                  </Grid>
                )}

                {alumno?.financiamientoEstudios === "Planes sociales" && (
                  <Grid item xs={12} sm={4} xxl={3}>
                    <InputLabel htmlFor="tipoPlanSocial">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        ¿El plan es?
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <Select
                      name="tipoPlanSocial"
                      value={alumno?.tipoPlanSocial || -1}
                      onChange={handleChanges}
                    >
                      <MenuItem value={-1} disabled>
                        Seleccione uno
                      </MenuItem>
                      <MenuItem value={"Nacional"}>Nacional</MenuItem>
                      <MenuItem value={"Provincial"}>Provincial</MenuItem>
                      <MenuItem value={"Municipal"}>Municipal</MenuItem>
                    </Select>
                  </Grid>
                )}

                {alumno?.financiamientoEstudios === "Planes sociales" && (
                  <Grid item xs={12} sm={4} xxl={3}>
                    <InputLabel htmlFor="tipoPlanSocial">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Nombre del plan
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="nombrePlanSocial"
                      placeholder="Especifique el nombre del plan"
                      type="text"
                      name="nombrePlanSocial"
                      value={alumno?.nombrePlanSocial || ""}
                      onChange={handleChanges}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="comoConocioInstitucion">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      ¿Cómo conoció la Institución?
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="comoConocioInstitucion"
                    value={alumno?.comoConocioInstitucion || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione una opción
                    </MenuItem>
                    <MenuItem value="Publicidad">Publicidad</MenuItem>
                    <MenuItem value="Redes Sociales">Redes Sociales</MenuItem>
                    <MenuItem value="Recomendación de amigos/familiares">
                      Recomendación de amigos/familiares
                    </MenuItem>
                    <MenuItem value="Eventos/ferias">Eventos/ferias</MenuItem>
                    <MenuItem value="Buscar en Internet">Buscar en Internet</MenuItem>
                    <MenuItem value="Recomendación de otros estudiantes/graduados">
                      Recomendación de otros estudiantes/graduados
                    </MenuItem>
                    <MenuItem value="Publicidad en medios locales">
                      Publicidad en medios locales
                    </MenuItem>
                    <MenuItem value="Otro">Otro</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={12} sm={4} xxl={3}>
                  <Tooltip
                    placement="top"
                    title="¿Se preinscribe simultáneamente a una segunda Propuesta en otra Institución?"
                  >
                    <InputLabel htmlFor="preinscripcionSimultanea">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        ¿Se preinscribe simultáneamente a una segunda Propuesta en otra Institución?
                      </SoftTypography>
                    </InputLabel>
                  </Tooltip>
                  <Tabs
                    value={alumno?.preinscripcionSimultanea || 0}
                    name="preinscripcionSimultanea"
                    onChange={(e, value) => {
                      setAlumno({
                        ...alumno,
                        preinscripcionSimultanea: value,
                      });
                    }}
                  >
                    <Tab
                      label={
                        <SoftTypography
                          variant="h6"
                          fontWeight={alumno?.preinscripcionSimultanea === 0 ? "bold" : "regular"}
                        >
                          No
                        </SoftTypography>
                      }
                      value={0}
                    />
                    <Tab
                      label={
                        <SoftTypography
                          variant="h6"
                          fontWeight={alumno?.preinscripcionSimultanea === 1 ? "bold" : "regular"}
                        >
                          Sí
                        </SoftTypography>
                      }
                      value={1}
                    />
                  </Tabs>
                </Grid>

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="motivosInstitucion">
                    <Tooltip
                      placement="top"
                      title="Motivos por los que eligió esta Institución educativa"
                    >
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Motivos por los que eligió esta Institución educativa
                      </SoftTypography>
                    </Tooltip>
                  </InputLabel>
                  <Select
                    name="motivosInstitucion"
                    value={alumno?.motivosInstitucion || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    <MenuItem value={"Económicas"}>Por cuestiones económicas / bajo costo</MenuItem>
                    <MenuItem value={"Prestigio"}>Por el prestigio</MenuItem>
                    <MenuItem value={"Difusión"}>Por la difusión</MenuItem>
                    <MenuItem value={"Recomendación"}>
                      Por recomendación de otros estudiantes / graduados
                    </MenuItem>
                    <MenuItem value={"Amigos"}>Por recomendación de amigos / familiares</MenuItem>
                    <MenuItem value={"Ingreso"}>Por el sistema de ingreso</MenuItem>
                    <MenuItem value={"Ubicación"}>Ubicación geográfica</MenuItem>
                    <MenuItem value={"Otros"}>Otros</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="motivosPropuesta">
                    <Tooltip
                      placement="top"
                      title="Motivos de mayor peso en la elección de Propuesta"
                    >
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Motivos de mayor peso en la elección de Propuesta
                      </SoftTypography>
                    </Tooltip>
                  </InputLabel>
                  <Select
                    name="motivosPropuesta"
                    value={alumno?.motivosPropuesta || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    <MenuItem value={"Vocación"}>Vocación</MenuItem>
                    <MenuItem value={"Núcleo Familiar"}>Sugerencia del núcleo familiar</MenuItem>
                    <MenuItem value={"Reconocimiento"}>Reconocimiento social</MenuItem>
                    <MenuItem value={"Laboral"}>Rápida inserción laboral</MenuItem>
                    <MenuItem value={"Profesional"}>Perfil profesional</MenuItem>
                    <MenuItem value={"Mejora económica"}>
                      Mejora de las condiciones económicas
                    </MenuItem>
                    <MenuItem value={"Ser útil"}>Deseo de ser útil a la sociedad</MenuItem>
                    <MenuItem value={"Otros"}>Otros</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="estadoLaboral">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      ¿Cuál es tu estado laboral actual?
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="estadoLaboral"
                    value={alumno?.estadoLaboral || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    <MenuItem value={"Trabajando"}>Trabajando</MenuItem>
                    <MenuItem value={"Desempleado"}>Desempleado</MenuItem>
                    <MenuItem value={"Estudiante"}>Estudiante</MenuItem>
                    <MenuItem value={"Jubilado"}>Jubilado</MenuItem>
                    <MenuItem value={"Otro"}>Otro</MenuItem>
                  </Select>
                </Grid>

                {alumno?.estadoLaboral === "Trabajando" && (
                  <Grid item xs={12} sm={4} xxl={3}>
                    <InputLabel htmlFor="tipoTrabajo">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        ¿Cuál es tu tipo de trabajo?
                      </SoftTypography>
                    </InputLabel>
                    <Select
                      name="tipoTrabajo"
                      value={alumno?.tipoTrabajo || -1}
                      onChange={handleChanges}
                    >
                      <MenuItem value={-1} disabled>
                        Seleccione uno
                      </MenuItem>
                      <MenuItem value={"Formal"}>Formal</MenuItem>
                      <MenuItem value={"Informal"}>Informal</MenuItem>
                      <MenuItem value={"Autónomo"}>Autónomo</MenuItem>
                      <MenuItem value={"Otro"}>Otro</MenuItem>
                    </Select>
                  </Grid>
                )}

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="descuentosJubilatorios">
                    <Tooltip
                      placement="top"
                      title="¿Le hacen descuentos jubilatorios? (sólo para asalariados)"
                    >
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        ¿Le hacen descuentos jubilatorios? (sólo para asalariados)
                      </SoftTypography>
                    </Tooltip>
                  </InputLabel>
                  <Tabs
                    value={alumno?.descuentosJubilatorios || 0}
                    name="descuentosJubilatorios"
                    onChange={(e, value) => {
                      setAlumno({
                        ...alumno,
                        descuentosJubilatorios: value,
                      });
                    }}
                  >
                    <Tab
                      label={
                        <SoftTypography
                          variant="h6"
                          fontWeight={alumno?.descuentosJubilatorios === 0 ? "bold" : "regular"}
                        >
                          No
                        </SoftTypography>
                      }
                      value={0}
                    />
                    <Tab
                      label={
                        <SoftTypography
                          variant="h6"
                          fontWeight={alumno?.descuentosJubilatorios === 1 ? "bold" : "regular"}
                        >
                          Sí
                        </SoftTypography>
                      }
                      value={1}
                    />
                  </Tabs>
                </Grid>

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="trabajaEnNegocioFamiliar">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      ¿Trabaja en el negocio o empresa de un familiar?
                    </SoftTypography>
                  </InputLabel>
                  <Tabs
                    value={alumno?.trabajaEnNegocioFamiliar || 0}
                    name="trabajaEnNegocioFamiliar"
                    onChange={(e, value) => {
                      setAlumno({
                        ...alumno,
                        trabajaEnNegocioFamiliar: value,
                      });
                    }}
                  >
                    <Tab
                      label={
                        <SoftTypography
                          variant="h6"
                          fontWeight={alumno?.trabajaEnNegocioFamiliar === 0 ? "bold" : "regular"}
                        >
                          No
                        </SoftTypography>
                      }
                      value={0}
                    />
                    <Tab
                      label={
                        <SoftTypography
                          variant="h6"
                          fontWeight={alumno?.trabajaEnNegocioFamiliar === 1 ? "bold" : "regular"}
                        >
                          Sí
                        </SoftTypography>
                      }
                      value={1}
                    />
                  </Tabs>
                </Grid>

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="pagoPorTrabajo">
                    <Tooltip
                      placement="top"
                      title="¿Recibe u obtiene pago por su trabajo? (en dinero o especies)"
                    >
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        ¿Recibe u obtiene pago por su trabajo? (en dinero o especies)
                      </SoftTypography>
                    </Tooltip>
                  </InputLabel>
                  <Tabs
                    value={alumno?.pagoPorTrabajo || 0}
                    name="pagoPorTrabajo"
                    onChange={(e, value) => {
                      setAlumno({
                        ...alumno,
                        pagoPorTrabajo: value,
                      });
                    }}
                  >
                    <Tab
                      label={
                        <SoftTypography
                          variant="h6"
                          fontWeight={alumno?.pagoPorTrabajo === 0 ? "bold" : "regular"}
                        >
                          No
                        </SoftTypography>
                      }
                      value={0}
                    />
                    <Tab
                      label={
                        <SoftTypography
                          variant="h6"
                          fontWeight={alumno?.pagoPorTrabajo === 1 ? "bold" : "regular"}
                        >
                          Sí
                        </SoftTypography>
                      }
                      value={1}
                    />
                  </Tabs>
                </Grid>

                <Grid item xs={12} sm={4} xxl={3}>
                  <InputLabel htmlFor="horasSemanales">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Horas semanales
                    </SoftTypography>
                  </InputLabel>
                  <SoftInputNumber
                    label="horasSemanales"
                    placeholder="Ingrese las horas semanales"
                    name="horasSemanales"
                    value={alumno?.horasSemanales || ""}
                    onChange={handleChanges}
                  />
                </Grid>

                <Grid item xs={12} sm={8} xxl={6}>
                  <InputLabel htmlFor="tareaRealizada">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Describa la tarea que realiza
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="tareaRealizada"
                    placeholder="Describa la tarea"
                    type="text"
                    name="tareaRealizada"
                    value={alumno?.tareaRealizada || ""}
                    onChange={handleChanges}
                  />
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="uproGreen"
                  circular
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  onClick={() => {
                    saveSocioambiental({
                      variables: {
                        input: {
                          id: alumno?.id,
                          idUser: idUsuario,
                          estadoCivil: alumno?.estadoCivil,
                          cantidadHijos: alumno?.cantidadHijos,
                          cantidadFamiliares: alumno?.cantidadFamiliares,
                          situacionPadre: alumno?.situacionPadre,
                          situacionMadre: alumno?.situacionMadre,
                          coberturaSalud: alumno?.coberturaSalud,
                          otraCobertura: alumno?.otraCobertura,
                          tipoVivienda: alumno?.tipoVivienda,
                          otroTipoVivienda: alumno?.otroTipoVivienda,
                          accesoVivienda: alumno?.accesoVivienda,
                          esCeliaco: alumno?.esCeliaco === 1,
                          discapacidad:
                            alumno?.discapacidad === "Declara condición de discapacidad",
                          descripcionDiscapacidad: alumno?.descripcionDiscapacidad,
                          disponePC: alumno?.disponePC === 1,
                          accesoInternet: alumno?.accesoInternet,
                          financiamientoEstudios: alumno?.financiamientoEstudios,
                          otroFinanciamiento: alumno?.otroFinanciamiento,
                          tipoPlanSocial: alumno?.tipoPlanSocial,
                          nombrePlanSocial: alumno?.nombrePlanSocial,
                          comoConocioInstitucion: alumno?.comoConocioInstitucion,
                          preinscripcionSimultanea: alumno?.preinscripcionSimultanea === 1,
                          motivosInstitucion: alumno?.motivosInstitucion,
                          motivosPropuesta: alumno?.motivosPropuesta,
                          estadoLaboral: alumno?.estadoLaboral,
                          descuentosJubilatorios: alumno?.descuentosJubilatorios === 1,
                          trabajaEnNegocioFamiliar: alumno?.trabajaEnNegocioFamiliar === 1,
                          pagoPorTrabajo: alumno?.pagoPorTrabajo === 1,
                          horasSemanales: alumno?.horasSemanales,
                          tareaRealizada: alumno?.tareaRealizada,
                          idProvinciaNacimiento: alumno?.idProvinciaNacimiento,
                          idLocalidadNacimiento: alumno?.idLocalidadNacimiento,
                          domicilio: alumno?.domicilio,
                          tipoTrabajo: alumno?.tipoTrabajo,
                        },
                      },
                    })
                      .then(() => {
                        refetch();
                        handleSnackbar("Datos guardados correctamente", "success");
                        handleClose();
                      })
                      .catch((e) => {
                        handleSnackbar(e.message || "Error al guardar los datos", "error");
                      });
                  }}
                >
                  {loadingSave ? (
                    <CircularProgress
                      size={15}
                      sx={{
                        marginRight: 0.5,
                      }}
                      color="inherit"
                    />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  )}
                  &nbsp;Guardar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalSocioambiental.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  idUsuario: PropTypes.string,
};
