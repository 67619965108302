// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { cloneElement, useContext, useEffect, useRef, useState } from "react";
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

// Kaizen Dashboard base styles
import {
  Card,
  CircularProgress,
  Divider,
  Grid,
  Icon,
  MenuItem,
  Popper,
  Skeleton,
  Slider,
} from "@mui/material";
import SoftBox from "components/CheckBox";
import plantaIcons from "utils/plantaIcons";

import { Menu } from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { UiContext } from "context/ui";
import { getUproColor } from "utils/colors";
import { MessageManager } from "context";
import { gql, useMutation } from "@apollo/client";
import SinDatos from "components/Carteles/SinDatos";
import ModalConfirm from "components/Modals/ConfirmMsg";
import { makePlural } from "utils/formatters";
import { UserContext } from "context/user";

const { light, white, sliderColors, primary, gradients } = colors;
const { borderRadius, borderWidth } = borders;
const { lg } = boxShadows;

function PlanoEditor({
  mapData,
  selectedItem,
  setSelectedItem,
  data,
  shapes,
  handleAsignar,
  selectedSede,
  dataSedes,
  loadingSedes,
  handleOpenSedeMenu,
  openSedeMenu,
  bienes,
  refetch,
}) {
  const [selectedId, setSelectedId] = useState(null);
  const [zoomSlider, setZoomSlider] = useState(1);
  const [verticalViewBox, setVerticalViewBox] = useState(707.28);
  const [horizontalViewBox, setHorizontalViewBox] = useState(1017.36);
  const [dragging, setDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [transformMatrix, setTransformMatrix] = useState(new DOMMatrix());
  const [deviceFilter, setDeviceFilter] = useState([
    "pc",
    "impresora",
    "camara",
    "servidor",
    "Computadora",
    "Cámara",
    "Servidor",
    "Impresora",
    "Teléfono",
    "Proyector",
    "Pizarra",
    "Silla",
    "Mesa",
    "Mueble",
    "Vehículo",
    "default",
  ]);
  const [errorFilter, setErrorFilter] = useState(false);
  const { hideNavbar, handleHideNavbar } = useContext(UiContext);
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [openBienesMenu, setOpenBienesMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAddingShape, setIsAddingShape] = useState(false);
  const [temporaryShape, setTemporaryShape] = useState(null);
  const { handleSnackbar } = useContext(MessageManager);
  const [openDevicePopover, setOpenDevicePopover] = useState(false);
  const [openOpcionesMenu, setOpenOpcionesMenu] = useState(false);
  const [anchorElOpciones, setAnchorElOpciones] = useState(null);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { verificarAcceso } = useContext(UserContext);

  const [saveNodo, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveNodo($input: NodoInput!) {
        saveNodo(input: $input) {
          id
        }
      }
    `,
    {
      onCompleted: (data) => {
        handleSnackbar("El bien se ha asignado correctamente", "success");
        refetch();
        setTemporaryShape(null);
        setIsAddingShape(false);
        svgCanvasRef.current.style.cursor = "";
      },
      onError: (error) => {
        handleSnackbar("Ha ocurrido un error al asignar el bien", "error");
      },
    }
  );
  const [update, { loading: loadingUpdate }] = useMutation(
    gql`
      mutation saveNodo($input: NodoInput!) {
        saveNodo(input: $input) {
          id
        }
      }
    `,
    {
      onCompleted: (data) => {
        handleSnackbar("El bien se ha actualizado correctamente", "success");
        refetch();
        setTemporaryShape(null);
        setIsAddingShape(false);
        svgCanvasRef.current.style.cursor = "";
      },
      onError: (error) => {
        handleSnackbar("Ha ocurrido un error al actualizar el bien", "error");
      },
    }
  );

  const svgCanvasRef = useRef(null);
  const viewPortRef = useRef(null);

  const CardTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: white.main,
      boxShadow: lg,
      padding: "1rem",
      maxWidth: 300,
      pointerEvents: "auto",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: white.main,
    },
  }));

  const handlePointerDown = (event) => {
    setDragging(true);
    setDragOffset({ x: event.clientX, y: event.clientY });
  };

  const handlePointerMove = (event) => {
    if (dragging) {
      const tx = event.clientX - dragOffset.x;
      const ty = event.clientY - dragOffset.y;
      setDragOffset({ x: event.clientX, y: event.clientY });
      setTransformMatrix((prevMatrix) => prevMatrix.translateSelf(tx, ty));
    }
  };

  const handlePointerUp = () => {
    setDragging(false);
  };

  const handleFilter = (device) => {
    if (deviceFilter.includes(device)) {
      setDeviceFilter(deviceFilter.filter((item) => item !== device));
    } else {
      setDeviceFilter([...deviceFilter, device]);
    }
  };

  const handleSave = () => {
    let input = {
      id: temporaryShape.id,
      posicionPlano: {
        x: temporaryShape.posicionPlano.x,
        y: temporaryShape.posicionPlano.y,
      },
    };
    saveNodo({ variables: { input: input } });
  };

  const handleDelete = () => {
    let input = {
      id: selectedItem?.id,
      posicionPlano: {
        x: null,
        y: null,
      },
    };
    update({ variables: { input: input } });
    setOpenModalDelete(false);
    setOpenDevicePopover(false);
    setOpenOpcionesMenu(false);
    setSelectedId(null);
    setSelectedItem(null);
    refetch();
  };

  const handleErrorFilter = () => {
    setErrorFilter(!errorFilter);
    if (errorFilter) {
      setDeviceFilter([
        "pc",
        "impresora",
        "camara",
        "servidor",
        "Computadora",
        "Cámara",
        "Servidor",
        "Impresora",
        "Teléfono",
        "Proyector",
        "Pizarra",
        "Silla",
        "Mesa",
        "Mueble",
        "Vehículo",
        "default",
      ]);
    } else {
      setDeviceFilter([]);
    }
  };

  const [history, setHistory] = useState([]);

  const handleHover = (e, seleccionable) => {
    if (dragging || isAddingShape) return;
    e.target.style.fill = "#102542";
  };

  const handleHoverOut = (e, item) => {
    if (e.target.id === selectedId) {
      e.target.style.fill = "#02D5C2";
    } else {
      e.target.style.fill = item.fill;
    }
  };

  const handleClick = (e, item) => {
    setOpenDevicePopover(false);
    if (isAddingShape || dragging) return;
    if (mapData.filter((element) => element.idPlano == item.id).length > 0) {
      setSelectedItem(item);
      setHistory([...history, item]);
      if (selectedId === e.target.id) {
        setSelectedId(null);
        setSelectedItem(null);
        e.target.style.fill = item.fill;
        mapData.forEach((element) => {
          const item = document.getElementById(element.idPlano);
          if (item) {
            item.style.opacity = 1;
          }
        });
      } else {
        if (selectedId) {
          const prevSelected = document.getElementById(selectedId);
          prevSelected.style.fill = history[history.length - 1].fill;
        }
        setSelectedId(e.target.id);

        let otherItems = mapData.filter((element) => element.id != e.target.id);
        otherItems.forEach((element) => {
          const prevSelected = document.getElementById(element.idPlano);
          if (prevSelected) {
            prevSelected.style.opacity = 0.5;
          }
        });

        e.target.style.fill = "#02D5C2";
        e.target.style.opacity = 1;
      }
    } else {
      handleAsignar(item);
    }
  };

  const handleClickDevice = (e, item) => {
    if (selectedId === e.target.id) {
      setSelectedId(null);
      setSelectedItem(null);
      setAnchorEl(null);
      setOpenDevicePopover(false);
    } else {
      if (selectedId) {
        const prevSelected = document.getElementById(selectedId);
        prevSelected.style.fill = history[history.length - 1].fill;
      }
      setSelectedItem(item);
      setSelectedId(e.target.id);
      setAnchorEl(e.currentTarget);
      setOpenDevicePopover(true);
    }
  };

  const handleAddShape = (item) => {
    const newItem = { ...item };
    delete newItem.posicionPlano;
    setIsAddingShape(true);
    handleCloseBienesMenu();
    handleSnackbar("Haz doble click en el plano para agregar la forma", "info");
    svgCanvasRef.current.style.cursor = "crosshair";
    setTemporaryShape(newItem);
    setAnchorEl(null);
    setAnchorElOpciones(null);
    setOpenDevicePopover(false);
    setOpenOpcionesMenu(false);
  };

  const handleCancelAddShape = () => {
    setIsAddingShape(false);
    setTemporaryShape(null);
    setSelectedItem(null);
    setAnchorEl(null);
    setAnchorElOpciones(null);
    setOpenDevicePopover(false);
    setOpenOpcionesMenu(false);
    svgCanvasRef.current.style.cursor = "";
  };

  const handleAddTemporaryShape = (e) => {
    if (!isAddingShape) return;
    const svgCanvas = svgCanvasRef.current;
    const rect = svgCanvas.getBoundingClientRect();

    const x = ((e.clientX - rect.left) / rect.width) * svgCanvas.viewBox.baseVal.width;
    const y = ((e.clientY - rect.top) / rect.height) * svgCanvas.viewBox.baseVal.height;

    const newTemporaryShape = {
      ...temporaryShape,
      posicionPlano: {
        x: x - 10,
        y: y - 10,
      },
    };
    setTemporaryShape(newTemporaryShape);
  };

  const handleOpenFilterMenu = (event) => {
    setOpenDevicePopover(false);
    setSelectedItem(null);
    setSelectedId(null);
    setAnchorEl(event.currentTarget);
    setOpenFilterMenu(true);
  };

  const handleCloseFilterMenu = () => {
    setAnchorEl(null);
    setOpenFilterMenu(false);
  };

  const renderFilterMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(openFilterMenu)}
      onClose={handleCloseFilterMenu}
      transformOrigin={{ horizontal: "right", vertical: "bottom" }}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      PaperProps={{
        elevation: 0,
        sx: {
          maxHeight: "300px",
          overflowY: "scroll",
          mb: -3,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
          },
        },
      }}
    >
      {Object.keys(plantaIcons.Bien).map((item, index) => (
        <MenuItem
          key={index}
          sx={{
            minWidth: { xs: "75vw", sm: "auto" },
          }}
          onClick={() => handleFilter(item)}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SoftBox mr={2} display="flex">
              <SoftBox mr={1}>
                <Icon
                  fontSize="small"
                  sx={{
                    verticalAlign: "middle",
                    color: getUproColor("uproYellow"),
                  }}
                >
                  {plantaIcons.Bien[item].icon}
                </Icon>
              </SoftBox>
              <SoftTypography variant="p">{item === "default" ? "Bien" : item}</SoftTypography>
            </SoftBox>
            <SoftBox>
              <Icon
                fontSize="small"
                sx={{
                  verticalAlign: "middle",
                  color: deviceFilter.includes(item) ? getUproColor("uproGreen") : "dark",
                }}
              >
                {deviceFilter.includes(item) ? "check_box" : "check_box_outline_blank"}
              </Icon>
            </SoftBox>
          </SoftBox>
        </MenuItem>
      ))}
    </Menu>
  );

  const handleOpenOpcionesMenu = (event) => {
    setAnchorElOpciones(event.currentTarget);
    setOpenOpcionesMenu(true);
  };

  const handleCloseOpcionesMenu = () => {
    setAnchorElOpciones(null);
    setOpenOpcionesMenu(false);
  };

  const renderOpcionesMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorElOpciones}
      open={Boolean(openOpcionesMenu)}
      onClose={handleCloseOpcionesMenu}
      transformOrigin={{ horizontal: "right", vertical: "bottom" }}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      PaperProps={{
        elevation: 0,
        sx: {
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
          },
        },
      }}
    >
      {verificarAcceso(23) && (
        <MenuItem onClick={() => handleAddShape(selectedItem)}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox mr={2}>
            <SoftTypography variant="p">Mover</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("uproGreen"),
              }}
            >
              arrow_forward
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      )}
      {verificarAcceso(24) && (
        <MenuItem
        onClick={() => {
          setOpenModalDelete(true);
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox mr={2}>
            <SoftTypography variant="p">Eliminar</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              color="error"
              sx={{
                verticalAlign: "middle",
              }}
            >
              delete
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      )}
    </Menu>
  );

  const renderPopper = (
    <Popper open={openDevicePopover} anchorEl={anchorEl}>
      <Card>
        <SoftBox
          sx={{
            width: "250px",
          }}
          p={2}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="start"
            textAlign="start"
          >
            <SoftBox display="flex-row" alignItems="center">
              <SoftBox lineHeight={1}>
                <SoftTypography variant="h7" fontWeight="bold">
                  {selectedItem?.nombre}
                </SoftTypography>
              </SoftBox>
              <SoftBox mt={-1}>
                <SoftTypography variant="caption">
                  {selectedItem?.subtipo === "default" ? "Bien" : selectedItem?.subtipo}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex">
              <Tooltip title="Cerrar" placement="top">
                <Icon
                  fontSize="medium"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpenDevicePopover(false);
                    setSelectedItem(null);
                    setSelectedId(null);
                    setAnchorEl(null);
                  }}
                >
                  close
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
          <Divider />
          <Grid container spacing={2}>
            {selectedItem?.descripcion && (
              <Grid item xs={12}>
                <SoftBox
                  sx={{
                    lineHeight: 1,
                  }}
                  display="flex-row"
                >
                  <SoftBox>
                    <SoftTypography variant="caption" fontWeight="bold">
                      Descripción:
                    </SoftTypography>
                  </SoftBox>
                  <SoftTypography variant="caption">{selectedItem?.descripcion}</SoftTypography>
                </SoftBox>
              </Grid>
            )}
            {selectedItem?.propiedades?.length > 0 && (
              <Grid item xs={12}>
                {selectedItem?.propiedades?.map((item, index) => (
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    sx={{
                      width: "100%",
                      lineHeight: 1,
                    }}
                    key={index}
                    mb={selectedItem?.propiedades?.length - 1 === index ? 0 : 1}
                  >
                    <SoftTypography variant="caption" lineHeight="normal" fontWeight="bold">
                      {item.name}:&nbsp;
                    </SoftTypography>
                    <SoftTypography variant="caption" lineHeight="normal">
                      {item.value}
                    </SoftTypography>
                  </SoftBox>
                ))}
              </Grid>
            )}
            <Grid item xs={12}>
              <SoftButton
                fullWidth
                variant="gradient"
                circular
                color={
                  selectedItem?.status === "error"
                    ? "error"
                    : selectedItem?.status === "warning"
                    ? "warning"
                    : "success"
                }
              >
                {selectedItem?.status === "error"
                  ? "Error"
                  : selectedItem?.status === "warning"
                  ? "En mantenimiento"
                  : "Funcionando"}
                &nbsp;
                <Icon>
                  {selectedItem?.status === "error"
                    ? "error_outline"
                    : selectedItem?.status === "warning"
                    ? "handyman"
                    : "done"}
                </Icon>
              </SoftButton>
            </Grid>
            {verificarAcceso([23, 24], "or") && (
              <Grid item xs={12}>
                <SoftButton
                  fullWidth
                  variant="text"
                  color="uproGreen"
                  onClick={handleOpenOpcionesMenu}
                >
                  Opciones &nbsp;
                  <Icon>settings</Icon>
                </SoftButton>
              </Grid>
            )}
          </Grid>
        </SoftBox>
      </Card>
    </Popper>
  );

  const handleOpenBienesMenu = (event) => {
    setOpenDevicePopover(false);
    setSelectedItem(null);
    setSelectedId(null);
    setAnchorEl(event.currentTarget);
    setOpenBienesMenu(true);
    setSelectedCategory(null);
  };

  const handleCloseBienesMenu = () => {
    setAnchorEl(null);
    setOpenBienesMenu(false);
    setSelectedCategory(null);
  };

  const renderBienesMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(openBienesMenu)}
      onClose={handleCloseBienesMenu}
      transformOrigin={{ horizontal: "left", vertical: "bottom" }}
      anchorOrigin={{ horizontal: "left", vertical: "top" }}
      PaperProps={{
        elevation: 0,
        sx: {
          maxHeight: "300px",
          width: { xs: "75vw", sm: "25vw", xl: "20vw", xxl: "15vw" },
          overflowY: "scroll",
          ml: -1,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
          },
        },
      }}
    >
      {!selectedCategory &&
        Object.keys(plantaIcons.Bien).map((item, index) => (
          <MenuItem
            key={index}
            sx={{
              minWidth: { xs: "75vw", sm: "auto" },
            }}
            onClick={() => setSelectedCategory(item)}
          >
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: "100%",
              }}
            >
              <SoftBox mr={2} display="flex">
                <SoftBox mr={1}>
                  <Icon
                    fontSize="small"
                    color="primary"
                    sx={{
                      verticalAlign: "middle",
                    }}
                  >
                    {plantaIcons.Bien[item].icon}
                  </Icon>
                </SoftBox>
                <SoftTypography variant="p">
                  {item === "default" ? "Bienes" : makePlural(item)}
                </SoftTypography>
              </SoftBox>
              <SoftBox>
                <Icon
                  fontSize="small"
                  sx={{
                    verticalAlign: "middle",
                    color: deviceFilter.includes(item) ? getUproColor("uproGreen") : "dark",
                  }}
                >
                  arrow_forward
                </Icon>
              </SoftBox>
            </SoftBox>
          </MenuItem>
        ))}
      {selectedCategory && (
        <MenuItem
          sx={{
            minWidth: { xs: "75vw", sm: "auto" },
          }}
          onClick={() => setSelectedCategory()}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SoftBox mr={2} display="flex">
              <SoftBox mr={1}>
                <Icon
                  fontSize="small"
                  color="primary"
                  sx={{
                    verticalAlign: "middle",
                  }}
                >
                  arrow_back
                </Icon>
              </SoftBox>
              <SoftTypography variant="p">Volver</SoftTypography>
            </SoftBox>
          </SoftBox>
        </MenuItem>
      )}
      {selectedCategory &&
        (bienes.length > 0 &&
        bienes
          .filter((item) => !shapes.find((shape) => shape.id === item.id))
          .filter((item) => item.subtipo === selectedCategory).length > 0 ? (
          bienes
            .filter((item) => !shapes.find((shape) => shape.id === item.id))
            .filter((item) => item.subtipo === selectedCategory)
            .map((item, index) => (
              <MenuItem
                key={index}
                sx={{
                  minWidth: { xs: "75vw", sm: "auto" },
                }}
                onClick={() => handleAddShape(item)}
              >
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    width: "100%",
                  }}
                >
                  <SoftBox mr={2} display="flex">
                    <SoftBox mr={1}>
                      <Icon
                        fontSize="small"
                        sx={{
                          verticalAlign: "middle",
                          color: getUproColor("uproYellow"),
                        }}
                      >
                        {plantaIcons.Bien[item.subtipo || "default"].icon}
                      </Icon>
                    </SoftBox>
                    <SoftTypography variant="p">{item.nombre}</SoftTypography>
                  </SoftBox>
                  <Tooltip placement="top" title="Colocar en el plano">
                    <SoftBox>
                      <Icon
                        fontSize="small"
                        sx={{
                          verticalAlign: "middle",
                          color: getUproColor("uproGreen"),
                        }}
                      >
                        arrow_forward
                      </Icon>
                    </SoftBox>
                  </Tooltip>
                </SoftBox>
              </MenuItem>
            ))
        ) : (
          <SinDatos />
        ))}
    </Menu>
  );

  useEffect(() => {
    if (!selectedItem) {
      setSelectedId(null);
      setSelectedItem(null);
      data.forEach((element) => {
        const item = document.getElementById(element.id);
        item.style.fill = element.fill;
        item.style.opacity = 1;
      });
    }
  }, [selectedItem]);

  useEffect(() => {
    setDragOffset({ x: 0, y: 0 });
    setTransformMatrix(new DOMMatrix());
  }, [zoomSlider]);

  useEffect(() => {
    setOpenDevicePopover(false);
    setSelectedItem(null);
    setSelectedId(null);
    setSelectedItem(null);
    setAnchorEl(null);
  }, [selectedSede]);

  const renderIcon = (icon, status) => {
    const newIcon = cloneElement(icon, {
      viewBox: "-5 -5 40 40",
      style: {
        color:
          status === "error"
            ? "#ea0606"
            : status === "ok"
            ? "#82d616"
            : status === "warning"
            ? "#fbcf33"
            : status == "temp"
            ? "#02D5C2"
            : "#BFCAD6",
      },
    });
    return newIcon;
  };

  return (
    <SoftBox>
      <SoftBox
        display="flex"
        justifyContent="end"
        alignItems="center"
        boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
        borderRadius="lg"
        cursor="pointer"
        ml={3}
        mt={3}
        p={1}
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          backgroundColor: "#fff",
          zIndex: 1000,
        }}
      >
        <SoftBox
          display="flex"
          alignItems="center"
          onClick={dataSedes?.sedes?.length > 0 ? handleOpenSedeMenu : null}
        >
          <Icon color="primary">location_on</Icon>
          <SoftTypography
            variant="h6"
            sx={{
              cursor: "pointer",
            }}
            ml={1}
          >
            {loadingSedes ? (
              <Skeleton width={100} />
            ) : dataSedes?.sedes?.find((sede) => sede.id === selectedSede)?.nombre ? (
              dataSedes?.sedes?.find((sede) => sede.id === selectedSede).nombre
            ) : (
              "No hay sedes disponibles"
            )}
          </SoftTypography>
          <Icon color="dark">{openSedeMenu ? "arrow_drop_up" : "arrow_drop_down"}</Icon>
        </SoftBox>
      </SoftBox>
      <SoftBox
        display="flex-row"
        alignItems="center"
        justifyContent="center"
        boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
        borderRadius="lg"
        cursor="pointer"
        mr={3}
        p={1}
        sx={{
          position: "absolute",
          top: "50%",
          right: "0",
          transform: "translateY(-50%)",
          backgroundColor: "#fff",
          zIndex: 1000,
        }}
      >
        <SoftBox mb={1} display="flex">
          <Tooltip title="Acercar" placement="top">
            <Icon
              fontSize="sm"
              color="primary"
              sx={{ cursor: "pointer" }}
              onClick={() => zoomSlider < 2.5 && setZoomSlider(zoomSlider + 0.1)}
            >
              zoom_in
            </Icon>
          </Tooltip>
        </SoftBox>
        <SoftBox
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "15vh",
          }}
        >
          <Slider
            aria-label="Temperature"
            orientation="vertical"
            valueLabelDisplay="auto"
            value={zoomSlider}
            onChange={(e) => setZoomSlider(e.target.value)}
            min={0.5}
            max={2.5}
            step={0.1}
          />
        </SoftBox>
        <SoftBox mt={1} display="flex">
          <Tooltip title="Alejar" placement="bottom">
            <Icon
              fontSize="sm"
              color="primary"
              sx={{ cursor: "pointer" }}
              onClick={() => zoomSlider > 0.5 && setZoomSlider(zoomSlider - 0.1)}
            >
              zoom_out
            </Icon>
          </Tooltip>
        </SoftBox>
        <SoftBox mt={1} display="flex">
          <Tooltip title="Restaurar vista" placement="bottom">
            <Icon
              fontSize="sm"
              color="uproGreen"
              sx={{ cursor: "pointer", color: getUproColor("uproGreen") }}
              onClick={() => {
                setZoomSlider(1);
                setDragOffset({ x: 0, y: 0 });
                setTransformMatrix(new DOMMatrix());
              }}
            >
              restart_alt
            </Icon>
          </Tooltip>
        </SoftBox>
      </SoftBox>
      <SoftBox
        display="flex"
        justifyContent="end"
        alignItems="center"
        boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
        borderRadius="lg"
        cursor="pointer"
        mr={3}
        mb={3}
        p={1}
        sx={{
          position: "absolute",
          bottom: "0",
          right: "0",
          backgroundColor: "#fff",
          zIndex: 1000,
        }}
      >
        <SoftBox mr={1}>
          <Tooltip
            title={hideNavbar ? "Salir de pantalla completa" : "Pantalla completa"}
            placement="top"
          >
            <SoftButton
              variant="contained"
              color={hideNavbar ? "dark" : "uproGreen"}
              iconOnly
              circular
              onClick={() => handleHideNavbar(!hideNavbar)}
            >
              <Icon>{hideNavbar ? "fullscreen_exit" : "fullscreen"}</Icon>
            </SoftButton>
          </Tooltip>
        </SoftBox>
        <SoftBox mr={1}>
          <Tooltip
            title={errorFilter ? "Ocultar solo errores" : "Mostrar solo errores"}
            placement="top"
          >
            <SoftButton
              variant="contained"
              color={errorFilter ? "dark" : "error"}
              iconOnly
              circular
              onClick={handleErrorFilter}
            >
              <Icon>{errorFilter ? "report_off" : "report"}</Icon>
            </SoftButton>
          </Tooltip>
        </SoftBox>
        <SoftBox>
          <Tooltip title={"Filtros"} placement="top">
            <SoftButton
              variant="contained"
              color={"uproYellow"}
              circular
              onClick={handleOpenFilterMenu}
            >
              <Icon>{openFilterMenu ? "filter_list" : "filter_list"}</Icon>
              &nbsp;Filtros
            </SoftButton>
          </Tooltip>
        </SoftBox>
        {renderFilterMenu}
        {renderOpcionesMenu}
        {selectedItem && renderPopper}
      </SoftBox>
      {verificarAcceso(23) && (
        <SoftBox
          display="flex"
          justifyContent="end"
          alignItems="center"
          boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
          borderRadius="lg"
          cursor="pointer"
          ml={3}
          mb={3}
          p={1}
          sx={{
            position: "absolute",
            bottom: "0",
            left: "0",
            backgroundColor: "#fff",
            zIndex: 1000,
          }}
        >
          <SoftBox>
            <Tooltip
              title={
                isAddingShape ? "Cancelar asignación del bien al plano" : "Asignar bienes al plano"
              }
              placement="top"
            >
              <SoftButton
                variant="contained"
                color={isAddingShape ? "error" : "primary"}
                circular
                onClick={isAddingShape ? handleCancelAddShape : handleOpenBienesMenu}
                disabled={loadingSave}
              >
                <Icon>{isAddingShape ? "close" : "add"}</Icon>
                &nbsp;{isAddingShape ? "Cancelar asignación" : "Asignar bienes"}
              </SoftButton>
            </Tooltip>
          </SoftBox>
          {temporaryShape && temporaryShape?.posicionPlano && (
            <SoftBox ml={1}>
              <Tooltip title={"Confirmar asignación"} placement="top">
                <SoftButton
                  variant="contained"
                  color={"uproGreen"}
                  circular
                  onClick={handleSave}
                  disabled={loadingSave}
                >
                  {loadingSave ? <CircularProgress size={15} sx={{
                marginRight: 0.5,
              }} color="white" /> : <Icon>done</Icon>}
                  {!loadingSave && (
                    <p
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      Confirmar
                    </p>
                  )}
                </SoftButton>
              </Tooltip>
            </SoftBox>
          )}
          {renderBienesMenu}
        </SoftBox>
      )}
      <SoftBox
        id="plano-container"
        p={3}
        ref={viewPortRef}
        onPointerDown={handlePointerDown}
        onDoubleClick={handleAddTemporaryShape}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        onPointerLeave={handlePointerUp}
        style={{
          transform: transformMatrix.toString(),
          cursor: dragging ? "grabbing" : "auto",
          maxHeight: hideNavbar ? "100vh" : "75vh",
        }}
      >
        <svg
          ref={svgCanvasRef}
          id="canvas"
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${horizontalViewBox} ${verticalViewBox}`}
          preserveAspectRatio="xMidYMid meet"
          style={{
            transform: `scale(${zoomSlider})`,
          }}
        >
          <g id="plano-item">
            {data.map((item) => {
              return item.type === "path" ? (
                <Tooltip
                  placement="top"
                  title={
                    dragging
                      ? ""
                      : mapData.find((element) => element.idPlano == item.id)?.nombre ||
                        "Edificio sin asociar"
                  }
                >
                  <path
                    id={item.id}
                    fill={item.fill}
                    style={{
                      cursor: dragging ? "grabbing" : isAddingShape ? "crosshair" : "pointer",
                      transition: "all 150ms ease",
                      filter:
                        !mapData.find((element) => element.idPlano == item.id)?.nombre &&
                        "grayscale(100%)",
                    }}
                    transform={item.transform}
                    onMouseEnter={(e) => handleHover(e, item.seleccionable)}
                    onMouseLeave={(e) => handleHoverOut(e, item)}
                    onClick={(e) => handleClick(e, item)}
                    selected={false}
                    stroke="#000000"
                    strokeWidth={1}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d={item.d}
                    seleccionable={item.seleccionable}
                    key={item.id}
                  />
                </Tooltip>
              ) : (
                <image
                  id={item.id}
                  xlinkHref={item.href}
                  transform={item.transform}
                  width={item.width}
                  height={item.height}
                  seleccionable={item.seleccionable}
                  key={item.id}
                />
              );
            })}
            {shapes
              .filter((item) => item.id !== temporaryShape?.id)
              .map((item, index) => {
                return (
                  <g key={"shapes-" + index}>
                    <svg
                      x={item.posicionPlano?.x}
                      y={item.posicionPlano?.y}
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        cursor: "pointer",
                        transition: "all 150ms ease",
                        filter: selectedItem && selectedItem.id !== item.id && "grayscale(100%)",
                        opacity:
                          errorFilter && item.status === "error"
                            ? 1
                            : !deviceFilter.includes(item.subtipo)
                            ? 0
                            : selectedItem && selectedItem.id !== item.id
                            ? 0.5
                            : 1,
                      }}
                      onMouseDown={(e) => handleClickDevice(e, item)}
                    >
                      <rect
                        width={20}
                        height={20}
                        fill="white"
                        rx={borderRadius.md}
                        style={{
                          filter: "drop-shadow(0 0 5px rgba(0, 0, 0, 0.2))",
                        }}
                        stroke={
                          item.status === "error"
                            ? "#ea0606"
                            : item.status === "ok"
                            ? "#82d616"
                            : item.status === "warning"
                            ? "#fbcf33"
                            : "#BFCAD6"
                        }
                      />
                      <pattern id={`image-${item.id}`} x="0" y="0" width="1" height="1">
                        {renderIcon(plantaIcons.Bien[item.subtipo].svg, item.status)}
                      </pattern>
                      <CardTooltip
                        placement="top"
                        title={
                          selectedItem && selectedItem.id === item.id ? (
                            ""
                          ) : (
                            <SoftBox>
                              <SoftBox>
                                <SoftTypography variant="p" fontWeight="bold">
                                  {item.subtipo}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox>
                                <SoftTypography variant="p">{item.nombre}</SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          )
                        }
                      >
                        <rect x="0" y="0" width="25" height="25" fill={`url(#image-${item.id})`} />
                      </CardTooltip>
                    </svg>
                  </g>
                );
              })}
            {temporaryShape && (
              <svg
                x={temporaryShape.posicionPlano?.x}
                y={temporaryShape.posicionPlano?.y}
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  cursor: "pointer",
                  transition: "all 150ms ease",
                }}
              >
                <rect
                  width={20}
                  height={20}
                  fill="white"
                  rx={borderRadius.md}
                  style={{
                    filter: "drop-shadow(0 0 5px rgba(0, 0, 0, 0.2))",
                  }}
                  stroke={"#02D5C2"}
                />
                <pattern id={`image-${temporaryShape.id}`} x="0" y="0" width="1" height="1">
                  {renderIcon(plantaIcons.Bien[temporaryShape.subtipo].svg, "temp")}
                </pattern>
                <Tooltip
                  placement="top"
                  title={`${temporaryShape.subtipo.toUpperCase()} - ${
                    temporaryShape.nombre
                  } (Sin guardar)`}
                >
                  <rect
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                    fill={`url(#image-${temporaryShape.id})`}
                  />
                </Tooltip>
              </svg>
            )}
          </g>
        </svg>
      </SoftBox>
      <ModalConfirm
        open={openModalDelete}
        handleClose={() => setOpenModalDelete(false)}
        icon="warning"
        title="Atención"
        message={
          <>
            <SoftTypography variant="body2">
              {`El bien ${selectedItem?.nombre} será eliminado del plano. ¿Está seguro que desea continuar?`}
            </SoftTypography>
          </>
        }
        func={handleDelete}
      />
    </SoftBox>
  );
}

// Typechecking props for the PlanoEditor
PlanoEditor.propTypes = {
  mapData: PropTypes.array,
  selectedItem: PropTypes.object,
  setSelectedItem: PropTypes.func,
  filter: PropTypes.string,
  data: PropTypes.array,
  shapes: PropTypes.array,
  setShapes: PropTypes.func,
  handleAsignar: PropTypes.func,
  selectedSede: PropTypes.object,
  dataSedes: PropTypes.array,
  loadingSedes: PropTypes.bool,
  handleOpenSedeMenu: PropTypes.func,
  openSedeMenu: PropTypes.bool,
  bienes: PropTypes.array,
  refetch: PropTypes.func,
};

export default PlanoEditor;
