/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";

const getRows = (data, handleOpenMenu) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      let sedes = [];
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color:
                    item?.tipo === "Carrera" ? getUproColor("uproPink") : getUproColor("uproGreen"),
                }}
                fontSize="lg"
              >
                {item?.tipo === "Carrera" ? "history_edu" : "class"}
              </Icon>
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={item?.tipo} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color:
                      item?.tipo === "Carrera"
                        ? getUproColor("uproPink")
                        : getUproColor("uproGreen"),
                  }}
                  fontSize="lg"
                >
                  {item?.tipo === "Carrera" ? "history_edu" : "class"}
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.id}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.id}
            </SoftTypography>
          </SoftBox>
        ),
        codigo: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.codigo}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.codigo}
            </SoftTypography>
          </SoftBox>
        ),
        nombre: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={makeNiceText(item?.nombre, "nombre")}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {makeNiceText(item?.nombre, "nombre")}
              {item?.esDiscapacitado && (
                <Tooltip title="Discapacitado" placement="top">
                  <Icon color="info" sx={{ verticalAlign: "middle", ml: 0.5 }} fontSize="small">
                    accessible
                  </Icon>
                </Tooltip>
              )}
            </SoftTypography>
          </SoftBox>
        ),
        unidadAcademica: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={makeNiceText(
              `${item?.unidadAcademica?.codigo} - ${item?.unidadAcademica?.nombre}`,
              "nombre"
            )}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.unidadAcademica?.codigo +
                makeNiceText(` - ${item?.unidadAcademica?.nombre}`, "nombre")}
              {item?.esDiscapacitado && (
                <Tooltip title="Discapacitado" placement="top">
                  <Icon color="info" sx={{ verticalAlign: "middle", ml: 0.5 }} fontSize="small">
                    accessible
                  </Icon>
                </Tooltip>
              )}
            </SoftTypography>
          </SoftBox>
        ),
        sedes: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={makeNiceText(`${item?.sedes?.map((sede) => sede?.nombre).join(", ")}`, "nombre")}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {
                item?.sedes?.length > 2 ?
                  `${makeNiceText(`${item?.sedes?.map((sede) => sede?.nombre).slice(0, 2).join(", ")}`, "nombre")}... +${item?.sedes?.length - 2}` :
                  makeNiceText(`${item?.sedes?.map((sede) => sede?.nombre).join(", ")}`, "nombre")
              }
            </SoftTypography>
          </SoftBox>
        ),
        accion: (
          <SoftBox
            mr={2}
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <Tooltip title="Opciones" placement="top">
              <Icon
                sx={{ color: getUproColor("uproGreen"), cursor: "pointer", mx: 0.5 }}
                fontSize="small"
                onClick={(e) => handleOpenMenu(e, item)}
              >
                more_vert
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", width: "1%", align: "left" },
  { name: "codigo", desc: "Codigo", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  {
    name: "unidadAcademica",
    desc: "Unidad Académica",
    align: "left",
    orderField: "idUnidadAcademica",
  },
  {
    name: "sedes",
    desc: "Sedes",
    align: "left",
    orderField: "idSedes",
  },
  { name: "accion", desc: " ", align: "center", width: "1%", noOrder: true },
];

export default { columns, getRows };
