import { gql, useLazyQuery } from "@apollo/client";
import { Card, Collapse, Fade, Grid, Icon, Modal, Tooltip } from "@mui/material";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { getUproColor } from "utils/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "50%", xxl: "40%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalVerPlanCarrera({ open, handleClose, idCarerra }) {
  const [expandedPlanes, setExpandedPlanes] = useState([]);
  const [expandedNiveles, setExpandedNiveles] = useState([]);
  const [plan, setPlan] = useState(null);
  const [getCarrera, { data: carrera, loading }] = useLazyQuery(gql`
    query getCarrera{
        carrerasPublic(filter: {id: ${idCarerra}}){
            id
            nombre
            descripcion
            plan{
                id
                codigo
                nombre
                niveles{
                    id
                    codigo
                    nombre
                    materias{
                        id
                        codigo
                        nombre
                        horasTotales
                    }
                }
            }
        }
    }
    `);

  useEffect(() => {
    setExpandedNiveles([]);
    setExpandedPlanes([]);
    if (open && idCarerra) {
      getCarrera();
    }
  }, [open, idCarerra]);

  useEffect(() => {
    if (carrera?.carrerasPublic) {
      setPlan(carrera.carrerasPublic[0].plan);
    }
  }, [carrera]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Información de la carrera</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox display="flex" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon
                      fontSize="medium"
                      onClick={() => {
                        handleClose();
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox>
              <SoftBox pt={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SoftTypography variant="h6" fontWeight="light">
                      {carrera?.carrerasPublic[0]?.descripcion}
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12}>
                    {loading ? (
                      <Loading />
                    ) : !plan ? carrera?.carrerasPublic[0]?.descripcion ? null : <SinDatos/> : (
                      <Card
                        sx={{
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                      >
                        <SoftBox
                          display="flex"
                          justifyContent="space-between"
                          px={2}
                          py={1}
                          sx={{
                            borderBottom:
                              !expandedPlanes.includes(plan.id) || !plan.niveles?.length
                                ? "none"
                                : "1px solid rgba(0, 0, 0, 0.12)",
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.04)",
                            },
                          }}
                          onClick={(e) => {
                            e.target.tagName !== "BUTTON" &&
                              e.target.tagName !== "SPAN" &&
                              plan.niveles?.length &&
                              setExpandedPlanes(
                                expandedPlanes.includes(plan.id)
                                  ? expandedPlanes.filter((i) => i !== plan.id)
                                  : [...expandedPlanes, plan.id]
                              );
                          }}
                        >
                          <SoftBox display="flex" alignItems="center">
                            <SoftBox mr={1}>
                              <Tooltip title="Plan de estudio" placement="top">
                                <Icon color="primary">task</Icon>
                              </Tooltip>
                            </SoftBox>
                            <SoftTypography
                              variant="h6"
                              fontWeight="bold"
                            >{`${plan.codigo} - ${plan.nombre}`}</SoftTypography>
                            <SoftBox
                              ml={1}
                              alignItems="center"
                              display={{
                                xs: "none",
                                sm: "flex",
                              }}
                            >
                              <SoftBadge
                                color="primary"
                                badgeContent={`${plan.niveles?.length} nivel${
                                  plan.niveles?.length === 1 ? "" : "es"
                                }`}
                              />
                            </SoftBox>
                          </SoftBox>
                          <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                            <SoftBox>
                              <Tooltip
                                title={
                                  expandedPlanes.includes(plan.id)
                                    ? "Contraer plan"
                                    : "Expandir plan"
                                }
                                placement="top"
                              >
                                <SoftButton
                                  color="primary"
                                  circular
                                  iconOnly
                                  disabled={!plan.niveles?.length}
                                  onClick={() =>
                                    setExpandedPlanes(
                                      expandedPlanes.includes(plan.id)
                                        ? expandedPlanes.filter((i) => i !== plan.id)
                                        : [...expandedPlanes, plan.id]
                                    )
                                  }
                                >
                                  <Icon
                                    sx={{
                                      rotate: expandedPlanes.includes(plan.id) ? "180deg" : "0deg",
                                      transition: "all 0.5s",
                                    }}
                                  >
                                    expand_more
                                  </Icon>
                                </SoftButton>
                              </Tooltip>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                        <Collapse in={expandedPlanes.includes(plan.id)}>
                          {plan.niveles?.map((nivel, index) => (
                            <SoftBox key={nivel.id}>
                              <SoftBox
                                px={2}
                                py={1}
                                sx={{
                                  borderBottom:
                                    plan.niveles.length - 1 === index &&
                                    !expandedNiveles.includes(nivel.id)
                                      ? "none"
                                      : "1px solid rgba(0, 0, 0, 0.12)",
                                  cursor: "pointer",
                                  "&:hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                  },
                                }}
                                onClick={(e) => {
                                  e.target.tagName !== "BUTTON" &&
                                    e.target.tagName !== "SPAN" &&
                                    nivel.materias?.length &&
                                    setExpandedNiveles(
                                      expandedNiveles.includes(nivel.id)
                                        ? expandedNiveles.filter((i) => i !== nivel.id)
                                        : [...expandedNiveles, nivel.id]
                                    );
                                }}
                              >
                                <SoftBox display="flex" justifyContent="space-between">
                                  <SoftBox
                                    display="flex"
                                    alignItems="center"
                                    pl={{
                                      xs: 0,
                                      sm: 2,
                                    }}
                                  >
                                    <SoftBox mr={1}>
                                      <Icon
                                        sx={{
                                          color: getUproColor("uproPink"),
                                        }}
                                      >
                                        subdirectory_arrow_right
                                      </Icon>
                                    </SoftBox>
                                    <SoftBox mr={1}>
                                      <Tooltip title="Nivel" placement="top">
                                        <Icon
                                          sx={{
                                            color: getUproColor("uproYellow"),
                                          }}
                                        >
                                          article
                                        </Icon>
                                      </Tooltip>
                                    </SoftBox>
                                    <SoftTypography variant="h6">{`${nivel.codigo} - ${nivel.nombre}`}</SoftTypography>
                                    <SoftBox
                                      ml={1}
                                      alignItems="center"
                                      display={{
                                        xs: "none",
                                        sm: "flex",
                                      }}
                                    >
                                      <SoftBadge
                                        color="uproYellow"
                                        badgeContent={`${nivel.materias?.length} Unidad${
                                          nivel.materias?.length === 1 ? "" : "es"
                                        } de Competencia`}
                                      />
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftBox
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                  >
                                    <SoftBox>
                                      <Tooltip
                                        title={
                                          expandedNiveles.includes(nivel.id)
                                            ? "Contraer nivel"
                                            : "Expandir nivel"
                                        }
                                        placement="top"
                                      >
                                        <SoftButton
                                          color="primary"
                                          circular
                                          iconOnly
                                          disabled={!nivel.materias?.length}
                                          onClick={() =>
                                            setExpandedNiveles(
                                              expandedNiveles.includes(nivel.id)
                                                ? expandedNiveles.filter((i) => i !== nivel.id)
                                                : [...expandedNiveles, nivel.id]
                                            )
                                          }
                                        >
                                          <Icon
                                            sx={{
                                              rotate: expandedNiveles.includes(nivel.id)
                                                ? "180deg"
                                                : "0deg",
                                              transition: "all 0.5s",
                                            }}
                                          >
                                            expand_more
                                          </Icon>
                                        </SoftButton>
                                      </Tooltip>
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              </SoftBox>
                              <Collapse in={expandedNiveles.includes(nivel.id)}>
                                {nivel.materias?.map((materia, index) => (
                                  <SoftBox
                                    key={materia.id}
                                    px={2}
                                    py={1}
                                    sx={{
                                      borderBottom:
                                        !expandedNiveles.includes(nivel.id) &&
                                        !nivel.materias.length
                                          ? "none"
                                          : "1px solid rgba(0, 0, 0, 0.12)",
                                      "&:hover": {
                                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                                      },
                                    }}
                                  >
                                    <SoftBox display="flex" justifyContent="space-between">
                                      <SoftBox
                                        display="flex"
                                        alignItems="center"
                                        pl={{
                                          xs: 1,
                                          sm: 4,
                                        }}
                                      >
                                        <SoftBox mr={1}>
                                          <Icon
                                            sx={{
                                              color: getUproColor("uproYellow"),
                                            }}
                                          >
                                            subdirectory_arrow_right
                                          </Icon>
                                        </SoftBox>
                                        <SoftBox mr={1}>
                                          <Tooltip title="Unidad de Competencia" placement="top">
                                            <Icon
                                              sx={{
                                                color: getUproColor("uproGreen"),
                                              }}
                                            >
                                              book
                                            </Icon>
                                          </Tooltip>
                                        </SoftBox>
                                        <SoftTypography variant="h6">{`${materia.codigo} - ${materia.nombre}`}</SoftTypography>
                                        {materia.horasTotales && (
                                          <SoftBox
                                            ml={1}
                                            alignItems="center"
                                            display={{
                                              xs: "none",
                                              sm: "flex",
                                            }}
                                          >
                                            <SoftBadge
                                              color="uproGreen"
                                              badgeContent={`${materia?.horasTotales} hs cátedra`}
                                            />
                                          </SoftBox>
                                        )}
                                      </SoftBox>
                                      <SoftBox
                                        display="flex"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                      ></SoftBox>
                                    </SoftBox>
                                  </SoftBox>
                                ))}
                              </Collapse>
                            </SoftBox>
                          ))}
                        </Collapse>
                      </Card>
                    )}
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalVerPlanCarrera.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idCarerra: PropTypes.number,
};
