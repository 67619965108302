// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Kaizen Dashboard examples
import PageLayout from "components/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "components/Footer";
import logo from "assets/images/logo_alt.png";

function BasicLayout({ title, description, image, children, size }) {
  return (
    <PageLayout>
      <SoftBox
        width="calc(100% - 2rem)"
        minHeight="50vh"
        borderRadius="lg"
        mx={2}
        my={2}
        pt={6}
        pb={28}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.uproPink.main, 0.9),
              rgba(gradients.uproPink.state, 0.6)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container spacing={3} justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={10} lg={4}>
            <SoftBox mt={6} mb={1}>
              <img src={logo} alt="Terra" width="50%" />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftTypography variant="h4" color="white" fontWeight="bold">
                {title}
              </SoftTypography>
              <SoftTypography variant="body2" color="white" fontWeight="regular">
                {description}
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mt={{ xs: -28, lg: -26 }} mb={5} px={1} width="calc(100% - 2rem)" mx="auto">
        <Grid container spacing={1} justifyContent="center">
          <Grid
            item
            xs={size === "large" ? 12 : 11}
            md={size === "large" ? 9 : 7}
            lg={size === "large" ? 9 : 4}
            xl={size === "large" ? 9 : 3}
          >
            {children}
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox py={3}>
        <Footer />
      </SoftBox>
    </PageLayout>
  );
}

// Setting default values for the props of BasicLayout
BasicLayout.defaultProps = {
  title: "",
  description: "",
};

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["small", "regular", "large"]),
};

export default BasicLayout;
